.paginator {
  box-sizing: border-box;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: rem(14);
  font-weight: 500;
  color: $neutral-solid-300;
  @media screen and (max-width: $breakpoint-tablet) {
    justify-content: space-between;
  }
  * {
    box-sizing: border-box;
  }
  .paginator__numbers {
    display: flex;
    gap: 4px;
  }
  .paginator__item {
    padding: 4px 8px;
    border-radius: $border-radius-s;
    text-align: center;
    min-width: 32px;
    color: $neutral-solid-300;
    &:hover {
      background-color: $neutral-opacity-50;
      text-decoration: none;
    }
  }
  .paginator__item--current {
    @extend .paginator__item;
    color: $primary-color;
    background-color: $mofmof-blue-50;
    &:hover {
      background-color: $mofmof-blue-50;
    }
  }
  .paginator__item--next, .paginator__item--prev {
    @extend .paginator__item;
    color: $primary-color;
    display: flex;
    align-items: center;
    gap: 4px;
    > i {
      margin-top: rem(1); //文字との高さ合わせ
    }
  }
  .paginator__item--invisible {
    color: transparent;
    background-color: transparent;
  }
  .paginator__item--sp {
    @extend .paginator__item;
    &:hover {
      background-color: transparent;
    }
  }
}