.news-posts {
  * {
    box-sizing: border-box;
  }

  .container {
    width: 100%;
    max-width: $breakpoint-xl;
  }
  .posts-container {
    width: 100%;
    max-width: $breakpoint-md;
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 80px;
    padding: 0 24px;
    @media screen and (max-width: $breakpoint-tablet) {
      margin-top: 16px;
    }
  }
  .news-list {
    margin-bottom: 24px;
  }
}