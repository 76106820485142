.techs {
  display: flex;
  flex-direction: column;
  gap: 20px;

  %tech-common {
    border-radius: $border-radius-l;
    background-color: $neutral-white;
    box-shadow: 2px 2px 10px 0px rgba(211, 211, 211, 0.3);
    
    display: flex;
    flex-grow: 1;

  }
  // 説明文ありアイテムのスタイル
  .tech-desc-group {
    display: flex;
    gap: 20px;
    @media screen and (max-width: $breakpoint-md) {
      flex-direction: column;
    }
  }
  .tech-desc {
    container: tech-desc / inline-size;
    flex: 1;
    align-items: stretch;
  }
  .tech-desc__inner {
    @extend %tech-common;
    height: 100%;
    gap: 24px;
    padding: 24px;
    @media screen and (max-width: $breakpoint-xl) {
      padding: 16px;
    }
    @container tech-desc (inline-size <= 300px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  .tech-desc__text-box {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .tech-desc__image {
    width: 96px;
    height: 96px;
  }
  .tech-desc__title {
    font-size: rem(20);
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.04em;
  }
  .tech-desc__description {
    font-size: rem(14);
    line-height: 1.8;
    letter-spacing: 0.06em;

  }

  //説明文なしアイテムのスタイル
  .tech-nodesc-group {
    display: flex;
    gap: 20px;
    @media screen and (max-width: $breakpoint-pc) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      grid-template-columns: 1fr;
    }
  }
  .tech-nodesc {
    container: tech-nodesc / inline-size;
    flex: 1;
  }
  .tech-nodesc__inner {
    @extend %tech-common;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    gap: 24px;
    padding: 24px 12px;
    @media screen and (max-width: $breakpoint-xl) {
      gap: 16px;
      padding: 12px 8px 16px;
    }
    @container tech-nodesc (inline-size >= 224px) {
      flex-direction: row;
      text-align: left;
      padding: 12px 8px;
      padding-left: calc(50% - 96px);
    }
    @media screen and (max-width: $breakpoint-tablet) {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  .tech-nodesc__image {
    width: 96px;
    height: 96px;
    @media screen and (max-width: $breakpoint-xl) {
      width: 80px;
      height: 80px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 64px;
      height: 64px;
    }
  }
  .tech-nodesc__title {
    font-size: rem(16);
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.04em;
  }
}
