.service-works {
  &_section {
    margin-bottom: -50px;
    position: relative;
    height: 150%;

    @media screen and (max-width: 1024px) {
      width: 88.75%;
      margin: 60px auto 0;
    }

    &--background {
      width: 80%;
      height: 78%;
      position: absolute;
      background: $neutral-white url(/images/bg_yellow.png);
      z-index: -1;
      top: 20px;
      left: 20%;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &_container {
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &_lists {
    // slickの矢印の位置を再設定
    .slick-prev, .slick-next {
      top: 30%;
      width: 64px;
      height: 64px;

      @media screen and (max-width: 1024px) {
        top: 10%;
        width: 32px;
        height: 32px;
      }
    }

    .slick-prev {
      background: center/cover no-repeat url(/images/service/arrow_pre.png);

      &:hover {
        background: center/cover no-repeat url(/images/service/arrow_pre.png);
      }
    }

    .slick-next {
      background: center/cover no-repeat url(/images/service/arrow_next.png);

      &:hover {
        background: center/cover no-repeat url(/images/service/arrow_next.png);
      }
    }
  }

  &_list {
    position: relative;
    margin: 0 10px 0 10px;
    img {
      text-align: center;

      @media screen and (max-width: 1024px) {
        width: 100%;
        height: auto;
      }
    }
  }

  &_content {
    background-color: $text-color-white;
    border: 2px solid $primary-color;
    padding: 20px;
    width: calc(38.2% - 40px);
    width: -webkit-calc(38.2% - 40px);
    position: relative;
    z-index: 3;
    bottom: 200px;
    right: -100px;
    margin: 0 0 0 auto;

    @media screen and (max-width: 1024px) {
      padding: 10px;
      width: calc(84.5% - 20px);
      width: -webkit-calc(84.5% - 20px);
      bottom: 30px;
      right: -20px;
    }

    &--company {
      font-size: rem(40);
      font-family: BrandonGrotesque-Bold;
      color: $primary-color;
      margin-bottom: 20px;
      line-height: 1;

      @media screen and (max-width: 1024px) {
        font-size: $base-font-size;
        font-family: Lato;
        margin-bottom: 0;
      }

      &:after {
        content: "･･･";
        color: $primary-color;
        display: block;
        margin-top: 20px;
        font-size: rem(24);

        @media screen and (max-width: 1024px) {
          margin-top: 10px;
          font-size: rem(12);
        }
      }
    }

    &--headline {
      font-size: rem(20);
      font-weight: bold;
      color: $primary-color;
      margin-bottom: 20px;

      @media screen and (max-width: 1024px) {
        font-size: rem(12);
        margin-bottom: 10px;
        line-height: 1.5;
      }
    }

    &--interviewer {
      font-size: rem(13);
      font-weight: bold;
      margin-bottom: 20px;

      @media screen and (max-width: 1024px) {
        font-size: rem(10);
        margin-bottom: 10px;
        line-height: 1;
      }
    }

    &--link {
      text-align: right;
    }
  }

  &_read-more {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 1em 5em;
    color: $primary-color;
    font-family: inherit;
    font-size: rem(12);
    font-weight: bold;
    line-height: 1;
    text-align: center;
    background-color: white;
    border: $primary-color solid 2px;
    border-radius: 0;
    filter: drop-shadow(2.3px 1.9px 3px lightgray);
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
    -webkit-appearance: none;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      font-size: rem(11);
      padding: 10px 20px 10px 20px;
      border: $primary-color solid 1px;
    }
  }
}
