// 教えてどんうさぎのページ
.container--main {
  width: 1200px;
  margin: 0 auto;
}

.chat_faq {
  section.section {
    margin-bottom: 60px;
  }
}

.chat-faq {
  ul {
    list-style: none;
  }

  &__comment {
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 30px;
    }

    .icon {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      border: solid 2px $primary-color;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 60px 0 0;
      text-indent: -9999px;
    }

    .icon.don1 {
      background-image: url('/images/illust/icon_donusagi-1.png');
    }
    .icon.don2 {
      background-image: url('/images/illust/icon_donusagi-2.png');
    }
    .icon.don3 {
      background-image: url('/images/illust/icon_donusagi-3.png');
    }
    // don4については、まだ作成していない
    .icon.don5 {
      background-image: url('/images/illust/icon_donusagi-5.png');
    }
    .icon.ope1 {
      background-image: url('/images/illust/icon_opeko-1.png');
    }
    .icon.ope2 {
      background-image: url('/images/illust/icon_opeko-2.png');
    }
    .icon.ope3 {
      background-image: url('/images/illust/icon_opeko-3.png');
    }
    .icon.ope4 {
      background-image: url('/images/illust/icon_opeko-4.png');
    }

    .balloon {
      background: $background-color-old;
      padding: 30px;
      position: relative;
      display: inline-block;
      min-width: 120px;
      max-width: 100%;
      box-sizing: border-box;
      border-radius: 10px;
    }

    .balloon:before {
      content: '';
      position: absolute;
      top: 40px;
      margin-top: -15px;
      border: 15px solid transparent;
    }

    .balloon p {
      margin: 0;
      padding: 0;
    }

    li.left {
      .balloon:before {
        left: -27px;
        border-right: 15px solid $background-color-old;
      }
    }

    li.right {
      flex-direction: row-reverse;

      .icon {
        margin: 0 0 0 60px;
      }

      .balloon:before {
        left: 100%;
        border-left: 15px solid $background-color-old;
      }
    }
  }

  &__contents {
    h3.tit {
      background: $secondary-color;
      padding: 15px;
      font-size: rem(25);
      color: $primary-color;
      border-left: solid 5px $primary-color;
      margin-bottom: 100px;
    }

    .box {
      position: relative;
      background: $background-color-old;
      padding: 80px 60px 60px 60px;
      border-radius: 10px;
      margin-bottom: 100px;

      .number {
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -60px;
        font-size: rem(60);
        font-weight: bold;
        color: $primary-color;
        line-height: 1;

        span {
          display: block;
          font-size: rem(30);
        }
      }

      h4 {
        font-size: rem(30);
        color: $primary-color;
        text-align: center;
        margin-bottom: 40px;
      }

      .txt {
        font-size: $base-font-size;
        margin-bottom: 40px;
        position: relative;

        &__icon {
          $size: 120px;
          height: $size;
          position: absolute;
          right: -40px;
          top: -32px;
          width: $size;
        }

        &__tit {
          background: $primary-color;
          width: 230px;
          padding: 10px;
          border-radius: 10px 10px 0 0;

          h5 {
            text-align: center;
            font-size: rem(20);
            color: $text-color-white;
          }
        }

        p {
          background: $neutral-white;
          padding: 40px;
          border: solid 2px $primary-color;
          color: $primary-color;
          font-size: rem(18);
          line-height: 1.8;
        }
      }

      .txt.gray {
        .txt__tit {
          background: #666666;
        }

        p {
          border: solid 2px #666666;
          color: $text-color;
        }
      }

      .txt:last-of-type {
        margin-bottom: 0;
      }
    }

    .box:last-of-type {
      margin-bottom: 60px;
    }
  }

  &__tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;

    &--item {
      display: block;
      list-style-type: none;
      width: 50%;
    }
    a {
      display: block;
      text-align: center;
      color: $primary-color;
      background: $background-color-old;
      padding: 30px;
      font-size: rem(22);
      font-weight: bold;

      &:hover {
        text-decoration: none;
        background: $primary-color;
        color: $text-color-white;
        transition: 0.3s;
      }
    }

    a.active {
      pointer-events: none;
      background: $primary-color;
      color: $text-color-white;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -15px;
        border: 15px solid transparent;
        border-top: 15px solid $primary-color;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .chat_faq {
    .header {
      padding-bottom: 50px;
    }

    h1.page_title:after {
      display: none;
    }

    .section {
      padding: 0 20px;
    }
  }

  .container--main {
    width: 100%;
  }

  // スマホのスタイル
  @media screen and (max-width: 1024px) {
    .chat-faq {
      &__comment {
        li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 30px;
        }

        .icon {
          width: 50px;
          height: 50px;
          margin: 0 15px 0 0;
        }

        .balloon {
          padding: 20px;
          width: 250px;
        }

        .balloon:before {
          top: 30px;
          margin-top: -15px;
          border: 15px solid transparent;
        }

        .balloon p {
          font-size: rem(12);
          line-height: 1.7;
        }

        li.right {
          .icon {
            margin: 0 0 0 15px;
          }
        }
      }

      &__contents {
        h3.tit {
          font-size: $base-font-size;
          margin-bottom: 70px;
        }

        .box {
          padding: 40px 20px 20px 20px;
          margin-bottom: 100px;

          .number {
            font-size: rem(40);
            top: -50px;

            span {
              font-size: rem(25);
            }
          }

          h4 {
            font-size: rem(20);
            margin-bottom: 30px;
          }

          .txt {
            font-size: $small-font-size;
            margin-bottom: 40px;

            &__icon {
              right: -40px;
              top: -25px;
              width: 100px;
              height: 100px;
            }

            &__tit {
              background: $primary-color;

              h5 {
                font-size: $base-font-size;
              }
            }

            p {
              padding: 20px;
              font-size: $base-font-size;
              line-height: 1.8;
            }
          }
        }

        .box:last-of-type {
          margin-bottom: 60px;
        }
      }

      &__tab {
        margin: 60px 0 30px;
        a {
          padding: 20px;
          font-size: $base-font-size;
        }
      }
    }
  }
}
