// color
$product-team-plus-orange: #F06B06;
$product-team-plus-blue: #029AAA;

.service-product-team-plus {
  // NOTE: product-team-plus内のさまざまな要素に共通の設定
  *, *::before, *::after {
    box-sizing: border-box;
  }
  .pc-only {
    @media screen and (max-width: $breakpoint-pc) {
      display: none!important;
    }
  }
  .sp-only {
    display: none!important;
    @media screen and (max-width: $breakpoint-pc) {
      display: block!important;
    }
  }

  %h3_underline {
    line-height: 2;
    &.dotted {
      border-bottom: 1px dotted $primary-color;
    }
  }

  .h3 {
    color: $primary-color;
    font-size: map-get($heading-font-size, md);
    font-weight: bold;
    .border {
      @extend %h3_underline;
    }
  }

  .ptp-text-link {
    color: $text-link-color;
    text-decoration: none;
    &:hover {
      color: $primary-color-hover;
      text-decoration: underline;
    }
  }

  @mixin btn-with-icon($icon-path) {
    $icon-width: 20px;
    $gap-width: 8px;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    font-weight: 700 !important;
    gap: 8px;
    height: 48px;
    justify-content: center;
    padding: 16px;
    transition: all .1s;
    white-space: nowrap;
    span {
      display: inline-block;
      margin-left: calc(#{$icon-width} + #{$gap-width});
      position: relative;
      &::before {
        $icon-height: 24px;
        background: url($icon-path) no-repeat center;
        content: "";
        display: block;
        height: $icon-height;
        left: calc(-#{$icon-width} - #{$gap-width});
        position: absolute;
        top: calc(50% - #{$icon-height} * 0.5); // NOTE: 最後の-1は隣の文字がnoto sansで少し下に下がって見えるための調整
        width: $icon-width;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }

  .btn-download {
    @include btn-with-icon("/images/ico_download_black.svg");
    background-color: $neutral-white;
    border: solid 1px $text-color;
    color: $text-color;
    &:hover {
      background-color: $neutral-solid-50;
    }
  }

  .btn-contact {
    @include btn-with-icon("/images/ico_mail.svg");
    background-color: $neutral-black;
    color: $text-color-white;
    &:hover {
      background-color: $neutral-solid-800;
    }
  }

  .btn-fixed {
    width: 214px;
  }
  .btn-lg {
    font-size: rem(20);
    height: 64px;
  }
  .btn-block {
    width: 100%;
  }
  // NOTE: $screen-size に設定した値以下でAOSを無効にする
  @mixin no-aos($screen-size) {
    @media screen and (max-width: $screen-size) {
      &[data-aos] {
        pointer-events: auto !important;
        opacity: 1 !important;
        transform: none !important;
      }
    }
  }

  $section-side-padding: 24px;
  .section {
    margin: 0;
    padding: 104px $section-side-padding;
    &:nth-child(2n - 1) {
      background-color: $neutral-solid-50;
    }
  }

  .heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
    margin-bottom: 60px;
    
  }

  .heading__text-ja {
    font-size: rem(32);
    font-weight: 700;
    line-height: 48px;
    text-align: center;
  }

  .heading__text-en {
    font-size: rem(14);
    font-weight: 700;
  }

  .heading__border {
    width: 150px;
    margin-top: 10px;
    border-bottom: 2px solid $neutral-black;
  }

  .contents {
    margin: 0 auto;
    max-width: 1120px;
  }

  .h3 {
    color: $text-color;
    font-size: rem(20);
    font-weight: 500;
  }

  .balloon {
    background-image: url("/images/service/product-team-plus/bg-heading-balloon.svg");
    background-position: center;
    background-repeat: no-repeat;
    height: 20px;
    width: 100%;
    @media screen and (max-width: $breakpoint-pc) {
      width: 70%;
    }
  }
  // END:NOTE: product-team-plus内のさまざまな要素に共通の設定 -----
  
  &.js-menu-open {
    //NOTE: SP版のメニューを開いた時に、後ろ側がスクロールしないようにする対応
    overflow: hidden;
    position: fixed;
  }
  .floating-btn {
    @include btn-with-icon("/images/ico_download_black.svg");
    background-color: $neutral-black;
    bottom: 20px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3), 0px 8px 24px 5px rgba(0, 0, 0, 0.1);
    color: $text-color-white;
    opacity: 0;
    padding: 8px 16px;
    position: fixed;
    right: 20px;
    z-index: $z-index-floating-button;
    &:hover {
      background-color: $neutral-solid-800;
    }
  }

  .floating-btn.js-float-visible {
    opacity: 1;
  }

  .header {
    align-items: center;
    background-color: $text-color-white;
    background-image: none;
    box-shadow: 0px 4px 12px 0px #00000040;
    box-sizing: border-box;
    display: flex;
    gap: 16px;
    height: 79px;
    justify-content: space-between;
    left: 0;
    padding: 0px 24px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index-header;
    @media screen and (max-width: $breakpoint-pc) {
      padding: 0px 24px;
    }
    .header__title {
      font-weight: 700;
    }
    .header__nav {
      align-items: center;
      display: flex;
      gap: 24px;
      .nav__link {
        font-weight: 700;
      }
      .nav__buttons {
        display: flex;
        gap: 24px;
      }
    }
  }

  .sp-menu {
    display: none;
    @media screen and (max-width: $breakpoint-pc) {
      display: block;
    }
    .sp-menu__content {
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      background: rgba($neutral-solid-50, 0.95);
      height: 100%;
      left: 100%;
      overflow-y: scroll;
      overscroll-behavior-y: contain;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: $z-index-sp-menu-overlay;
      .sp-heading {
        align-items: center;
        display: flex;
        gap: 16px;
        height: 79px;
        justify-content: space-between;
        padding: 16px 24px;
        .sp-heading__title {
          font-weight: 700;
        }
      }
      .nav {
        padding: 32px 40px 70px 40px;
        .nav__item {
          border-bottom: 1px solid $neutral-solid-200;
          cursor: pointer;
          list-style: none;
          padding: 12px;
          &:first-child {
            border-top: 1px solid $neutral-solid-200;
          }
          .nav__item--link {
            display: block;
            font-size: rem(14);
            font-weight: 700;
            position: relative;
            width: 100%;
            &::after {
              background: url("/images/service/product-team-plus/ico_chevron-right.svg") no-repeat center;
              content: "";
              display: block;
              height: 100%;
              position: absolute;
              right: 0;
              top: calc(50% - 16px); // FIXME: マジックナンバー
              width: 16px;
            }
          }
        }
      }
      .buttons {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 40px;

        [class^="btn-"] {
          height: 56px; // FIXME: ここだけボタン高さ固定しないと太くなる
          width: 235px;
        }
      }
    }

    &.open {
      .sp-menu__content {
        left: 0;
      }
    }
  }

  .keyvis.section {
    align-items: center;
    background-image: url('/images/service/product-team-plus/bg-keyvis-left-top.svg'), url('/images/service/product-team-plus/bg-keyvis-right-bottom.svg');
    background-position: top left, bottom right;
    background-repeat: no-repeat, no-repeat;
    background-size: 700px, 810px;
    display: flex;
    height: 772px;
    margin-top: 80px;
    padding: 0 inherit;

    @media screen and (max-width: $breakpoint-xxl) {
      $max-window-size: $breakpoint-xxl;
      background-position: top left calc((100vw - #{$max-window-size}) / 2), bottom right calc((100vw - #{$max-window-size}) / 2);
    }

    @media screen and (max-width: $breakpoint-xl) {
      background-image: url('/images/service/product-team-plus/bg-keyvis-left-top-sp.svg'), url('/images/service/product-team-plus/bg-keyvis-right-bottom-sp.svg');
      background-position: top left, bottom right;
      background-size: auto, auto;
      display: block;
      height: auto;
      padding: 0;
    }

    .contents {
      column-gap: 60px;
      display: flex;
      height: 540px;
      @media screen and (max-width: $breakpoint-xl) {
        padding: 64px 24px 104px 24px;
        flex-direction: column-reverse;
        height: auto;
      }

      .title-and-buttons {
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: flex-end;
        @media screen and (max-width: $breakpoint-xl) {
          margin: 0 auto;
          max-width: 640px;
        }
        .subtitle {
          font-weight: 700;
          margin-bottom: 10px;
        }
        .title {
          border-bottom: 2px solid $neutral-black;
          color: $text-color;
          font-size: rem(40);
          font-weight: 700;
          line-height: 54px;
          padding-bottom: 15px;
          @media screen and (max-width: $breakpoint-xl) {
            font-size: rem(24);
            line-height: 36px;
          }
          .title__img {
            width: 100%;
          }
        }
        .buttons {
          display: flex;
          column-gap: 24px;
          justify-content: center;
          @media screen and (max-width: $breakpoint-tablet) {
            align-items: center;
            flex-direction: column;
            gap: 20px;
          }
          .contact {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 267px;
            height: 47px;
            border-radius: 8px;
            background-color: $text-color;
            font-weight: 700;
            color: $text-color-white;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }

      .image-and-copy {
        align-items: flex-end;
        background-image: url('/images/service/product-team-plus/bg-keyvis-image-back.svg');
        background-position: top 40px center;
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        gap: 60px;
        @media screen and (max-width: $breakpoint-pc) {
          background-image: url('/images/service/product-team-plus/bg-keyvis-image-back.svg');
          background-size: 100%;
          flex-direction: column-reverse;
          max-width: 420px;
          margin: 0 auto 60px auto;
        }
        .copy {
          @include no-aos($breakpoint-pc);
          align-self: flex-start;
          background: $neutral-white;
          font-family: 'Noto Sans JP', sans-serif;
          font-size: rem(30);
          font-weight: bold;
          letter-spacing: 18px;
          padding: 8px 8px 0px 8px;
          writing-mode: vertical-rl;
          @media screen and (max-width: $breakpoint-pc) {
            letter-spacing: 8px;
            padding: 0px 8px;
            font-size: rem(22);
            min-width: 220px;
            writing-mode: horizontal-tb;
          }
        }
        .image {
          @include no-aos($breakpoint-pc);
          margin: 0 auto;
          max-width: 514px;
          @media screen and (max-width: $breakpoint-pc) {
            max-width: calc(100% - 48px);
          }
        }
      }
    }
  }

  $triangle-size: 52px;
  .problem.section {
    background-image: url('/images/service/product-team-plus/bg-cross.svg');
    padding-bottom: 0;
    .heading {
      

      .title__q {
        display: inline-block;
        margin: 0 5px 0 0;
        color: $product-team-plus-blue;
        font-size: rem(40);
        @media screen and (max-width: $breakpoint-pc) {
          display: none;
        }
      }
  
      .title__problem-text {
        display: inline-block;
        margin: 0 10px;
        color: $product-team-plus-blue;
        font-size: rem(40);
        font-weight: 700;
      }
    }

    .contents {
      margin-bottom: 104px;
      .list {
        display: flex;
        justify-content: center;
        gap: 48px;
        @media screen and (max-width: $breakpoint-pc) {
          flex-direction: column;
        }

        .list__item {
          text-align: center;

          .list__image {
            margin-bottom: 20px;
            width: 200px;
          }

          .list__item__text {
            font-size: rem(20);
            font-weight: 500;
          }
        }
      }
    }

    .triangle {
      display: flex;
      height: $triangle-size;
      margin: 0 -#{$section-side-padding};
      .center {
        width: 0;
        height: 0;
        border-style: solid;
        border-right: $triangle-size solid $neutral-solid-50;
        border-left: $triangle-size solid $neutral-solid-50;
        border-top: $triangle-size solid transparent;
        border-bottom: 0;
      }
      .left, .right {
        background-color: $neutral-solid-50;
        flex-grow: 1;
        height: 100%;
      }
    }
  }

  .solution.section {
    padding-top: calc(104px - #{$triangle-size}); 
    .heading {
      .title__solution {
        display: inline-block;
        margin: 0 10px;
        color: $product-team-plus-orange;
        font-size: rem(40);
        font-weight: 700;
      }
    }

    .contents {
      .list {
        display: flex;
        justify-content: space-between;
        gap: 48px;
        @media screen and (max-width: $breakpoint-pc) {
          flex-direction: column;
        }

        .list__item {
          flex: 1;
          text-align: center;

          .list__item__image {
            max-width: 200px;
            width: 100%;
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  .feature.section {
    background-image: url('/images/service/product-team-plus/bg-wave.png');
    background-size: cover;
    @media screen and (max-width: $breakpoint-xl) {
      background-image: url('/images/service/product-team-plus/bg-wave-sp.png');
    }

    .heading {
      .heading__text-ja {
        @media screen and (max-width: $breakpoint-pc) {
          font-size: rem(24);
        }
      }
    }

    .contents {
      max-width: 960px;
      .list {
        display: flex;
        flex-direction: column;
        row-gap: 64px;
        align-items: center;
        .list-item {
          align-items: center;
          column-gap: 64px;
          display: flex;
          width: 100%;
          @media screen and (max-width: $breakpoint-xl) {
            flex-direction: column;
            gap: 16px;
            max-width: 90%;
          }
          &:nth-child(2n - 1) {
            flex-direction: row-reverse;
            @media screen and (max-width: $breakpoint-xl) {
              flex-direction: column;
            }
          }
          @mixin ellipse($position) {
            background: url("/images/service/product-team-plus/bg-ellipse.svg");
            background-size: cover;
            content: '';
            height: 111px;
            left: $position;
            position: absolute;
            top: $position;
            width: 115px;
          }
          .img__wrap {
            display: block;
            position: relative;
            max-width: 400px;
            &:before {
              @include ellipse(-20px);
              display: none;
              @media screen and (max-width: $breakpoint-xl) {
                display: block;
              }
            }
            .image {
              max-width: 100%;
            }
          }

          .description {
            display: flex;
            flex-flow: column;
            justify-content: center;
            position: relative;
            
            &:before {
              @include ellipse(-60px);
              display: block;
              @media screen and (max-width: $breakpoint-xl) {
                  display: none;
              }
            }
            .description__title {
              margin-bottom: 16px;
              font-size: rem(24);
              font-weight: 500;
              line-height: 36px;
            }
            .description__detail {
              margin-bottom: 16px;
            }
            .description__link {
              display: flex;
              align-items: center;
              font-weight: 700;
              color: $primary-color;
            }
          }
          &.first {
            .img__wrap {
              &:before {
                @media screen and (max-width: $breakpoint-xl) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .how.section {
    .heading {
      .heading__text-ja {
        @media screen and (max-width: $breakpoint-pc) {
          font-size: rem(24);
        }
      }
    }

    .contents {
      max-width: 1100px;
      .list {
        display: grid;
        grid-gap: 48px;
        grid-template-columns: 1fr 1fr 1fr;
        @media screen and (max-width: $breakpoint-xl) {
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: 104px;
        }

        .list__item {
          background-color: $text-color-white;
          border-radius: 12px;
          display: flex;
          flex-flow: column;
          min-height: 420px;
          padding: 16px;
          position: relative;
          row-gap: 24px;
          width: 300px;
          .number {
            position: absolute;
            top: -0.55em;
            left: 10%;
            font-size: rem(40);
            font-weight: 700;
            color: $product-team-plus-orange;
            line-height: 1;
          }
          .image__wrap {
            text-align: center;
            .image {
              $image-aspect-ratio: 1.33;
              $image-height: 208px;
              height: 100%;;
              max-height: $image-height;
              max-width: calc(#{$image-height} * #{$image-aspect-ratio});
              width: 100%;
            }
          }
          .detail {
            padding-left: 30px;
            .detail__item {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  .works.section {
    background-image: url("/images/service/product-team-plus/bg-works-section.svg");
    background-size: cover;
    .heading {
      .heading__text-ja {
        @media screen and (max-width: $breakpoint-pc) {
          font-size: rem(24);
        }
      }
    }

    .contents {
      .list {
        display: flex;
        column-gap: 80px;
        width: 100%;

        &:nth-child(2n - 1) {
          flex-direction: row-reverse;
          margin-bottom: 104px;

          @media screen and (max-width: $breakpoint-pc) {
            flex-direction: column;
          }
        }

        @media screen and (max-width: $breakpoint-pc) {
          flex-direction: column;
          row-gap: 64px;
          margin: 0 auto;
          max-width: 90%;
        }

        .list__content {
          flex: 1;
          display: flex;
          flex-flow: column;
          row-gap: 16px;
          justify-content: center;

          .list__content__company {
            font-size: rem(36);
            font-weight: 700;
            img {
              width:  200px;
            }
          }

          .list__content__interviewee {
            font-weight: 400;
          }

          .list__content__title {
            font-size: rem(24);
            font-weight: 500;
            line-height: 36px;
          }

          .list__content__description {
            font-weight: 400;
          }

          .list__content__link {
            display: flex;
            align-items: center;
            font-weight: 700;
            color: $primary-color;
          }
        }

        .list__image {
          flex: 1;
          max-width: 510px;
          height: 100%;
        }
      }
    }
  }

  .organization.section {
    .heading {
      .heading__text-ja {
        @media screen and (max-width: $breakpoint-pc) {
          font-size: rem(24);
        }
      }
    }

    .contents {
      .item {
        align-items: flex-start;
        display: flex;
        column-gap: 80px;
        flex-direction: row-reverse;
        width: 100%;
        @media screen and (max-width: $breakpoint-pc) {
          flex-direction: column;
          row-gap: 48px;
        }

        .content {
          flex: 1;
          display: flex;
          flex-flow: column;
          justify-content: center;

          .content__title {
            margin-bottom: 16px;
            font-size: rem(24);
            font-weight: 500;
          }

          .content__description {
            margin-bottom: 16px;
          }
        }

        .image {
          flex: 1;
          height: 100%;
          text-align: center;
          width: 100%;
          img {
            max-width: 520px;
            width: 100%;
          }
        }
      }
    }
  }

  .members.section {
    background-image: url("/images/service/product-team-plus/bg-members-section.svg");
    background-position: center;
    background-size: 100%;
    .heading {
      .heading__text-ja {
        @media screen and (max-width: $breakpoint-pc) {
          font-size: rem(24);
        }
      }
    }
    .contents {
      .description {
        margin-bottom: 64px;
        @media screen and (max-width: $breakpoint-pc) {
          margin-bottom: 32px;
        }
      }
    }
    .slider {
      height: 360px;
      margin: 0 -#{$section-side-padding};
      @media screen and (max-width: $breakpoint-pc) {
        height: 240px;
      }
      .slide {
        margin-right: 16px;
        width: 540px;
        @media screen and (max-width: $breakpoint-pc) {
          margin-right: 10px;
          max-width: 360px;
        }
        img {
          border: solid 1px $neutral-solid-200;
          border-radius: $border-radius-m;
          width: 100%;
        }
        &:nth-child(2n - 1) {
          width: 420px;
          background: transparent;
          display: grid;
          grid-gap: 16px;
          &.middle-to-small {
            grid-template-rows: 3fr 2fr;
          }
          &.small-to-middle {
            grid-template-rows: 2fr 3fr;
          }
          
          @media screen and (max-width: $breakpoint-pc) {
            grid-gap: 10px;
            max-width: 280px;
            max-height: 240px;
          }
          .small-img, .middle-img {
            width: 100%;
          }
        }
      }
    }
  }

  .skill.section {
    background-image: url("/images/service/product-team-plus/bg-skill-section.svg");
    background-position: top 60% left -200%;
    background-repeat: repeat-x;
    background-size: 80%;
    .heading {
      .heading__text-ja {
        @media screen and (max-width: $breakpoint-pc) {
          font-size: rem(24);
        }
      }
    }

    .contents {
      .content-top {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        padding: 0px 24px 48px;
        width: 100%;
        max-width: 1120px;
        box-sizing: border-box;
        @media screen and (max-width: $breakpoint-pc) {
          padding: 0px 0px 48px;
        }
      }
    }
  }

  .flow.section {
    padding-bottom: 80px;
    .heading {
      .heading__text-ja {
        @media screen and (max-width: $breakpoint-pc) {
          font-size: rem(24);
        }
      }
    }

    .contents {
      .list {
        $gap-length-pc: 88px;
        $gap-length-sp: 40px;
        align-items: center;
        display: flex;
        gap: $gap-length-pc;
        justify-content: center;
        @media screen and (max-width: $breakpoint-xl) {
          flex-direction: column;
          gap: $gap-length-sp;
          max-width: 100%;
          margin: 0 auto;
          width: 350px;
        }
        .list__item {
          display: grid;
          grid-template-rows: 1fr 4fr 1.5fr;
          @media screen and (max-width: $breakpoint-xl) {
            display: flex;
            width: 100%;
          }
          &:not(:last-child) {
            .image__wrap:after {
              background-color: $neutral-solid-200;
              content: "";
              display: block;
              height: 1px;
              position: absolute;
              right: -#{$gap-length-pc};
              top: 50%;
              width: $gap-length-pc;
              @media screen and (max-width: $breakpoint-xl) {
                height: $gap-length-sp;
                left: 50%;
                right: auto;
                top: auto;
                width: 1px;
              }
            }
          }
          .image__wrap {
            border: solid 1px $neutral-solid-200;
            border-radius: 40px;
            margin-bottom: 16px;
            max-width: 200px;
            position: relative;
            
            .image {
              width: 100%;
            }
            @media screen and (max-width: $breakpoint-xl) {
              max-width: 150px;
              margin: 0 16px;
            }
          }

          .description {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
            .number.sp-only {
              margin-bottom: 8px;
              text-align: left;
            }
            .title {
              text-align: center;
              @media screen and (max-width: $breakpoint-xl) {
                text-align: left;
              }
            }
          }

          .number.pc-only {
            text-align: center;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .contact.section {
    align-items: flex-start;
    background-image: url('/images/service/product-team-plus/bg-contact.png');
    background-size: cover;
    background-color: $neutral-solid-50;
    display: flex;
    justify-content: space-evenly;
    padding-top: 140px;
    @media screen and (max-width: $breakpoint-pc) {
      background-image: url('/images/service/product-team-plus/bg-contact-sp.png');
    }

    .main-contents {
      $border: solid 3px $neutral-black;
      background-color: $text-color-white;
      border-radius: $border-radius-m;
      border: $border;
      padding: 80px 48px 64px 48px;
      position: relative;
      width: 700px;
      @media screen and (max-width: $breakpoint-pc) {
        padding: 64px 16px 48px 16px;
        max-width: 640px;
        width: 100%;
      }

      .copy {
        $box-height: 68px;
        $box-width: 430px;
        @mixin copy-box-position($width, $height) {
          left: calc(50% - #{$width} * 0.5);
          top: calc(-#{$height} * 0.5);
        }
        @include copy-box-position($box-width, $box-height);
        background: $product-team-plus-orange;
        border: $border;
        border-radius: $border-radius-m;
        position: absolute;
        text-align: center;
        @media screen and (max-width: $breakpoint-pc) {
          $box-height-sp: 32px;
          $box-width-sp: 288px;
          @include copy-box-position($box-width-sp, $box-height-sp);
          width: $box-width-sp;
        }
        .copy__text {
          color: $text-color-white;
          display: inline-block;
          font-size: rem(24);
          font-weight: 700;
          line-height: 150%;
          padding: 16px 40px; 
          text-align: center;
          @media screen and (max-width: $breakpoint-pc) {
            font-size: rem(16);
            padding: 16px 0; 
          }
        }
      }
      .main-contents__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        
        .logos {
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: 24px;
          margin-bottom: 48px;
          .logos__company {
            width: 336px;
            @media screen and (max-width: $breakpoint-pc) {
              max-width: 240px;
            }
          }
          .logos__service {
            width: 480px;
            @media screen and (max-width: $breakpoint-pc) {
              width: 100%;
            }
          }
        }
        
        .buttons {
          display: flex;
          gap: 32px;
          @media screen and (max-width: $breakpoint-tablet) {
            flex-direction: column;
            gap: 20px;
            justify-content: center;
          }
        }
      }
    }
  }

  .footer {
    margin: 0;
    padding: 60px 24px 20px;
    background-color: #666666;
    color: $text-color-white;

    .contents {
      .heading {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        border-bottom: 1px solid #FFFFFF4D;
        padding-bottom: 15px;
        margin-bottom: 15px;
        @media screen and (max-width: $breakpoint-pc) {
          flex-direction: column;
          align-items: start;
          row-gap: 20px;
        }
        .heading__text {
          @media screen and (max-width: $breakpoint-pc) {
            font-size: rem(24);
          }
          .img {
            width: 100%;
          }
        }
        .contact {
          font-weight: 700;
          color: $text-color-white;
        }
      }

      .links {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 10px;
        margin-bottom: 60px;
        max-width: 800px;
        @media screen and (max-width: $breakpoint-pc) {
          grid-template-columns: repeat(1, 1fr);
        }

        .links__column {
          display: flex;
          flex-direction: column;
          row-gap: 10px;

          .links__column__item {
            font-weight: 700;
            color: $text-color-white;
          }
        }
      }

      .footer__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: rem(12);
        @media screen and (max-width: $breakpoint-pc) {
          flex-direction: column;
        }

        .left-contents {
          font-weight: 700;
          display: flex;

          .left-contents__link {
            color: $text-color-white;
          }
        }
      }
    }
  }
}
