.document-dl-page {
  .main_visual {
    .main_img {
      background: url(/images/mof-logo-and-meeting.png) no-repeat center center;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }

  &_section {
    width: 700px;
    margin: auto;
    margin-top: 15px;

    @media screen and (max-width: 1024px) {
      float: none;
      width: 100%;
      padding-top: 0;
      padding-bottom: 60px;
      form {
        width: 80%;
        margin: 0 auto;
      }
    }

    @media screen and (min-width: 1024px) {
      .contact_form {
        padding-top: 70px;
        width: 65%;
        display: flex;
        justify-content: flex-end;

        form {
          width: 80%;
        }
      }
    }
  }

  &_text {
    margin-bottom: 20px;

    @media screen and (max-width: 1024px) {
      margin: 15px;
    }
  }

  &_submit {
    margin-top: 40px;
  }

  &_button-container {
    margin-top: 45px;
    display: flex;
    justify-content: center;
  }
}
