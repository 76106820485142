.banner {
  &_section {
    width: 100%;
    height: 360px;
  }

  &_container {
    display: flex;
    height: 100%;
    flex-direction: row;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &_wrapper {
    position: relative;
    width: 50%;
    height: 100%;

    @media screen and (max-width: 1024px) {
      width: auto;
      height: 50%;
    }
  }

  &_about-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(1);
    opacity: 0.2;
  }

  &_mask {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100%;

    &--about {
      @extend .banner_mask;
      background-color: rgba(143, 143, 143, 0.8);
    }

    &--recruit {
      @extend .banner_mask;
      background-color: rgba(168, 168, 168, 0.8);
    }
  }

  &_card {
    width: 560px;
    height: 216px;
    background-color: white;
    filter: drop-shadow(2.3px 1.9px 3px gray);

    @media screen and (max-width: 1024px) {
      width: 288px;
      height: 156px;
    }
  }

  &_subtext {
    display: inline-block;
    padding-top: 30px;
    padding-left: 30px;

    @media screen and (max-width: 1024px) {
      padding-top: 20px;
      padding-left: 20px;
    }
  }

  &_headline {
    display: flex;
    justify-content: space-between;
    color: $primary-color;
    margin: 20px 0;
    padding: 0 27px;
    border-left: solid $primary-color 3px;
    font-size: rem(34);

    @media screen and (max-width: 1024px) {
      padding: 4px 17px;
      font-size: rem(19);
      margin: 0;
    }
  }

  &_text {
    display: inline-block;
    padding: 0 30px 30px 30px;
    font-size: rem(13);
    color: #252525;

    @media screen and (max-width: 1024px) {
      padding: 10px 20px 0 20px;
      font-size: rem(9);
      line-height: 1.9em;
    }
  }

  &_link {
    &:hover {
      text-decoration: none;
    }
  }
}

.banner-dev-team-intro {
  display: flex;
  justify-content: center;
}

.banner-dev-team-intro__link {
  @media screen and (max-width: 1024px) {
    width: 88.75%;
  }
}

.banner-dev-team-intro__link:hover {
  opacity: 0.7;
  transition: 0.3s;
}

.banner-dev-team-intro__img {
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
