@mixin sp($breakpoint: medium) {
  @if $breakpoint == xsmall {
    @media screen and (min-width: 320px) {
      @content;
    }
  } @else if $breakpoint == small {
    @media screen and (min-width: 600px) {
      @content;
    }
  } @else if $breakpoint == medium {
    @media screen and (min-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == large {
    @media screen and (min-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == xlarge {
    @media screen and (min-width: 1280px) {
      @content;
    }
  }
}

//figmaのvertical-trimに挙動を合わせるためのmixin
//(text-boxの上端と下端をそれぞれcap-height, base-lineまで切り詰める)
//trim量は https://seek-oss.github.io/capsize/ で得た数値を利用
//JP: Noto Sans JP
//EN: Lato
@mixin vertical-trim-JP {
  &::before {
    content: '';
    margin-bottom: calc((1em - 1lh) * 0.5 - 0.203em);
    // (line-height - 1em)の半分（=half-leading）とem-box(=fontsize)の上端からcap-heightまでの高さを引く
    display: table;
  }
  &::after {
    content: '';
    margin-top: calc((1em - 1lh) * 0.5 - 0.064em);
    // (line-height - 1.0em)の半分（=half-leading）とem-box(=fontsize)の下端からbase-lineまでの高さを引く
    display: table;
  }
}
@mixin vertical-trim-EN {
  &::before {
    content: '';
    margin-bottom: calc((1em - 1lh) * 0.5 - 0.1705em);
    display: table;
  }
  &::after {
    content: '';
    margin-top: calc((1em - 1lh) * 0.5 - 0.113em);
    display: table;
  }
}

// 実績詳細ページやお知らせページで使用される、マークダウン記法で生成された文章に使用する汎用スタイル
@mixin markdown-style {
  
  h1 {
    border-bottom: solid 1px $neutral-opacity-100;
    font-size: rem(24);
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 80px;
    padding-bottom: 8px;
  }

  h2 {
    font-size: rem(20);
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  h3 {
    font-size: rem(16);
    font-weight: bold;
    margin-bottom: 16px;
    margin-top: 24px;
  }

  p {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  a {
    @extend .text-link-primary;
  }

  img {
    max-width: 100%;
    margin: 32px auto;
    display: block;
    border-radius: $border-radius-s;
  }

  ul,
  ol {
    margin-bottom: 16px;
    margin-left: 20px;
    margin-top: 16px;
  }
  ul ul,
  ul ol,
  ol ol,
  ol ul {
    margin-bottom: 0;
    margin-top: 0;
  }

  ol ol,
  ul ol {
    list-style-type: lower-alpha;
  }

  ul ul ol,
  ul ol ol,
  ol ul ol,
  ol ol ol {
    list-style-type: lower-roman;
  }

  ul {
    list-style-type: none;
    li {
      position: relative;
      &::before {
        background: $text-color;
        border-radius: 50%;
        content: '';
        display: block;
        height: 4px;
        left: calc(-18px);
        position: absolute;
        top: calc(0.5*1.0lh - 2px); // lh: 1行の高さ
        width: 4px;
      }
    }
  }

  // コードブロック関連
  code {
    padding: .2em .4em;
    margin: 0 .2em;
    font-size: rem(14);
    background-color: $neutral-opacity-100;
    border-radius: 3px;
  }
  
  pre {
    word-wrap: normal;
  }
  
  pre>code {
    padding: 0;
    margin: 0;
    font-size: 100%;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
  }
  
  .highlight {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  
  .highlight pre {
    margin-bottom: 0;
    word-break: normal;
  }
  
  .highlight pre,
  pre {
    padding: 16px;
    overflow: auto;
    font-size: rem(14);
    line-height: 1.45;
    color: $text-color-white;
    background-color: $neutral-solid-800;
    border-radius: $border-radius-s;
  }
  
  pre code {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: initial;
    border: 0;
  }
  // END: コードブロック関連

  blockquote {
    background-color: #fff;
    border-left: solid 4px $neutral-opacity-100;
    margin-bottom: 24px;
    margin-top: 24px;
    padding: 0 0 0 24px;
    &::before,
    &::after {
      display: none;
    }
  }

  hr {
    border-bottom: solid 1px $neutral-opacity-100;
    margin: 24px 0;
    width: 100%;
  }

  table {
    margin-bottom: 24px;
    margin-top: 24px;
    width: 100%;
    th {
      border-bottom: solid 2px $neutral-opacity-200;
      padding: 8px;
    }
    td {
      border-bottom: solid 1px $neutral-opacity-100;
      padding: 8px;
    }
  }

}