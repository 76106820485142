.member-interview {
  margin-top: 120px;
  margin-bottom: 120px;
  .client-profile_interviewee {
    position: relative;
    padding-top: 80px;
  }
  &_title {
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin: auto;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    &_title {
      top: -20px;
      font-size: rem(34);
      line-height: 1.2;
      span {
        margin-top: 10px;
        font-size: $small-font-size;
      }
    }
  }
}
