.techs-etc {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  padding: 24px 16px;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
    line-height: 175%;
    letter-spacing: 0.02rem;
    text-align: center;
  }
  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
    @media screen and (max-width: $breakpoint-pc) {
      column-gap: 0px;
    }
    .icon {
      width: 96px;
      height: 96px;
    }
    .text {
      display: flex;
      align-items: end;
      height: 60px;
      font-size: rem(14);
      line-height: 1.8;
      letter-spacing: 0.06em;
      color: $neutral-solid-400;
    }
  }
}
