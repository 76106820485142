$heading-font-size: (
  // FIXME: タイトルの大きさについて、バラバラなので整理していきたい。
  // 下記の値は暫定なので、まだまだ変化して良い
  xxl: rem(104),
  xl: rem(66),
  lg: rem(28),
  md: rem(20)
);

%content-heading-base {
  font-size: rem(24);
  font-weight: bold;
  border-bottom: solid 1px $neutral-solid-100;
  position: relative;
  padding: 16px 0 12px 40px;
  margin-bottom: 8px;
  &::before {
    background-color: $mofmof-blue-400;
    border-radius: 2px;
    bottom: -8px;
    content: '';
    display: block;
    height: 64px;
    left: 8px;
    position: absolute;
    transform: skewX(-15deg);
    width: 12px;
  }
}

.content-heading {
  //NOTE: 藤田さんデザインになって実績詳細ページなどに使用されるようになった見出し
  @extend %content-heading-base;
  &.lg {
    // NOTE: もしかしたらサイズが大きいことではなく、下に英文の説明が入っている事でこのサイズ感になっているかもしれないので、
    // パターンが増えてきたら適宜修正していく
    font-size: rem(32);
    padding: 0 0 12px 48px;
    margin-bottom: 32px;
    @media screen and (max-width: $breakpoint-pc) {
      font-size: rem(24);
    }
    &::before {
      left: 12px;
      height: 84px;
    }
    .en,
    .ja {
      display: block;
      line-height: 150%;
    }
    .en {
      color: $primary-color;
      font-size: rem(12);
    }
  }
}

.page-top-heading {
  // ページトップのタイトル用のスタイル
  // figmaのコンポーネントと対応
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primary-color;
  font-family: $title-font-family;
  font-size: rem(80);
  font-weight: 700;
  line-height: 1;
  letter-spacing: rem(1);
  padding: 32px 0;
  gap: 4px;
  .subtext {
    font-family: $base-font-family;
    font-size: rem(20);
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: rem(0.8);
  }
  @media screen and (max-width: $breakpoint-pc) {
    padding: 24px 0;
    font-size: rem(64);
    .subtext {
      font-size: rem(16);
      letter-spacing: rem(0.64);
      line-height: 1.7;
    }
  }
  @media screen and (max-width: $breakpoint-tablet) {
    font-size: rem(48);
  }
}

%ttl {
  // FIXME: 汎用的なセレクタに特殊な状況でのスタイルが当てられすぎているので、プロパティを減らしたい
  color: $primary-color;
  font-family: $title-font-family;
  font-size: rem(64);
  letter-spacing: 3px;
  line-height: 1;
  margin-bottom: 70px;
  text-align: center;
  @media screen and (max-width: $breakpoint-tablet) {
    font-size: rem(48);
  }
  span,
  .subtext {
    display: block;
    font-size: $base-font-size;
    font-weight: bold;
    text-align: center;
  }
}

.ttl {
  @extend %ttl;

  @media screen and (max-width: $breakpoint-pc) {
    font-size: rem(40);
    line-height: 40px;
    margin-bottom: 15px;
  }

  &.ttl_sm {
    font-size: rem(40);
    margin-bottom: 20px;
    span {
      line-height: 2;
    }
  }

  &.ttl_md {
    font-size: rem(56);
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
      font-size: rem(32);
      line-height: 40px;
      margin-bottom: 15px;
      span {
        font-size: rem(12);
      }
    }
  }

  &.ttl_lg {
    font-size: rem(104);
    margin-bottom: 20px;
    @media screen and (max-width: $breakpoint-pc) {
      font-size: rem(64);
    }
  }

  &.ttl_left {
    text-align: left;
    span {
      text-align: left;
    }

    &.ttl_sm {
      line-height: 2;
      margin-bottom: 0px;
      padding-top: 70px;
      @media screen and (max-width: $breakpoint-pc) {
        font-size: rem(30);
        margin-bottom: 10px;
        padding-top: 30px;
        // FIXME： スマホサイズだと中央揃えになる、というのは特定のページでのみ起こるようにしたい
        text-align: center;
        span {
          font-size: rem(14);
          text-align: center;
        }
      }
    }
  }

  &.ttl_right {
    text-align: right;
    span {
      text-align: right;
    }
  }
}

.ttl-desc {
  // NOTE: 英語のタイトル文字に対する日本語テキスト
  @extend %ttl;
  font-size: rem(40);
  line-height: 1.7rem;
  margin-bottom: 0;
  padding-top: 16px;
}

// FIXME: ここから下は特定のページでしか使われないタイトルなので、project配下などに移動したい

.ttl--search-result {
  font-family: $title-font-family;
  color: $primary-color;
  text-align: center;
  font-size: rem(24);
  margin-bottom: 16px;
  display: block;
}

.ttl--search-tag {
  font-family: $title-font-family;
  text-align: center;
  font-size: $base-font-size;
  display: block;

  a {
    color: $text-color-light;
    font-weight: bold;
    text-decoration: none;
    margin-right: 8px;

    &:hover {
      color: $primary-color;
    }
  }
}

.ttl--column {
  font-family: $title-font-family;
  color: $primary-color;
  text-align: center;
  font-size: rem(28);
  margin-bottom: 28px;
  display: block;
  line-height: 1.5;
}

.ttl_recruit {
  position: relative;
  border-bottom: solid 1px $primary-color;
  font-size: $base-font-size;
  padding: 38px 30px;
  margin-top: 50px;

  &:first-child {
    margin-top: 0;
  }

  @media screen and (max-width: $breakpoint-pc) {
    background: $neutral-white;
    font-size: rem(12);
    padding: 20px;
    margin-top: 10px;
  }

  &::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    right: 20px;
    width: 20px;
    height: 100%;
    background: url(/images/button_open.svg) no-repeat center;
    background-size: contain;
  }

  &.open {
    border-bottom: none;

    &::after {
      background: url(/images/button_close.svg) no-repeat center;
    }
  }
}

.ttl_blog {
  font-family: $title-font-family;
  font-size: map-get($heading-font-size, md);

  img {
    vertical-align: middle;
  }

  .logo_m {
    width: 30px;
    height: 27px;
    padding-right: 15px;
  }

  .logo {
    padding-right: 14px;
    width: 90px;
  }
}

// FIXME: この辺りのタイトルは他のものと統合できそう
h1.page_title {
  position: relative;
  font-size: rem(28);
  text-align: center;
  padding: 80px 0;
  padding-bottom: 60px;
  color: $primary-color;

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 1px;
    height: 100px;
    position: absolute;
    top: -120px;
    left: 50%;
    background: $primary-color;
  }
}

@media screen and (max-width: $breakpoint-pc) {
  h1.page_title {
    font-size: rem(24);
    padding: 30px 15px;

    &::after {
      top: -85px;
      height: 30px;
    }
  }
}

.ttl_case {
  position: relative;
  margin: 0 auto;
  margin-bottom: 50px;
  color: $primary-color;
  text-align: center;
  padding-bottom: 50px;
  background-image: linear-gradient(
    to right,
    $primary-color 55%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;

  .main_ttl {
    display: inline-block;
    font-size: rem(30);
    text-align: center;
    border-bottom: solid 1px $primary-color;
    margin-bottom: 10px;
  }

  .sub_ttl {
    display: block;
    font-weight: normal;
    font-size: $base-font-size;
  }

  @media screen and (max-width: $breakpoint-pc) {
    padding: 30px;
    margin-bottom: 30px;
    .main_ttl {
      font-size: rem(20);
    }
    .sub_ttl {
      font-size: rem(10);
    }
  }
}
