.inhouse-service {
  .main_visual {
    .main_img {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }

  &_about-rental {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    span {
      font-size: rem(24);
      color: $primary-color;
      font-weight: bold;
      line-height: 2.9;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      max-width: 550px;
      margin: 0 auto;
    }
  }

  &_about-rental-button {
    width: 49%;
    box-sizing: border-box;
    margin-top: 10px;
    height: 70px;
    position: relative;

    a {
      text-decoration: none;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      position: absolute;
      display: block;
      content: "";
      height: 100%;
      width: 20px;
      top: 0;
      right: 20px;
      background: url(../images/arrow.svg) no-repeat center;
      background-size: contain;
    }

    &:hover {
      background-color: $primary-color;
      color: $text-color-white;

      span {
        color: $text-color-white;
      }

      &:after {
        background: url(../images/arrow_white.svg) no-repeat center;
        background-size: contain;
      }
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      box-sizing: border-box;
      margin-top: 10px;
    }
  }
}
