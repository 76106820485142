.works {
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .breadcrumbs__outer {
    padding: 16px 24px;
    max-width: 100%;
  }
  .container {
    width: 100%;
    max-width: $breakpoint-xl;
  }
  .posts-container {
    margin: 0 auto;
    width: 100%;
    padding: 0 24px;
    margin-top: 16px;
    margin-bottom: 64px;
    .posts {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 64px 40px;
      margin-bottom: 48px;
      justify-content: center;
      justify-items: center;
      @media screen and (max-width: $breakpoint-pc) {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 64px;
      }
      .post {
        border-bottom: solid 1px $neutral-opacity-100;
        width: 100%;
        overflow: hidden;
        transition: 0.3s;
        @media screen and (max-width: $breakpoint-pc) {
          max-width: 600px;
        }
        .main-visual {
          border-radius: $border-radius-s;
          height: 300px;
          margin-bottom: 16px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          @media screen and (max-width: $breakpoint-tablet) {
            height: 200px;
          }
          .main-visual__image {
            height: 100%;
            transition: 0.3s;
          }
        }
        .post__content {
          padding-bottom: 20px;
          .service-name {
            margin-bottom: 4px;
          }
          .project-name {
            font-size: rem(32);
            line-height: 150%;
            margin-bottom: 4px;
            transition: 0.3s;
            @media screen and (max-width: $breakpoint-tablet) {
              font-size: rem(28);
            }
          }
          .project-summary {
            color: $text-color;
            font-size: rem(20);
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 20px;
            @media screen and (max-width: $breakpoint-tablet) {
              font-size: rem(16);
            }
          }
          .separator {
            background-color: $mofmof-blue-400;
            border-radius: 2px;
            height: 4px;
            margin-bottom: 20px;
            width: 80px;
          }
          .client-name {
            color: $text-color;
            font-size: rem(14);
            font-weight: 500;
          }
        }

        &:hover {
          opacity: .8;
          .main-visual__image {
            scale: 1.05;
          }
          .post__content {
            .project-name {
              color: $mofmof-blue-600;
            }
          }
        }
        &:hover, &:hover * {
          text-decoration: none;
        }
      }
    }
  }

  .various-works-section {
    background-color: $background-base-300;
    padding: 104px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .various-works-section__title {
      > span {
        display: inline-block;
      }
      text-align: center;
      font-size: rem(28);
      font-weight: 700;
      line-height: 150%;
      letter-spacing: rem(1.12);
      @media screen and (max-width: $breakpoint-md) {
        font-size: rem(24);
        letter-spacing: rem(0.96);
      }
    }
    .various-works-wrapper {
      max-width: $breakpoint-xl;
      width: 100%;
      padding: 0 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      @media screen and (max-width: $breakpoint-md) {
        padding: 0 16px;
      }
    }
    .pc-container {
      background-color: $background-base-100;
      padding: 24px;
      border-radius: $border-radius-m;
      @media screen and (max-width: $breakpoint-md) {
        display: none;
      }
    }
    .pc-table {
      font-size: rem(12);
      line-height: 1.8;
      thead {
        background-color: $neutral-solid-100;
      }
      th {
        padding: 0 12px;
        height: 40px;
        text-align: center;
        vertical-align: middle;
      }
      td {
        padding: 12px;
      }
      tbody > tr:nth-of-type(even) {
        background-color: $neutral-opacity-30;
      }
      tbody > tr:not(:last-child) {
        border-bottom: solid 1px $neutral-opacity-100;
      }
      .pc-table__category {
        width: 132px;
        text-align: center;
        vertical-align: middle;
      }
      .pc-table__service-name {
        width: 122px;
        font-weight: 700;
        text-align: center;
        vertical-align: middle;
      }
      .pc-table__project-name {
        width: 160px;
        vertical-align: middle;
        text-align: justify;
      }
      .pc-table__summary {
        text-align: justify;
      }
    }

    .mobile-accordion {
      background-color: $background-base-100;
      padding: 0 0 8px;
      width: 100%;
      border-radius: $border-radius-s;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10), 0px 4px 16px 3px rgba(0, 0, 0, 0.05);
      display: none;
      transform: translate3d(0, 0, 0); //アコーディオン開閉で影の残像が残ってしまう対策
      @media screen and (max-width: $breakpoint-md) {
        display: flex;
        flex-direction: column;
      }
      .mobile-accordion__item:not(:last-child) {
        border-bottom: 1px solid $neutral-opacity-50;
      }
      .mobile-accordion__header {
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        cursor: pointer;
        // アコーディオンオープン時のスタイル
        &.open {
          .mobile-accordion__header-lower::after {
            transform: rotate(-180deg);
          }
        }
      }

      .mobile-accordion__project-name {
        color: $text-color;
        font-size: rem(14);
        font-weight: 500;
        line-height: 170%;
        letter-spacing: rem(0.56);
        padding-right: 40px;
      }
      .mobile-accordion__header-lower {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        &::after {
          flex-shrink: 0;
          transition: 0.5s;
          content: '';
          $icon_size: 20px;
          width: $icon_size;
          height: $icon_size;
          background-image: url("/images/ico_chevron-down-primary.svg");
        }
      }
      .mobile-accordion__description {
        color: $text-color-light;
        font-size: rem(10);
        font-weight: 500;
        line-height: 150%;
        letter-spacing: rem(0.4);
        display: flex;
        .mobile-accordion__category {
          text-wrap: nowrap;
        }
        .mobile-accordion__separator {
          display: inline-block;
          width: 1px;
          height: 13px;
          border-left: 1px solid $neutral-opacity-100;
          margin: 2px 8px 0;
        }
      }
      .mobile-accordion__content {
        display: none;
        background-color: $background-base-200;
        border-top: 1px solid $neutral-opacity-50;
      }
      .mobile-accordion__content-inner {
        padding: 12px 12px 24px;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      .mobile-accordion__summary-title {
        font-size: rem(10);
        font-weight: 700;
        line-height: 150%;
        letter-spacing: rem(0.4);
      }
      .mobile-accordion__summary {
        font-size: rem(12);
        line-height: 180%;
        letter-spacing: rem(0.72);
        text-align: justify;
      }
    }
  }
}
