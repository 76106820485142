.jekyll-linkpreview {
  display: flex;
  padding: 12px;
  background-color: $text-color-white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  &-image {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
  }

  &-body {
    width: 100%;
  }

  &-title {
    margin-bottom: 4px;

    a {
      color: #3d4245;
      text-decoration: none;
    }
  }

  &-description {
    font-size: 0.75rem;
    line-height: 1.5;
  }

  &-footer {
    margin-top: 8px;
    font-size: 0.75rem;

    a {
      color: #666;
      font-weight: normal;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  .jekyll-linkpreview {
    &-title,
    &-description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-image {
      display: none;
    }
  }
}
