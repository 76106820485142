.development-team-rental {
  .main {
    background-image: url("/images/service/dev-team-rental/startup/keyvis/bg-cloud-pc.png"),
                      url("/images/service/dev-team-rental/startup/keyvis/splitter.png"),
                      url("/images/service/dev-team-rental/startup/keyvis/bg_green.svg");
    background-position: left -2% top 12%, bottom, center;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: auto, contain, cover;
    height: 806px;
    position: relative;
    @media screen and (max-width: $breakpoint-pc) {
      background-image: url("/images/service/dev-team-rental/startup/keyvis/bg-cloud-sp.png"),
                        url("/images/service/dev-team-rental/startup/keyvis/splitter.png"),
                        url("/images/service/dev-team-rental/startup/keyvis/bg_green.svg");
      background-position: left -7% top 10%, bottom, center;
      height: 744px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      height: 686px;
    }
    .header {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      gap: 16px;
      height: 66px;
      justify-content: space-between;
      padding: 0px 32px;
      top: 0;
      width: 100%;
      z-index: $z-index-header;
      @media screen and (max-width: $breakpoint-tablet) {
        padding: 0px 24px;
      }
      .header__logo {
        .logo-white {
          @extend %sp-header-logo-base;
          display: block;
        }
        .logo-primary {
          @extend %sp-header-logo-base;
          display: none;
        }
        
      }
      
      .header__right {
        display: flex;
        align-items: center;
        @media screen and (max-width: $breakpoint-tablet) {
          display: none;
        }
        .nav {
          display: flex;
          column-gap: 20px;
          list-style-type: none;
          margin-right: 16px;
          .nav__item {
            .nav__item--link {
              font-size: rem(14);
              font-weight: 700;
              color: $text-color-white;
              text-decoration: none;
              &.scrolled {
                color: $primary-color;
              }
            }
          }
        }
        .buttons {
          display: flex;
          column-gap: 20px;
          .btn-download {
            color: $primary-color;
            background: $neutral-white;
            @media screen and (max-width: $breakpoint-pc) {
              display: none;
            }
          }
          .btn-contact {
            @media screen and (max-width: $breakpoint-pc) {
              display: none;
            }
          }
        }
      }
      &.sticky {
        position: fixed;
        background: $neutral-white;
        opacity: 0;
        .header__logo {
          .logo-white {
            display: none;
          }
          .logo-primary {
            display: block;
          }
        }
        .sp-menu {
          .sp-menu__box {
            .menu-icon-white {
              display: none;
            }
            .menu-icon-primary {
              display: block;
            }
          }
        }
      }
      &.sticky-un-visible {
        opacity: 0;
        transition: 0.2s;
      }
      &.sticky-visible {
        opacity: 1;
        transition: 0.2s;
      }
    }
    
    .keyvis {
      height: 100%;
      padding-top: 8px;
      position: relative;
      width: 100%;
      @media screen and (max-width: $breakpoint-pc) {
        padding-top: 0;
      }
      .image {
        filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.24));
        height: 347px;
        margin-left: auto;
        padding-right: 40px;
        width: 66%;
        position: relative;
        @media screen and (max-width: $breakpoint-pc) {
          padding-right: 0;
          height: 317px;
          width: 62%;
        }
        @media screen and (max-width: $breakpoint-tablet) {
          width: 85%;
          height: 221px;
        }
        .image__bg {
          background-image: url("/images/service/dev-team-rental/startup/keyvis/image-block-bg.svg");
          background-size: cover;
          border-radius: 28px;
          height: calc(100% - 40px);
          @media screen and (max-width: $breakpoint-pc) {
            border-radius: 28px 0px 0px 28px;
          }
        }
        .image__main {
          box-sizing: border-box;
          height: 94%;
          left: -20px;
          max-width: 840px;
          object-fit: cover;
          object-position: bottom center;
          top: 0;
          position: absolute;
          width: 98%;
          filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.24));
          @media screen and (max-width: $breakpoint-pc) {        
            object-position: bottom left;
            width: calc(100% + 20px);
          }
        }
      }
      .copy {
        display: flex;
        flex-direction: column;
        gap: 16px;
        left: 7%;
        position: absolute;
        top: 56px;
        z-index: 1;
        @media screen and (max-width: $breakpoint-pc) {
          gap: 14px;
          top: 48px;
        }
        @media screen and (max-width: $breakpoint-tablet) {
          gap: 10px;
          left: 5%;
          top: 32px;
        }
        .copy__line {
          font-size: rem(24);
          font-weight: 500;
          letter-spacing: 6px;
          color: $neutral-solid-700;
          background: $neutral-white;
          border-radius: 4px;
          padding: 4px 6px 6px 6px;
          white-space: nowrap;
          width: fit-content;
          filter: drop-shadow(9px 12px 50px rgba(0, 0, 0, 0.25));
          @media screen and (max-width: $breakpoint-pc) {
            font-size: rem(20);
            letter-spacing: 5px;
          }
          @media screen and (max-width: $breakpoint-tablet) {
            font-size: rem(16);
            letter-spacing: 4px;
            padding: 3px 4px 4px 4px;
          }
        }
      }
      .content__main {
        position: absolute;
        top: 360px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        @media screen and (max-width: $breakpoint-pc) {
          top: 336px;
          left: 50%;
          transform: translateX(-50%);
          row-gap: 16px;
          align-items: center;
        }
        @media screen and (max-width: $breakpoint-tablet) {
          top: 250px;
        }
        .title {
          width: 552px;
          margin-bottom: -8px;
          filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.25));
          @media screen and (max-width: $breakpoint-pc) {
            width: 472px;
            filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.25));
          }
          @media screen and (max-width: $breakpoint-tablet) {
            width: 320px;
            max-width: calc(100vw - 16px);
            filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
          }
        }
        .description {
          color: $text-color-white;
          font-weight: 500;
          letter-spacing: 0.15em;
          line-height: 235.3%;
          text-align: center;
          white-space: nowrap;
          @media screen and (max-width: $breakpoint-pc) {
            text-align: center;
          }
        }
        .buttons {
          column-gap: 32px;
          display: flex;
          justify-content: center;
          .btn-download {
            background: $neutral-white;
            color: $primary-color;
          }
        }
      }
      .scroll-sign {
        bottom: 120px;
        color: $text-color-white;
        font-family: 'Barlow';
        font-size: rem(10);
        font-weight: 700;
        position: absolute;
        text-align: center;
        width: 100%;
        &::after {
          $icon-width: 12px;
          background: url("/images/ico_chevron-down_white.svg") no-repeat center;
          content: "";
          display: block;
          height: 8px;
          left: calc(50% - #{$icon-width} * 0.5);
          position: absolute;
          width: $icon-width;
        }
      }
    }
  }
}
