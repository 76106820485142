/* =============================================
mofmof開発チーム紹介ページ
============================================= */

/* 共通項目
====================================================*/

// Utility
.u-flex-center {
  display: flex;
  // display: -webkit-box;
  align-items: center;
  -webkit-box-align: center;
}

.u-justifyx-center {
  justify-content: center;
  -webkit-box-pack: center;
}

.u-align-bottom {
  -webkit-box-align: end;
  align-items: flex-end;
}

.u-w-50 {
  width: 50% !important;
  box-sizing: border-box;
}

.u-w-33 {
  width: 33% !important;
  box-sizing: border-box;
}

.u-w-16 {
  width: 16.6% !important;
  box-sizing: border-box;
}

.only-sp {
  display: none;
}

// 最上部タイトル
.mofmof-team__heading {
  font-size: rem(65);
  margin-top: 48px;
  margin-bottom: 80px;
  line-height: 100%;
  letter-spacing: 2px;
  color: $primary-color;
}

// タブメニュー
.mofmof-team__tabmenu {
  width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;

  .link-item {
    display: inline-block;
    line-height: 75px;
    font-size: rem(18);
    color: $primary-color;
    width: 230px;
    margin-right: 40px;
    border-radius: 10px 10px 0 0;
    text-align: center;
    border: solid 2px $primary-color;
    border-bottom: none;

    &:hover {
      text-decoration: none;
      background-color: $background-color-old;
    }

    &.active {
      background-color: $background-color-old;
      font-weight: bold;
    }
  }
}

// コンテナ
.mofmof-team__container {
  border-top: solid 2px $primary-color;
  background-color: $background-color-old;
  margin-top: -2px;
}

// セクション
.mofmof-team__section {
  width: 1200px;
  margin: 0 auto;
  padding: 70px 0;
  box-sizing: border-box;
}

// セクションごとのタイトル
.mofmof-team__title {
  margin-bottom: 50px;
  text-align: center;

  h2 {
    color: $primary-color;
    margin-bottom: 20px;
    font-size: rem(55);
    line-height: 100%;
  }

  span {
    color: $primary-color;
    font-size: rem(25);
    display: block;
    margin-bottom: 30px;
  }

  p {
    line-height: 1.8;
  }
}

// レスポンシブ：共通項目
@media screen and (max-width: 1024px) {
  // 最上部タイトル
  .mofmof-team__heading {
    font-size: rem(35);
    margin-top: 24px;
    margin-bottom: 60px;
  }

  // タブメニュー
  .mofmof-team__tabmenu {
    width: 100%;
    padding: 0 10px;

    .link-item {
      line-height: 55px;
      font-size: $base-font-size;
      width: 150px;
      margin-right: 10px;
    }
  }

  // セクション
  .mofmof-team__section {
    width: 100% !important;
    padding: 30px 0;
  }

  // セクションごとのタイトル
  .mofmof-team__title {
    margin-bottom: 40px;

    h2 {
      margin-bottom: 10px;
      font-size: rem(30);
    }

    span {
      font-size: $base-font-size;
      margin-bottom: 30px;
    }

    p {
      font-size: $small-font-size;
    }
  }

  .only-sp {
    display: block;
  }

  .only-pc {
    display: none !important;
  }
}

/* mofmofの開発とは
====================================================*/
.mofmof-team__section.feature {
  width: 1200px;
  margin: 0 auto;
  padding: 120px 20px 70px;
}

.mofmof-team .feature {
  .mofmof-team__title {
    margin-bottom: 0;
  }

  &__list {
    padding: 0 20px;
  }

  &__item {
    margin: 30px 0;

    p {
      font-size: rem(24);
      color: $primary-color;
      font-weight: bold;
      margin-left: 15px;
    }
  }
}

// レスポンシブ：mofmofの開発とは
@media screen and (max-width: 1024px) {
  .mofmof-team__section.feature {
    width: 100%;
    padding: 50px 20px;
  }

  .mofmof-team__section.feature > .u-flex-center {
    display: block;
  }

  .u-w-50 {
    width: 100% !important;
  }

  .mofmof-team .feature {
    .mofmof-team__title {
      margin-bottom: 30px;
    }

    &__list {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      margin: 20px 0;

      img {
        width: 50px;
      }

      p {
        font-size: $base-font-size;
        width: 204px;
      }
    }
  }
}

/* mofmofが選ばれる3つの理由
====================================================*/
.mofmof-team .sttength {
  padding: 0 20px;

  .mofmof-team__title {
    margin-bottom: 120px;
  }

  &__list {
    text-align: center;

    h4 {
      color: $primary-color;
      font-size: rem(18);
      margin: 20px 0 10px;
    }

    p {
      font-size: rem(13);
    }
  }

  &__illust {
    position: relative;
    width: 300px;
    margin: 0 auto;
  }

  &__number {
    position: absolute;
    top: -30px;
    left: -30px;
    width: 60px;
    height: 60px;
    border: solid 2px $primary-color;
    background-color: $mofmof-blue-50;
    color: $primary-color;
    border-radius: 50%;
    text-align: center;
    font-size: rem(32);
    line-height: 55px;
    font-weight: bold;
  }
}

// レスポンシブ：mofmofが選ばれる3つの理由
@media screen and (max-width: 1024px) {
  .mofmof-team .sttength {
    .mofmof-team__title {
      margin-bottom: 60px;
    }

    .u-flex-center {
      display: block;
    }

    .u-w-33 {
      width: 100% !important;
    }

    &__list {
      margin-bottom: 50px;

      h4 {
        font-size: $base-font-size;
        margin: 15px 0 10px;
      }

      p {
        font-size: rem(12);
        line-height: 1.5;
      }
    }

    &__illust {
      position: relative;
      width: 300px;
      margin: 0 auto;
    }

    &__number {
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      width: 50px;
      height: 50px;
      font-size: rem(25);
      line-height: 50px;
    }
  }
}

/* mofmofのプロダクトで使われている技術
====================================================*/
.mofmof-team__section.skill {
  width: 1000px;
  padding: 70px 20px;
}

.mofmof-team__skill {
  .u-w-33,
  .u-w-16 {
    padding: 10px;
  }

  .skill-card {
    background: $neutral-white;
    border-radius: 10px;
    padding: 30px;
    justify-content: space-between;
    -webkit-box-pack: justify;

    &__caption {
      width: 60%;
      font-size: rem(12);

      h5 {
        font-size: rem(22);
        color: $primary-color;
        margin-bottom: 10px;
      }

      p {
        line-height: 2;
      }
    }
  }
  .skill-card.sm {
    text-align: center;

    h5 {
      font-size: $base-font-size;
      color: $primary-color;
      margin-bottom: 10px;
      letter-spacing: 0;
    }
  }

  .skill-other {
    width: 98%;
    background: $neutral-white;
    border: dashed 2px $primary-color;
    margin: 40px auto 0;
    padding: 30px;
    box-sizing: border-box;

    h5 {
      font-size: rem(18);
      color: $primary-color;
      margin-bottom: 15px;
    }

    img {
      display: inline-block;
      margin: 0 15px;
    }
  }
}

// レスポンシブ：mofmofのプロダクトで使われている技術
@media screen and (max-width: 1024px) {
  .mofmof-team__section.skill {
    width: 100%;
  }

  .mofmof-team__skill {
    .skill-card-box--lg {
      display: block;
    }

    .skill-card-box--sm {
      flex-wrap: wrap;
    }

    .u-w-33 {
      width: 100% !important;
    }

    .u-w-16 {
      width: 50% !important;
    }

    .skill-other {
      width: 100%;

      h5 {
        font-size: $base-font-size;
      }
    }
  }
}

/* mofmof流プロジェクトの進め方
====================================================*/
.mofmof-team__flow {
  margin-top: 80px;
  padding: 0 20px;

  .u-w-50 {
    padding: 10px;
  }

  // 向いていること・向いていないこと
  .flow-list {
    margin-bottom: 80px;

    &__balloon {
      font-weight: bold;
      position: relative;
      text-align: center;
      width: 100%;
      display: inline-block;
      margin: 15px 0 30px;
      padding: 15px;
      color: $primary-color;
      font-size: $base-font-size;
      background: $mofmof-blue-50;
      border: solid 2px $primary-color;
      box-sizing: border-box;
      border-radius: 99px;
    }

    &__balloon:before {
      content: '';
      position: absolute;
      bottom: -24px;
      left: 50%;
      margin-left: -15px;
      border: 12px solid transparent;
      border-top: 12px solid $mofmof-blue-50;
      z-index: 2;
    }

    &__balloon:after {
      content: '';
      position: absolute;
      bottom: -29px;
      left: 50%;
      margin-left: -17px;
      border: 14px solid transparent;
      border-top: 14px solid $primary-color;
      z-index: 1;
    }

    &__balloon p {
      margin: 0;
      padding: 0;
    }

    &__balloon img {
      position: absolute;
      left: 15px;
      top: -55px;
    }

    &__contents {
      color: $primary-color;
      font-weight: bold;
      border: solid 2px $primary-color;
      border-radius: 10px;
      background: $neutral-white;
      font-size: $small-font-size;

      li {
        padding: 35px;
        border-bottom: dashed 1px $primary-color;
        list-style: none;
      }

      li:last-child {
        border-bottom: none;
      }

      li.bg-blue {
        background: rgba($mofmof-blue-50, 0.5);
      }

      li::before {
        content: '';
        display: inline-block;
        margin-right: 10px;
        width: 10px;
        height: 10px;
        background-color: $primary-color;
        border-radius: 50%;
      }

      span {
        color: $text-color;
        font-weight: normal;
      }
    }
  }

  .flow-list.unsuitable {
    .flow-list__balloon {
      color: $attention-color;
      background: $red-50;
      border: solid 2px $attention-color;
    }

    .flow-list__balloon:before {
      content: '';
      border-top: 12px solid $red-50;
    }

    .flow-list__balloon:after {
      border-top: 14px solid $attention-color;
    }

    .flow-list__contents {
      color: $attention-color;
      border: solid 2px $attention-color;

      li {
        border-bottom: dashed 1px $attention-color;
      }

      li:last-child {
        border-bottom: none;
      }

      li.bg-red {
        background: rgba($red-50, 0.5);
      }

      li::before {
        background-color: $attention-color;
      }
    }
  }

  // なんかいい感じにしてくれるエンジニア
  .flow-engineer {
    position: relative;
    text-align: center;
    width: 100%;
    display: inline-block;
    padding: 100px 20px 150px;
    background: $neutral-white;
    border: solid 2px $primary-color;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 100px;

    &__tit {
      font-size: rem(35);
      color: $primary-color;
      font-weight: bold;
      margin-bottom: 50px;

      h3 {
        display: inline-block;
        border-bottom: solid 10px $secondary-color;
        margin-bottom: 20px;
      }

      span {
        font-size: rem(25);
        display: block;
      }
    }

    &__list {
      list-style: none;
    }

    &__item {
      position: absolute;
    }

    &__contents {
      position: relative;
    }

    &__caption {
      position: absolute;
      width: 210px;

      h4 {
        color: $primary-color;
        margin-bottom: 8px;
        line-height: 150%;
      }

      p {
        font-size: rem(13);
        line-height: 1.8;
      }
    }

    // ポジション：アジャイ力
    .agile {
      top: 200px;
      left: 150px;

      .flow-engineer__caption {
        left: -55px;
        top: 115px;
      }
    }

    // ポジション：ビジネス力
    .business {
      bottom: 180px;
      left: 130px;

      .flow-engineer__caption {
        left: -55px;
        top: 180px;
      }
    }

    // ポジション：プロジェクトマネジメント力
    .management {
      top: 170px;
      right: 170px;

      .flow-engineer__caption {
        right: -50px;
        top: 115px;
      }
    }

    // ポジション：コミュニケーション力
    .communication {
      top: 400px;
      right: 80px;

      .flow-engineer__caption {
        right: -50px;
        top: 105px;
      }
    }

    // ポジション：コミュニケーション力
    .think {
      bottom: 160px;
      right: 200px;

      .flow-engineer__caption {
        right: -55px;
        top: 155px;
      }
    }
  }

  .flow-engineer:before {
    content: '';
    position: absolute;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    border: 60px solid transparent;
    border-top: 40px solid $neutral-white;
    z-index: 2;
  }

  .flow-engineer:after {
    content: '';
    position: absolute;
    bottom: -108px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    border: 64px solid transparent;
    border-top: 44px solid $primary-color;
    z-index: 1;
  }

  // 詳しくはこちら
  .flow-detail {
    &__tit {
      font-size: rem(25);
      color: $primary-color;
      margin-bottom: 30px;
    }

    &__card {
      background: $neutral-white;
      border: solid 2px $primary-color;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 40px;
      margin: 0 auto;
      width: 750px;
    }

    &__contents {
      width: 340px;
      margin-left: 30px;

      h4 {
        font-size: $base-font-size;
        color: $primary-color;
        margin-bottom: 20px;

        &::after {
          content: '';
          display: block;
          width: 50px;
          height: 2px;
          background: $primary-color;
          margin-top: 20px;
        }
      }

      p {
        font-size: rem(13);
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        margin-bottom: 20px;
      }
    }

    &__button {
      text-align: right;

      .button {
        border-radius: 99px;
        border: solid 1px $primary-color;
      }
    }
  }
}

// レスポンシブ：mofmof流プロジェクトの進め方
@media screen and (max-width: 1024px) {
  .mofmof-team__flow {
    margin-top: 0;

    & > .u-flex-center {
      display: block;
    }

    .u-w-50 {
      padding: 10px;
    }

    // 向いていること・向いていないこと
    .flow-list {
      margin-bottom: 40px;

      &__balloon img {
        left: 0;
        top: -35px;
        width: 85px;
      }
    }

    // なんかいい感じにしてくれるエンジニア
    .flow-engineer {
      width: 100%;
      padding: 40px 15px;
      margin-bottom: 80px;

      &__tit {
        font-size: rem(25);
        margin-bottom: 30px;

        h3 {
          display: inline-block;
          margin-bottom: 15px;
          line-height: 1.8;
        }

        span {
          font-size: rem(18);
          display: block;
        }
      }

      .flow-engineer__cover {
        width: 80%;
        margin-bottom: 20px;
      }

      &__list {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
      }

      &__item {
        position: inherit;
        width: 50%;
        box-sizing: border-box;
        padding: 10px;
      }

      &__caption {
        position: inherit;
        width: 100%;

        h4 {
          margin-bottom: 8px;
          margin-top: 10px;
          font-size: $small-font-size;
          line-height: 1.5;
        }

        p {
          font-size: rem(12);
          line-height: 1.8;
        }
      }
    }

    // 詳しくはこちら
    .flow-detail {
      &__tit {
        font-size: rem(20);
        margin-bottom: 15px;
      }

      &__card {
        padding: 30px;
        width: 100%;

        .u-flex-center {
          display: block;
        }

        img {
          width: 100%;
        }
      }

      &__contents {
        width: 100%;
        margin-left: 0;
        margin-top: 15px;

        h4 {
          font-size: $base-font-size;
          margin-bottom: 15px;

          &::after {
            margin-top: 15px;
          }
        }

        p {
          font-size: rem(12);
          margin-bottom: 25px;
        }
      }

      &__button {
        text-align: center;
      }
    }
  }
}

/* 実績
====================================================*/
.mofmof-team__section.works {
  padding: 70px 20px;
}

.mofmof-team__works {
  margin-bottom: 40px;

  span {
    color: $primary-color;
    font-size: $base-font-size;
    font-weight: bold;
    display: block;
    text-align: center;
    margin-bottom: 30px;
  }

  .works__card {
    background: $neutral-white;
    border-radius: 10px;
    padding: 30px;
    justify-content: space-between;
    -webkit-box-pack: justify;
  }

  .works__supplement {
    p {
      font-size: $small-font-size;
    }
  }

  .works__logo {
    margin-bottom: 30px;

    img {
      display: inline-block;
      margin: 0 20px;
    }

    a:hover {
      opacity: 0.5;
      transition: 0.3s;
    }
  }
}

// レスポンシブ：実績
@media screen and (max-width: 1024px) {
  .mofmof-team__works {
    margin-bottom: 20px;

    span {
      color: $primary-color;
      font-size: $base-font-size;
      font-weight: bold;
      text-align: center;
      display: block;
      margin-bottom: 0;
    }

    .works__supplement {
      p {
        font-size: rem(12);
      }
    }

    .works__logo {
      margin-bottom: 15px;
      flex-wrap: nowrap;
      overflow: hidden;

      img {
        margin: 0 10px;
        height: 30px;
      }
    }

    .works__wrap {
      display: flex !important;
    }
  }
}

/* 下部のナビゲーションボタン
====================================================*/
.mofmof-team__nav-btn {
  background: #f2fafb url(/images/bg-transparent.png) repeat center top;
  border-top: solid 2px $primary-color;
  margin-bottom: 50px;

  h3 {
    font-size: rem(25);
    color: $primary-color;
    margin-bottom: 50px;
  }

  button {
    margin-top: 0 !important;
    margin: 0 10px;
  }

  .btn-wrap {
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 50px;
  }
}

// レスポンシブ：下部のナビゲーションボタン
@media screen and (max-width: 1024px) {
  .mofmof-team__nav-btn {
    margin-bottom: 30px;

    .mofmof-team__section {
      padding: 50px 20px;
    }

    h3 {
      font-size: rem(20);
      line-height: 1.5;
      margin-bottom: 30px;
    }

    button {
      margin-top: 0 !important;
      margin: 15px 0;
      width: 100% !important;
    }

    .btn-wrap {
      display: block;
      margin-bottom: 40px;
    }
  }
}

/* 興味・関心ごと
====================================================*/
.mofmof-team__section.interests {
  width: 1200px;
  margin: 0 auto;
  padding: 120px 20px 0;
}

.mofmof-team .interests {
  .mofmof-team__title {
    margin-bottom: 0;
  }

  &__list {
    padding: 0 40px;
  }

  &__item {
    margin: 30px 0;

    p {
      font-size: rem(24);
      color: $primary-color;
      font-weight: bold;
      margin-left: 15px;
    }
  }
}

// レスポンシブ：興味・関心ごと
@media screen and (max-width: 1024px) {
  .mofmof-team__section.interests {
    width: 100%;
    padding: 50px 20px;
  }

  .mofmof-team__section.interests > .u-flex-center {
    display: block;
  }

  .u-w-50 {
    width: 100% !important;
  }

  .mofmof-team .interests {
    .mofmof-team__title {
      margin-bottom: 30px;
    }

    &__list {
      padding: 0;
    }

    &__item {
      margin: 20px 0;

      img {
        margin: 0 auto;
        width: 300px;
      }

      p {
        font-size: $base-font-size;
        width: calc(100% - 65px);
      }
    }
  }
}

/* 年代
====================================================*/
.mofmof-team__section.age {
  width: 1200px;
  margin: 0 auto;
  padding: 0 20px 70px;
}

.mofmof-team .age {
  .mofmof-team__title {
    margin-bottom: 0;
  }

  &__list {
    padding: 0 40px;
  }

  &__item {
    margin: 30px 0;

    img {
      margin: 0 auto;
    }

    p {
      font-size: rem(24);
      color: $primary-color;
      font-weight: bold;
      margin-left: 15px;
    }
  }
}

// レスポンシブ：年代
@media screen and (max-width: 1024px) {
  .mofmof-team__section.age {
    width: 100%;
    padding: 0 20px 50px;
  }

  .mofmof-team__section.age > .u-flex-center {
    display: block;
  }

  .u-w-50 {
    width: 100% !important;
  }

  .mofmof-team .age {
    .mofmof-team__title {
      margin-bottom: 30px;
    }

    &__list {
      padding: 0;
    }

    &__item {
      margin: 20px 0;

      img {
        margin: 0 auto;
        width: 300px;
      }

      p {
        font-size: $base-font-size;
        width: calc(100% - 65px);
      }
    }
  }
}

/* 私たちがお届けしたもの
====================================================*/
.mofmof-team .interview {
  width: 100%;

  .mofmof-team__title {
    margin-bottom: 60px;
  }

  &__slider {
    width: 100%;

    &-list {
      background: $neutral-white;
      border-radius: 10px;
      list-style: none;
      text-align: center;
      margin: 0 15px;
      width: 350px;
    }

    .img {
      height: 180px;
      object-fit: cover;
      border-radius: 10px 10px 0px 0px;
    }

    &-content {
      height: 150px;
      padding: 20px 30px;

      .name {
        color: $primary-color;
      }

      .discription {
        padding: 10px 0;
        font-size: rem(12);
        text-align: left;
        overflow: hidden;
      }
    }

    .read-more {
      display: block;
      max-width: 180px;
      margin: 20px auto;
      padding: 5px;
      border: solid 1px $primary-color;
      border-radius: 70px;
      box-shadow: $shadow-btn;
      color: $primary-color;

      &:hover {
        text-decoration: none;
        background-color: $primary-color-hover;
        color: $text-color-white;
        transition: 0.3s;
      }
    }

    .slick-next,
    .slick-prev {
      $size: 40px;
      background-color: $primary-color;
      border-radius: 50%;
      color: $text-color-white;
      height: $size;
      width: $size;
      box-shadow: $shadow-btn;
      &:hover {
        background-color: $primary-color-hover;
      }
    }

    .slick-next {
      right: 60px;
    }

    .slick-prev {
      left: 60px;
    }
  }
}

// レスポンシブ：私たちがお届けしたもの
@media screen and (max-width: 1024px) {
  .mofmof-team .interview {
    .mofmof-team__title {
      margin-bottom: 30px;
    }

    &__slider {
      .slick-next {
        right: 15px;
      }
      .slick-prev {
        left: 15px;
      }
    }
  }
}

/* 大切にしていること
====================================================*/
.mofmof-team .value {
  padding: 70px 20px;

  .mofmof-team__title {
    margin-bottom: 60px;
  }

  &__content {
    position: relative;
    overflow: hidden;
    max-width: 350px;
    background-color: $text-color-white;
    padding: 40px 30px;
    margin: 0 20px;
    border-radius: 40px 0px;

    h3 {
      line-height: 40px;
      font-size: rem(23);
      font-weight: 700;
      color: $primary-color;
    }

    .divider {
      width: 50px;
      margin: 20px 0;
      border: $primary-color 1px solid;
    }

    .text {
      font-size: rem(12);
    }

    .read-more {
      position: relative;
      z-index: 1;
      display: block;
      max-width: 180px;
      margin: 30px auto 10px;
      padding: 5px;
      background-color: $text-color-white;
      border: solid 1px $primary-color;
      border-radius: 70px;
      box-shadow: $shadow-btn;
      text-align: center;
      color: $primary-color;

      &:hover {
        text-decoration: none;
        background-color: $primary-color-hover;
        color: $text-color-white;
        transition: 0.3s;
      }
    }

    .illust-commit {
      position: absolute;
      z-index: 0;
      opacity: 0.4;
      width: 196px;
      bottom: -60px;
      right: -40px;
      transform: rotate(2.92deg);
    }

    .illust-pleasure {
      position: absolute;
      z-index: 0;
      opacity: 0.4;
      width: 231px;
      bottom: -80px;
      right: -40px;
      transform: rotate(2.37deg);
    }

    .illust-mvp {
      position: absolute;
      z-index: 0;
      opacity: 0.4;
      width: 290px;
      bottom: 10px;
      right: -120px;
    }
  }
}

// レスポンシブ：大切にしていること
@media screen and (max-width: 1024px) {
  .mofmof-team .value {
    padding: 70px 20px 0;

    .u-flex-center {
      display: block;
    }

    .mofmof-team__title {
      margin-bottom: 30px;
    }

    &__content {
      margin: 0 auto 30px;
    }
  }
}

/* メンバー
====================================================*/
.mofmof-team .members {
  padding-top: 0px;

  .mofmof-team__title {
    margin-bottom: 60px;
  }

  &__list {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .member-card {
      position: relative;
      width: 33%;
      padding: 15px 20px;
      box-sizing: border-box;
      margin-bottom: 20px;

      &__name {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          width: max-content;
          display: flex;
          justify-content: center;
          gap: 2px;
          .name_ja {
            font-size: $base-font-size;
            font-weight: 700;
          }
          .name_en {
            font-size: rem(12);
            font-weight: 700;
          }
        }
        .role {
          color: $primary-color;
          font-size: rem(12);
          font-weight: 700;
        }
      }

      &__txt {
        font-size: rem(12);
        margin-bottom: 15px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        line-height: 2em;
        height: calc(2em * 6);
      }

      .member-thumb {
        border-top: 2px solid $primary-color;
        height: 200px;
        margin-bottom: 15px;
        padding-top: 15px;
        position: relative;
        width: 300px;

        img {
          transition: 0.3s ease-in-out;
          position: absolute;
          top: 15px;
          left: 0;
        }

        .member-thumb__out {
          width: 100%;
          z-index: 1;
        }

        .member-thumb__over {
          z-index: 0;
          opacity: 0;
          visibility: hidden;
        }

        &:hover {
          .member-thumb__out {
            z-index: 0;
            opacity: 0;
            visibility: hidden;
          }

          .member-thumb__over {
            z-index: 1;
            opacity: 1;
            top: 15px;
            visibility: visible;
          }
        }
      }

      &__sns {
        a:hover {
          opacity: 0.7;
          transition: 0.3s;
        }

        img {
          width: 30px;
          height: 30px;
          display: inline-block;
          margin-right: 15px;
        }
      }

      .button.see-more {
        width: 70%;
        display: block;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        padding-left: 0; // .buttonについているスタイルの打ち消し
        padding-right: 0;
        border-radius: 99px;
      }
    }
  }
}

// レスポンシブ：メンバー
@media screen and (max-width: 1024px) {
  .mofmof-team .members {
    margin: 0px;

    &__list {
      .member-card {
        min-height: initial;
        height: fit-content;
        padding: 0 20px 40px;
        margin: 0px 5px;
        border-top: 2px solid $primary-color;
        background-color: $text-color-white;

        .member-thumb {
          width: 100%;
          height: fit-content;
          border-top: initial;

          img {
            width: 100%;
            transition: initial;
            position: initial;
            top: initial;
            left: initial;
          }

          &__over {
            display: none;
          }
        }

        &__name {
          border-top: none;
          padding-top: 0;

          & h5 {
            font-size: $small-font-size;
          }

          & span {
            font-size: rem(12);
          }
        }

        &__txt {
          font-size: rem(12);
          -webkit-line-clamp: 8;
          line-height: 2em;
          height: calc(2em * 8);
        }

        &__footer {
          height: 100px;
        }

        .button.see-more {
          width: 100%;
          box-sizing: border-box;
          position: inherit;
          bottom: inherit;
          left: inherit;
          transform: inherit;
          -webkit-transform: inherit;
          margin: 15px 0 20px;
        }
      }

      button.slick-next,
      button.slick-prev {
        $size: 40px;
        background-color: $primary-color;
        border-radius: 50%;
        color: $text-color-white;
        height: $size;
        width: $size;
        box-shadow: $shadow-btn;
      }

      .slick-next {
        right: 15px;
      }

      .slick-prev {
        left: 15px;
      }
    }
  }
}
