.development-team-rental {
  .main {
    .header {
      .sp-menu {
        display: none;
        @media screen and (max-width: $breakpoint-tablet) {
          display: block;
        }
        .sp-menu__check {
          display: none;
        }
        .sp-menu__box {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 66px;
          width: 32px;
          z-index: 20;
          caret-color: transparent;
          .menu-icon-white {
            display: block;
          }
          .menu-icon-primary {
            display: none;
          }
          .close-icon {
            display: none;
          }
        }
        .sp-menu__check:checked
          ~ .sp-menu__box
          .menu-icon {
          display: none;
        }
        .sp-menu__check:checked
          ~ .sp-menu__box
          .close-icon {
          display: block;
        }
        .sp-menu__check:checked
          ~ .sp-menu__content {
          left: 0;
        }
        .sp-menu__content {
          position: fixed;
          width: 100%;
          height: 100%;
          top: 0;
          left: 100%;
          z-index: 10;
          display: flex;
          flex-direction: column;
          background: rgba($neutral-solid-50, 0.95);
          backdrop-filter: blur(4px);
          .sp-header {
            display: flex;
            justify-content: start;
            align-items: center;
            height: 66px;
            padding: 0px 24px;
            gap: 16px;
            &::after {
              content: '';
              width: 32px;
              flex-shrink: 0;
            }
            .sp-header__logo {
              @extend %sp-header-logo-base;
              display: block;
            }
          }
          .sp-nav {
            padding: 32px 40px;
            .sp-nav__item {
              border-bottom: 1px solid $neutral-solid-200;
              list-style: none;
              cursor: pointer;
              .sp-nav__item--link {
                color: $mofmof-blue-600;
                display: flex;
                font-size: rem(14);
                font-weight: 700;
                justify-content: space-between;
                padding: 12px;
                position: relative;
                &::after {
                  background: url("/images/ico_chevron-right_primary.svg") no-repeat center;
                  content: '';
                  display: block;
                  height: 27px; // FIXME: マジックナンバー
                  width: 16px;
                }
              }
            }
            .sp-nav__item:first-child {
              border-top: 1px solid $neutral-solid-200;
            }
          }
          .buttons {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: end;
            column-gap: 32px;
            padding: 40px 0px;
            &.btn-download {
              &:hover {
                // NOTE: スマホ時のホバーアクション打ち消し
                color: $neutral-white;
                background: $mofmof-blue-600;
              }
            }
            &.btn-contact {
              &:hover {
                // NOTE: スマホ時のホバーアクション打ち消し
                color: $neutral-solid-700;
                background: $yellow-300;
              }
            }
          }
        }
      }
    }
  }
}
