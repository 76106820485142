.downloads {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .downloads_title-sub {
    margin: 0 auto;
    padding: 16px 24px 48px;
    text-align: center;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(14);
      max-width: 480px;
    }
  }

  .downloads-wrapper {
    background-color: $background-base-color;
    padding: 40px 0 104px 0;
    @media screen and (max-width: $breakpoint-xl) {
      padding: 40px 24px 104px;
    }
    @media screen and (max-width: $breakpoint-md) {
      padding-top: 24px;
    }
    .downloads-container {
      $container-size: 1200px;
      $container-side-padding: 24px;
      margin: 0 auto;
      width: calc(#{$container-size} - #{$container-side-padding} * 2);
      @media screen and (max-width: $breakpoint-xl) {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .nav-tabs {
        border-bottom: solid 1px $neutral-opacity-300;
        display: block;
        margin-bottom: 40px;
        width: 100%;
        .tabs {
          display: flex;
          @media screen and (max-width: $breakpoint-xl) {
            width: 100%;
          }
          .tab {
            .tab__link {
              align-items: center;
              color: $text-color-light;
              display: flex;
              font-weight: bold;
              height: 64px;
              justify-content: center;
              padding: 16px;
              width: 166px;
              &:hover {
                background: $neutral-opacity-50;
                text-decoration: none;
              }
              &.active {
                $border-width: 2px;
                border-bottom: solid $border-width $primary-color;
                color: $primary-color;
                padding-bottom: calc(16px - #{$border-width});
              }
              @media screen and (max-width: $breakpoint-tablet) {
                font-size: rem(14);
                height: 45px;
                padding: 12px;
                white-space: nowrap;
                width: auto;
                &.active {
                  $border-width: 2px;
                  padding-bottom: calc(12px - #{$border-width});
                }
              }
            }
          }
        }
      }
      .cards {
        $horizontal-gap: 24px;
        display: none;
        &.show {
          display: grid;
          gap: 48px $horizontal-gap;
          grid-template-columns: 1fr 1fr 1fr;
          margin: 0 auto;
          @media screen and (max-width: $breakpoint-xl) {
            grid-template-columns: 1fr 1fr;
            max-width: 964px;
          }
          @media screen and (max-width: $breakpoint-md) {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        .card {
          background-color: $neutral-white;
          border-radius: $border-radius-m;
          box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1),
            0px 4px 16px 3px rgba(0, 0, 0, 0.05);
          display: flex;
          flex-direction: column;
          height: 448px;
          overflow: hidden;
          width: 100%;
          @media screen and (max-width: $breakpoint-xl) {
            max-width: 400px;
            width: 100%;
          }
          @media screen and (max-width: $breakpoint-md) {
            width: 400px;
          }
          @media screen and (max-width: $breakpoint-tablet) {
            width: 100%;
          }
          .image {
            width: 100%;
            height: 200px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .contents {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;
            padding-bottom: 24px;
            .top-contents {
              padding: 16px;
              .tag {
                border-radius: $border-radius-s;
                display: inline-block;
                font-size: rem(12);
                font-weight: bold;
                line-height: 150%;
                margin-bottom: 12px;
                padding: 4px 8px;
                &.service-guide {
                  background-color: $mofmof-blue-100;
                  color: $mofmof-blue-900;
                }
                &.help-document {
                  background-color: $neutral-solid-100;
                  color: $text-color-light;
                }
              }
              .title {
                margin-bottom: 12px;
                font-weight: bold;
              }
              .description {
                font-size: rem(12);
                line-height: 150%;
                color: $text-color-light;
              }
            }
            .bottom-contents {
              padding: 0 24px;
              a {
                gap: 8px;
              }
            }
          }
        }
      }
    }
  }
}
