$sp-menu-side-padding: 24px;

.sp-menu-modal {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-sp-menu-overlay;
  visibility: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: $background-base-100;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  &.open {
    visibility: visible;
    opacity: 1;
    .sp-header-btn {
      background-image: url('/images/ico_menu_close_primary-color.svg');
      background-size: cover;
      height: 24px;
      width: 32px;
    }
  }
  display: none;
  @media screen and (max-width: $breakpoint-pc) {
    display: block;
  }
}

@media screen and (max-width: $breakpoint-pc) {
  .bgfix {
    //モーダル展開時に背景固定
    overflow: hidden;
  }
}

.sp-menu-modal__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 $sp-menu-side-padding;
  height: 64px;
}

.sp-menu-modal__items {
  margin: 32px 0 48px;
  width: 100%;
}
.sp-menu-modal__item {
}
%icon-setting {
  $icon_size: 22px;
  background-size: contain;
  content: '';
  display: block;
  height: $icon_size;
  width: $icon_size;
  position: absolute;
  top: calc(50% - #{$icon_size} * 0.5);
  transition: opacity 0.3s;
  right: $sp-menu-side-padding;
}
.sp-menu-modal__item-title {
  display: flex;
  justify-content: space-between;
  padding: 16px $sp-menu-side-padding;
  font-size: rem(14);
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: rem(0.56);
  color: $neutral-solid-700;
  align-items: center;
  border-bottom: 1px solid $neutral-opacity-100;
  position: relative;
  &.js_switch {
    //下位メニューがある→js_switchが同時に指定されている
    &::after {
      @extend %icon-setting;
      background-image: url('/images/ico_plus-circle.svg');
      opacity: 1;
    }
    &::before {
      @extend %icon-setting;
      background-image: url('/images/ico_minus-circle.svg');
      opacity: 0;
    }
    &.open {
      &::after {
        opacity: 0;
      }
      &::before {
        opacity: 1;
      }
    }
  }
}

.sp-menu-modal__accordion {
  display: none;
  padding: 24px 32px 32px;
  background-color: $background-base-200;
  .header-list-top-link {
    margin-bottom: 24px;
  }
}

.sp-menu-modal__buttons {
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  .header-button {
    width: 240px;
    padding: 12px 20px;
  }
}
.sp-menu-modal__link-lists {
  display: flex;
  gap: 32px 48px;
  flex-wrap: wrap;
}
.sp-menu-modal__link-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
}
