// MEMO: 問い合わせと採用応募フォームで利用されている
.contact {
  .main_visual {
    .main_img {
      background: url(/images/main_contact.jpg) no-repeat center center;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }

  &_container {
    @media screen and (max-width: 1024px) {
      max-width: 600px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @media screen and (min-width: 1024px) {
      #blog {
        h2.ttl_left--small {
          margin-bottom: 30px;
          padding-top: 0px;
          padding-left: 15%;
        }
      }
    }
  }

  &_section {
    @media screen and (max-width: 1024px) {
      float: none;
      width: 100%;
      padding-top: 0;
      padding-bottom: 60px;
      form {
        width: 80%;
        margin: 0 auto;
      }
    }

    @media screen and (min-width: 1024px) {
      .contact_form {
        padding-top: 70px;
        width: 65%;
        display: flex;
        justify-content: flex-end;

        form {
          width: 80%;
        }
      }
    }
  }

  &_wrap {
    position: relative;
    top: -60px;

    @media screen and (max-width: 1024px) {
      top: 0;
    }

    .contact_image {
      @media screen and (max-width: 1024px) {
        margin-bottom: 15px;
      }
    }

    .contact_notice {
      margin-bottom: 20px;
    }
  }

  &_text {
    float: left;
    width: 350px;
    font-size: $small-font-size;

    ul {
      margin: 20px;
    }

    @media screen and (max-width: 1024px) {
      float: none;
      width: 100%;
      font-size: rem(12);
      margin-bottom: 30px;
      ul {
        width: 220px;
        margin: 0 auto;
        margin-bottom: 10px;
      }
      p {
        text-align: center;
      }
    }
  }
}

body.contact {
  #footer_contact {
    display: none;
  }
}
