.client-profile {
  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  &_company {
    position: relative;
    margin-bottom: 20px;
    padding: 100px 75px 40px;
    background: $background-color-old;
    &_title {
      position: absolute;
      top: -15px;
      left: 0;
      right: 0;
      margin: auto;
    }
    @media screen and (max-width: 1024px) {
      padding: 80px 16px 40px;
      &_title {
        font-size: rem(34);
        span {
          margin-top: 10px;
          font-size: $small-font-size;
        }
      }
    }
  }

  &_table {
    font-size: $small-font-size;
    &_row {
      display: flex;
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &_title {
      width: 80px;
    }
    &_content {
      display: flex;
      width: calc(100% - 80px);
      word-break: break-word;
      span {
        display: inline-block;
        margin-right: 20px;
      }
      a {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }

  &_interviewee {
    background: $background-color-old;
    padding: 20px 75px 10px;
    &_wrap {
      display: flex;
      justify-content: space-between;
      padding: 30px 0;
      border-bottom: 1px dashed #888;
      &:last-of-type {
        border: none;
      }
    }
    &_image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      & + .client-profile_interviewee_content {
        width: calc(100% - 140px);
      }
    }
    &_name {
      font-size: rem(18);
    }
    &_position {
      margin-top: 10px;
      margin-bottom: 14px;
      color: #888;
      font-size: rem(12);
    }
    &_career {
      font-size: rem(12);
    }

    @media screen and (max-width: 1024px) {
      padding: 10px 20px;
      &_wrap {
        content: "";
        display: block;
        clear: both;
      }
      &_image {
        float: left;
        width: 100px !important;
        margin-right: 26px;
        & + .client-profile_interviewee_content {
          width: inherit;
        }
      }
      &_name {
        margin-top: 26px;
      }
      &_position {
        margin-top: 6px;
        margin-bottom: 34px;
        color: #888;
        font-size: rem(12);
      }
      &_career {
        font-size: rem(12);
      }
    }
  }
}
