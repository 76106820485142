

.mainvisual {
  margin-bottom: 50px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 30px;
  }
  .mainvisual__image {
    display: flex;
    margin-bottom: 30px;
    @media screen and (max-width: 1024px) {
      margin-bottom: 20px;
    }
    img {
      width: 100%;
    }
  }
}
.personal-info {
  margin-bottom: 30px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 20px;
  }
  .personal-info__name {
    font-size: rem(28);
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
      margin-bottom: 15px;
    }
    .name_ja {
      font-size: rem(28);
      @media screen and (max-width: 1024px) {
        font-size: rem(18);
      }
    }
    .name_en {
      font-size: rem(24);
      @media screen and (max-width: 1024px) {
        font-size: $small-font-size;
      }
    }
  }
  .personal-info__role {
    font-size: rem(20);
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
      font-size: $small-font-size;
      margin-bottom: 15px;
    }
  }

  .personal-info__sns {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    @media screen and (max-width: 1024px) {
      margin-bottom: 20px;
    }
    .personal-info__sns--item {
      list-style-type: none;

      &:not(:last-child){
        margin-right: 10px;
      }
      a:hover {
        opacity: 0.7;
        transition: 0.3s;
      }
      img {
        width: 30px;
        height: 30px;
        display: inline-block;
      }
    }
  }

  .personal-info__introduction {
    color: $primary-color;
    line-height: 200%;
    background-color: $background-color-old;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    @media screen and (max-width: 1024px) {
      margin: 0 20px;
      padding: 20px;
    }
    .personal-info__introduction--content {
      font-size: $base-font-size;
      margin-bottom: 20px;
      @media screen and (max-width: 1024px) {
        font-size: rem(12);
      }
    }
    .personal-info__interview {
      text-decoration: none;
      max-width: 400px;
      width: 100%;
      .personal-info__interview--button {
        padding: 20px 24px;
        display: flex;
        justify-content: space-between;
        border-width: 1px;
        @media screen and (max-width: 1024px) {
          padding: 18px 20px;
          font-size: $small-font-size;
          border-radius: 100vh;
          width: initial;
        }
      }
    }
  }
}
.profile {
  margin-bottom: 50px;
  @media screen and (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  .profile__title {
    font-size: rem(20);
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px dashed $primary-color;
    @media screen and (max-width: 1024px) {
      font-size: $base-font-size;
      margin-bottom: 15px;
    }
  }
  .profile__content {
    line-height: 200%;
    opacity: 0.5;
    @media screen and (max-width: 1024px) {
      line-height: 28px;
      font-size: rem(12);
      opacity: 1;
    }
  }
}
.qa {
  margin-bottom: 50px;
  @media screen and (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  .qa__title {
    font-size: rem(20);
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px dashed $primary-color;
    @media screen and (max-width: 1024px) {
      font-size: $base-font-size;
      margin-bottom: 20px;
    }
  }
  .qa__content {
    padding: 30px;
    border: 1px solid $primary-color;
    margin-bottom: 30px;
    @media screen and (max-width: 1024px) {
      padding: 20px;
      margin-bottom: 20px;
    }

    .qa__content--title {
      color: $primary-color;
      font-weight: 700;
      font-size: $base-font-size;
      margin-bottom: 15px;
      display: flex;
      gap: 20px;
      @media screen and (max-width: 1024px) {
        gap: 10px;
        margin-bottom: 10px;
      }
      .qa__content--title-q {
        font-size: rem(20);
        line-height: 1.2;
      }
      .qa__content--title-text {
        font-size: $base-font-size;
        @media screen and (max-width: 1024px) {
          font-size: $small-font-size;
        }
      }
    }
    .qa__content--answer {
      font-size: $small-font-size;
      line-height: 200%;
      margin-left: 43px;
      @media screen and (max-width: 1024px) {
        font-size: rem(12);
        opacity: 0.5;
        margin-left: 0px;
      }
    }
  }
}
.project {
  margin-bottom: 50px;
  @media screen and (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  .project__title {
    font-size: rem(20);
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px dashed $primary-color;
    @media screen and (max-width: 1024px) {
      font-size: $base-font-size;
      margin-bottom: 20px;
    }
  }
  .project__content {
    display: flex;
    align-items: center;
    gap: 30px;
    background-color: $background-color-old;
    border-radius: 10px;
    padding: 40px;
    margin-bottom: 30px;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      gap: 0px;
      padding: 20px;
    }
    .project__content--image {
      img {
        object-fit: cover;
        aspect-ratio: 6 / 5;
        width: 320px;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
    }
    .project__content--detail {
      .interview--title {
        font-size: $base-font-size;
        color: $primary-color;
        margin: 20px 0px;
        line-height: 150%;
        @media screen and (max-width: 1024px) {
          font-size: $small-font-size;
          margin: 15px 0px;
        }
      }
      .interview--border {
        color: $primary-color;
        background-color: $primary-color;
        width: 50px;
        height: 2px;
      }
      .interview--content {
        margin: 20px 0px 30px 0px;
        line-height: 200%;
        font-size: 0.75rem;
        @media screen and (max-width: 1024px) {
          margin: 20px 0px 20px 0px;
        }
      }
      .interview__read-more {
        display: block;
        width: 80px;
        line-height: 8px;
        border-radius: 70px;
        margin-bottom: 20px;
        margin-left:auto;
        margin-right: 0px;
        text-decoration: none;
        @media screen and (max-width: 1024px) {
          width: initial;
        }
      }
    }
  }
}
.personal-apps {
  margin-bottom: 50px;
  @media screen and (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  .personal-apps__title {
    font-size: rem(20);
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px dashed $primary-color;
    @media screen and (max-width: 1024px) {
      font-size: $base-font-size;
      margin-bottom: 20px;
    }
  }
  .personal-apps__content {
    display: flex;
    gap: 30px;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      gap: 20px;
    }
    .personal-app {
      width: 50%;
      border: 1px solid $primary-color;
      border-radius: 10px;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
      .personal-app__image {
        margin-bottom: 20px;
        img {
          object-fit: cover;
          aspect-ratio: 16 / 9;
          width: 100%;
          border-radius: 10px 10px 0px 0px;
        }
      }
      .personal-app__title {
        color: $primary-color;
        font-weight: 700;
        font-size: $base-font-size;
        text-align: center;
        margin-bottom: 15px;
        padding: 0px 25px;
      }
      .personal-app__content {
        font-size: $small-font-size;
        line-height: 2em;
        height: calc( 2em * 3 );
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        padding: 0px 25px;
        margin-bottom: 20px;
      }
      .personal-app__read-more {
        display: block;
        width: 30%;
        line-height: 8px;
        border-radius: 70px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        text-decoration: none;
        @media screen and (max-width: 1024px) {
          width: 60%;
        }
      }
    }
  }
}
.other-member-profile {
  position: relative;
  .container-wrapper {
    position: relative;
    background-color: rgba($primary-color, 0.05);
    border-top: 2px solid $primary-color;
    width: 100vw;
    height: 600px;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    @media screen and (max-width: 1024px) {
      height: calc(250px + 100vw);
      max-height: 900px;
    }
    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: $neutral-white url(/images/bg.png);
      z-index: -1;
    }
  }
  .container {
    position: absolute;
    top: 0px;
    .title {
      font-weight: 700;
      font-size: rem(25);
      color: $primary-color;
      text-align: center;
      margin-top: 100px;
      margin-bottom: 45px;
      @media screen and (max-width: 1024px) {
        font-size: rem(18);
        margin-top: 40px;
        margin-bottom: 30px;
        margin-left: 50px;
        margin-right: 50px;
      }
    }
    .slider-member {
      .slider-member-items {
        width: 90%;
        margin: 0 auto 60px;
        @media screen and (max-width: 1024px) {
          width: 100%;
          margin: 0 auto 40px;
        }
        .item {
          display: block;
          background: $neutral-white;
          border: 1px solid $primary-color;
          padding: 20px;
          margin: 0 15px;
          @media screen and (max-width: 1024px) {
            margin: 0 7px;
          }
          &:hover {
            opacity: 0.7;
            transition: 0.3s;
            text-decoration: none;
          }
          .item__content {
            display: flex;
            align-items: center;
            gap: 20px;
            .item__content--image {
              img {
                object-fit: cover;
                aspect-ratio: 5 / 4;
                width: 200px;
              }
            }
            .item__content--detail {
              .name-and-role {
                display: flex;
                justify-content: space-between;
                align-items: start;
                .name {
                  width: max-content;
                  .name_ja {
                    font-size: rem(18);
                    font-weight: 700;
                    margin-bottom: 5px;
                  }
                  .name_en {
                    font-size: $small-font-size;
                    font-weight: 700;
                    margin-bottom: 10px;
                  }
                }
                .role {
                  color: $primary-color;
                  font-size: $small-font-size;
                  font-weight: 700;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .introduction {
                font-size: $small-font-size;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
              }
            }
          }
          .item__content-sp {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .item__content--image {
              img {
                object-fit: cover;
                aspect-ratio: 5 / 4;
                width: 100%;
              }
            }

            .name-and-role {
              display: flex;
              flex-direction: column;
              align-items: center;
              .name {
                width: max-content;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                .name_ja {
                  font-size: $small-font-size;
                  font-weight: 700;
                  margin-bottom: 5px;
                }
                .name_en {
                  font-size: $small-font-size;
                  font-weight: 700;
                  margin-bottom: 10px;
                }
              }
              .role {
                color: $primary-color;
                font-size: $small-font-size;
                font-weight: 700;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .introduction {
              font-size: rem(12);
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
          }
          @media screen and (max-width: 1024px) {
            .item__content {
              display: none;
            }
          }
          @media screen and (min-width: 1025px) {
            .item__content-sp {
              display: none;
            }
          }
        }

        .slick-next,
        .slick-prev {
          $size: 40px;
          background-color: $primary-color;
          border-radius: 50%;
          color: $text-color-white;
          height: $size;
          width: $size;
          box-shadow: $shadow-btn;
          &:hover {
            background-color: $primary-color-hover;
          }
        }

        .slick-next {
          right: -50px;
          @media screen and (max-width: 1024px) {
            right: 15px;
          }
        }

        .slick-prev {
          left: -50px;
          @media screen and (max-width: 1024px) {
            left: 15px;
          }
        }
      }
    }
    .other-member-profile-link {
      text-decoration: none;
      display: flex;
      justify-content: center;
      .other-member-profile-link--button {
        padding: 20px 24px;
        display: flex;
        justify-content: space-between;
        width: 400px;
        border-width: 1px;
        @media screen and (max-width: 1024px) {
          padding: 18px 20px;
          font-size: $small-font-size;
          border-radius: 100vh;
          width: 100%;
          margin-left: 40px;
          margin-right: 40px;
        }
      }
    }
  }
}
