.footer {
  *, *::before, *::after {
    box-sizing: border-box;
  }
  background-color: $background-base-200;
  position: relative;
  overflow: hidden;
  background-image: url("/images/footer-bg-image-1.svg"),
                    url("/images/footer-bg-image-2.svg");
  background-position: bottom -400px left -200px,
                    top -360px right -200px;
  background-repeat: no-repeat;

  .category-heading {
    font-size: rem(16);
    font-weight: 700;
    line-height: 1.7;
    letter-spacing: 0.04em;
    margin-bottom: 4px;
    color: $neutral-solid-700;
  }

  .upper {
    .upper__container {
      padding-top: 40px;
      padding-bottom: 64px;
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  
    .footer-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: $breakpoint-md) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 40px;
      }
    }
    .footer-header__logo {
      height: 48px;
    }
    .footer-header__buttons {
      display: flex;
      align-items: center;
      gap: 24px;
      .btn {
        width: 180px;
      }
      @media screen and (max-width: $breakpoint-md) {
        gap: 16px;
      }
    }
    .footer-divider {
      border-top: 1px solid $neutral-opacity-100;
    }
    .footer-sitemap {
      column-count: 5;
      @media screen and (max-width: $breakpoint-xl) {
        column-count: 3;
      }
      @media screen and (max-width: $breakpoint-md) {
        column-count: 2;
      }
      @media screen and (max-width: $breakpoint-tablet) {
        column-count: 1;
      }
    }
    .category {
      break-inside:avoid;
      margin-bottom: 24px;
    }
    .category-items {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .category-items__link {
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 4px 0;
        font-size: rem(12);
        line-height: 1.5;
        letter-spacing: 0.04em;
        color: $neutral-solid-700;
        text-decoration: none;
        &::after {
          content: '';
          line-height: 1;
          width: 10px;
          height: 10px;
          background-color: $neutral-solid-700;
          clip-path: path(
            'M3.084 8.791a.5.5 0 0 1 0-.707l2.771-2.771L3.084 2.54a.5.5 0 1 1 .707-.707l3.125 3.125a.5.5 0 0 1 0 .707L3.791 8.791a.5.5 0 0 1-.707 0'
          ); // > アイコン
        }
        &:hover {
          color: $mofmof-blue-700;
          text-decoration: none;
          &::after {
            background-color: $mofmof-blue-700;
            animation: kf-icon-right-bounce 0.2s;
          }
        }
      }
    }
  }

  .lower {
    background-color: $neutral-opacity-30;
    .lower__container {
      padding-top: 40px;
      padding-bottom: 16px;
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  
    .lower__copyright {
      text-align: right;
      font-size: rem(12);
      line-height: 1.8;
      letter-spacing: 0.06em;
      color: $text-color-light;
      padding: 4px 0;
    }
    .seo-links {
      padding: 8px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 12px 20px;
      @media screen and (max-width: $breakpoint-tablet) {
        flex-direction: column;
      }
      .seo-links__item {
        font-size: rem(12);
        line-height: 1.5;
        letter-spacing: 0.06em;
        color: $neutral-solid-700;
        text-decoration: none;
        &:hover {
          color: $text-link-color;
          text-decoration: none;
        }
      }
    }
  }
}
