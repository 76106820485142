$header-height-pc: 64px;
$header-height-mobile: 64px;

.wrapper {
  position: relative;
  overflow-x: hidden;
  $top-bg-height: 400px;
  .top-bg {
    position: absolute;
    width: 100%;
    height: $top-bg-height;
    background: url(/images/bg.png);
    z-index: $z-index-bg;
    @media screen and (max-width: $breakpoint-pc) {
      height: 208px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      height: 192px;
    }
  }
  .page-content {
    padding-top: $header-height-pc + 16px;
    @media screen and (max-width: $breakpoint-pc) {
      padding-top: $header-height-mobile;
    }
  }
}

// NOTE: 背景画像が上部までピッタリ表示される例外ページ
.home {
  .page-content {
    padding-top: 0;
  }
}
.recruit {
  .page-content {
    padding-top: $header-height-pc;
    @media screen and (max-width: $breakpoint-pc) {
      padding-top: 0;
    }
  }
}
