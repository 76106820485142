
.js-tab-content {
  display: none;
  &.show {
    display: block;
  }
}

.d-flex {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.d- {
  $types: (block, inline-block, inline, flex, inline-flex, table, table-cell, table-row, grid, inline-grid, none);
  @each $type in $types {
    &#{$type} {
      display: $type !important;
    }
  }
}
// .d-none, .d-block etc...
// d-noneが最も優先される

.d- {
  $screen-sizes: (xxl, xl, lg, md, sm, xs);
  @each $size in $screen-sizes {
    $types: (block, inline-block, inline, flex, inline-flex, table, table-cell, table-row, grid, inline-grid, none);
    @each $type in $types {
      &#{$size}-#{$type} {
        @media screen and (max-width: map-get($grid-breakpoints, $size)) {
          display: $type !important;
          
        }
      }
    }
  }
}
// .d-md-none, .d-lg-block etc... （画面の大きさごとにdisplay要素の値を切り替える）



.u-flex-grow-1, .flex-grow-1 {
  flex-grow: 1;
}

// PC、スマホで表示切り替えをする時に使用 ====================
.only-sp, .sp-only {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .only-pc, .pc-only {
    display: none !important;
  }
  .only-sp, .sp-only {
    display: block !important;
  }
}
