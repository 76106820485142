.home-news {
  * {
    box-sizing: border-box;
  }
  background-color: $neutral-solid-20;
  position: relative;
  width: 100%;
  padding: 120px 0;
  @media screen and (max-width: $breakpoint-xxl) {
    padding-bottom: 200px;
  }
  @media screen and (max-width: $breakpoint-pc) {
    padding-bottom: 160px;
  }
  @media screen and (max-width: $breakpoint-tablet) {
    padding-bottom: 120px;
  }

  .home-news-container {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  .news-list-container {
    background-color: $background-base-100;
    padding: 40px;
    border-radius: $border-radius-m;
    box-shadow: 2px 2px 10px 0px rgba(211, 211, 211, 0.3);
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 32px;
    }
  }
  .home-news-buttons {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    > .btn {
      width: 100%;
      max-width: 200px;
    }
  }
  .bg-image-left-top-circle {
    position: absolute;
    top: -120px;
    left: -220px;
    width: 400px;
    @media screen and (max-width: $breakpoint-md) {
      width: 300px;
      top: -120px;
      left: -140px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 200px;
      top: -104px;
      left: -104px;
    }
  }
  .bg-image-left-top-dot {
    position: absolute;
    top: 172px;
    left: 96px;
    @media screen and (max-width: $breakpoint-xl) {
      width: 240px;
      top: 48px;
      left: -48px;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 200px;
      top: 48px;
      left: -48px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 144px;
      top: 36px;
      left: -56px;
    }
  }
  .bg-image-right-bottom-illust {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 280px;
    @media screen and (max-width: $breakpoint-pc) {
      width: 200px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 160px;
    }
  }
}
