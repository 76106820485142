// homeのworkセクション
.home-works {
  background-color: $neutral-solid-20;
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  .home-works__container {
    position: relative;
    z-index: 1;
  }
  .work-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 64px 56px;
    margin: 0 auto 80px;
    @media screen and (max-width: $breakpoint-xl) {
      gap: 64px 48px;
    }
    @media screen and (max-width: $breakpoint-pc) {
      grid-template-columns: repeat(2, 1fr);
      max-width: 880px;
    }
    @media screen and (max-width: $breakpoint-md) {
      grid-template-columns: 1fr;
      max-width: 560px;
    }
  }
  .work-card {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-decoration: none;
    transition: 0.3s;
    container-type: inline-size;
    &:hover {
      .work-card__image {
        scale: 1.05;
        opacity: 0.8;
      }
    }
  }
  .work-card__image-container {
    width: 100%;
    height: 200px;
    border-radius: $border-radius-m;
    overflow: hidden;
    margin-bottom: 24px;
    background-color: $neutral-white;
    @container (inline-size <= 340px) {
      height: 180px;
    }
  }
  .work-card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }
  .work-card__tag {
    margin-bottom: 16px;
  }
  .work-card__title {
    font-size: rem(20);
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.04em;
    margin-bottom: 8px;
  }
  .work-card__intro {
    color: $text-color;
    font-feature-settings: 'pwid' on;
    font-size: rem(14);
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.04em;
    margin-bottom: 16px;
  }
  .work-card__client-name {
    display: flex;
    gap: 8px;
    font-size: rem(14);
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: 0.04em;
    color: $neutral-solid-500;
    > i {
      font-size: rem(20);
      margin-top: rem(2);
    }
  }
  .home-works__btn-group {
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}
