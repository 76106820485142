// mofmof開発チーム紹介ページ
.team-feature {

  // 共通項目 ====================
  .main_visual {
    .main_img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }

  .section {
    padding: 100px 0;
  }

  &__container.ob{
    background-color: $background-color-old;
    padding-top: 80px;
  }

  &__container.banner {
    margin-bottom: 40px;
  }

  &__title {
    font-size: rem(45);
    letter-spacing: 3px;
    text-align: center;
    margin-bottom: 50px;
    color: $text-link-color;

    p {
      font-size: rem(20);
      margin-top: 20px;
    }
  }

  // メンバー紹介 ====================
  &__member {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .member-card {
      position: relative;
      width: 33%;
      padding: 15px 20px;
      box-sizing: border-box;
      margin-bottom: 20px;

      &__name {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          width: max-content;
          display: flex;
          justify-content: center;
          gap: 2px;
          .name_ja {
            font-size: $base-font-size;
            font-weight: 700;
          }
          .name_en {
            font-size: rem(12);
            font-weight: 700;
          }
        }
        .role {
          color: $primary-color;
          font-size: rem(12);
          font-weight: 700;
        }
      }

      &__txt {
        font-size: rem(12);
        margin-bottom: 15px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        line-height: 2em;
        height: calc( 2em * 6 );
      }

      .member-thumb {
        border-top: 2px solid $primary-color;
        display: flex;
        height: 200px;
        justify-content: center;
        margin-bottom: 15px;
        overflow: hidden;
        padding-top: 15px;
        position: relative;
        width: 300px;

        img {
          transition: .3s ease-in-out;
          position: absolute;
          top: 15px;
        }

        .member-thumb__out {
          width: 100%;
          z-index: 1;
        }

        &.big {
          .member-thumb__out {
            width: 130%;
          }
        }
        .member-thumb__over {
          z-index: 0;
          opacity: 0;
          visibility: hidden;
        }

        &:hover {
          .member-thumb__out {
            z-index: 0;
            opacity: 0;
            visibility: hidden;
          }

          .member-thumb__over {
            z-index: 1;
            opacity: 1;
            top: 15px;
            visibility: visible;
          }
        }
      }

      &__sns {
        a:hover {
          opacity: 0.7;
          transition: 0.3s;
        }

        img {
          width: 30px;
          height: 30px;
          display: inline-block;
          margin-right: 15px;
        }
      }

      .button.see-more {
        width: 70%;
        display: block;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        padding-left: 0; // .buttonについているスタイルの打ち消し
        padding-right: 0;
        border-radius: 99px;
      }
    }
  }
}

// スマホのスタイル ====================
@media screen and (max-width: 1024px) {
  .team-feature {

    .section {
      padding: 60px 0;
    }

    &__title {
      font-size: rem(30);
      margin-bottom: 40px;

      p {
        font-size: $base-font-size;
        margin-top: 10px;
      }
    }

    // メンバー紹介 ====================
    &__container.ob {
      padding-top: 60px;
      padding-bottom: 60px;
      .team-feature__member {
        margin: 0px;
        .member-card {
          min-height: initial;
          height: fit-content;
          padding: 0 20px;
          margin: 0px 5px;
          border-top: 2px solid $primary-color;
          .member-thumb {
            border-top: initial;
          }
          &__name {
            & h5 {
              font-size: $small-font-size;
            }
            & span {
              font-size: rem(12);
            }
          }
          &__txt {
            font-size: rem(12);
            -webkit-line-clamp: 8;
            line-height: 2em;
            height: calc( 2em * 8 );
          }
        }
      }
    }

    &__member {
      max-width: 1000px;
      display: block;
      margin-left: 20px;
      margin-right: 20px;
      .member-card {
        width: 100%;
        margin-bottom: 40px;
        padding: 0px;
        background-color: $text-color-white;

        .member-thumb {
          width: 100%;
          height: fit-content;
          img {
            width: 100%;
            transition: initial;
            position: initial;
            top: initial;
            left: initial;
          }

          &__over {
            display: none;
          }
        }

        &__name {
          border-top: none;
          padding-top: 0;
        }
        &__txt {
          font-size: rem(12);
          -webkit-line-clamp: 8;
          line-height: 2em;
          height: initial;
        }

        .button.see-more {
          width: 100%;
          box-sizing: border-box;
          position: inherit;
          bottom: inherit;
          left: inherit;
          transform: inherit;
          -webkit-transform: inherit;
          margin: 15px 0 20px;
        }
      }
      button.slick-next,
      button.slick-prev {
        $size: 40px;
        background-color: $primary-color;
        border-radius: 50%;
        color: $text-color-white;
        height: $size;
        width: $size;
        box-shadow: $shadow-btn;
      }
      .slick-next {
        right: 15px;
      }
      .slick-prev {
        left: 15px;
      }
    }
  }
}
