.section_service {
  position: relative;
  padding-bottom: 130px;
  margin-bottom: 0;
  @media screen and (max-width: 1024px) {
    padding-top: 30px;
    padding-bottom: 0;
    margin-bottom: 0;
    .text_area {
      padding: 0 30px;
      width: auto;

      p {
        padding: 0;
      }
    }
  }

  .ttl {
    font-size: rem(32);
  }

  .ttl_development_process {
    padding-top: 80px;
    font-size: rem(66);
    line-height: 50px;
    @media screen and (max-width: 1024px) {
      padding-top: 30px;
      font-size: rem(33);
      &:after {
        display: none;
      }
    }
  }
}

.section_service.bg_right {
  background: url(/images/bg.svg) repeat-y right;
  background-size: 1100px;
}
