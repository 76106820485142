.content_info {
  width: 620px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }

  dl {
    background-image: linear-gradient(to right, $primary-color 55%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
  }

  dl dt {
    float: left;
    width: 8em;
    background-image: linear-gradient(to right, $primary-color 55%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    padding: 15px 0 15px 7em;
    padding-top: 17px;
    padding-left: 100px;
    padding-bottom: 0;
    padding-right: 0;
    @media screen and (max-width: 1024px) {
      width: 6rem;
      padding-left: 0;
      padding: 15px 0 15px 0.8em;
    }
  }

  dl dd {
    margin-left: 10em;
    padding: 10px;
    background-image: linear-gradient(to right, $primary-color 55%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    padding: 17px 0 15px 5.5em;
    @media screen and (max-width: 1024px) {
      padding: 17px 0 15px 0;
      margin-left: 7.4em;
    }
  }
}
