.service-difference {
  &_section {
    position: relative;
    margin-bottom: 120px;

    @media screen and (max-width: 1024px) {
      position: static;
      margin-top: 60px;
    }

    &--background {
      width: 62%;
      height: 93%;
      position: absolute;
      background: $neutral-white url(/images/bg_yellow.png);
      z-index: -1;
      top: 10px;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &_background-text {
    position: absolute;
    z-index: -1;
    height: 100%;
    text-align: center;
    font-weight: bold;
    font-size: rem(160);
    opacity: 0.1;

    &--general {
      @extend .service-difference_background-text;
      writing-mode: vertical-rl;
    }

    &--mofmof {
      @extend .service-difference_background-text;
      right: 0;
      color: $primary-color;
      writing-mode: vertical-rl;
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &_attention {
    position: absolute;
    top: 50px;
    z-index: 2;

    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: $text-link-color;

    text-align: center;
    color: white;

    @media screen and (max-width: 1024px) {
      top: -70px;
      width: 80px;
      height: 80px;
      padding: 15px;
    }
  }

  &_attention-content {
    width: 100%;
    height: 100%;
    padding: 20px 0;

    @media screen and (max-width: 1024px) {
      padding: 0;
    }
  }

  &_attention-headline {
    font-size: rem(32);
    font-weight: normal;
    margin-bottom: 5px;
    line-height: 1.4;

    @media screen and (max-width: 1024px) {
      font-size: rem(20);
      font-weight: bold;
      margin-bottom: 0;
      line-height: 1.5;
    }

    &--subtext {
      @extend .service-difference_attention-headline;
      font-size: $small-font-size;

      @media screen and (max-width: 1024px) {
        font-size: rem(6);
        font-weight: bold;
        font-family: Lato;
      }
    }
  }

  &_headline {
    text-align: center;
  }

  &_container {
    position: relative;
    width: 1300px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      position: static;
      width: 100%;
      margin-top: 70px;
    }
  }

  &_cards {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @media screen and (max-width: 1024px) {
      position: relative;
    }
  }

  &_card {
    width: 420px;
    margin: 30px;
    padding: 70px;
    filter: drop-shadow(2.3px 1.9px 3px lightgray);

    &--general {
      @extend .service-difference_card;
      background: linear-gradient(to top, #EFEFEF, $neutral-white);
    }

    &--mofmof {
      @extend .service-difference_card;
      background: linear-gradient(to top, #E8FDFF, $neutral-white);
      border: solid 2px $text-link-color;
    }

    @media screen and (max-width: 1024px) {
      width: 43.75%;
      margin: 0;
      padding: 0;
    }
  }

  &_card-item {
    margin-top: 100px;
    position: relative;

    &:after {
      position: absolute;
      display: block;
      top: -90px;
      width: 100%;
      text-align: center;
      font-size: rem(24);
      content: "･･･";
      color: $primary-color;
    }

    @media screen and (max-width: 1024px) {
      font-size: rem(12);
      margin-top: 120px;

      &:first-child {
        height: 160px;
      }
      &:nth-child(2) {
        height: 140px;
      }
      &:nth-child(3) {
        height: 190px;
      }
      &:nth-child(4) {
        height: 90px;
      }
      &:last-child {
        margin-bottom: 30px;
      }
    }

    &--specification {
      @extend .service-difference_card-item;

      &:before {
        position: absolute;
        display: block;
        left: -210px;
        width: 190px;
        height: 50px;
        background-color: $text-link-color;
        text-align: center;
        line-height: 50px;
        color: white;
        content: "仕様変更";

        @media screen and (max-width: 1024px) {
          left: -100px;
          top: -60px;
          height: 32px;
          width: 160px;
          line-height: 32px;
          font-size: rem(13);
          font-weight: bold;
        }
      }
    }

    &--cost {
      @extend .service-difference_card-item;

      &:before {
        position: absolute;
        display: block;
        left: -210px;
        width: 190px;
        height: 50px;
        background-color: $text-link-color;
        text-align: center;
        line-height: 50px;
        color: white;
        content: "費用";

        @media screen and (max-width: 1024px) {
          left: -100px;
          top: -60px;
          height: 32px;
          width: 160px;
          line-height: 32px;
          font-size: rem(13);
          font-weight: bold;
        }
      }
    }

    &--aptitude {
      @extend .service-difference_card-item;

      &:before {
        position: absolute;
        display: block;
        left: -210px;
        width: 190px;
        height: 50px;
        background-color: $text-link-color;
        text-align: center;
        line-height: 50px;
        color: white;
        content: "向いている\Aプロジェクト";
        white-space: pre;
        height: auto;
        line-height: 1.7;
        padding: 15px 0;

        @media screen and (max-width: 1024px) {
          left: -100px;
          top: -70px;
          height: 32px;
          width: 160px;
          line-height: 1.4;
          font-size: rem(13);
          font-weight: bold;
        }
      }
    }

    &--recommended {
      @extend .service-difference_card-item;

      &:before {
        position: absolute;
        display: block;
        left: -210px;
        width: 190px;
        height: 50px;
        background-color: $text-link-color;
        text-align: center;
        line-height: 50px;
        color: white;
        content: "こんな方に\Aオススメ";
        white-space: pre;
        height: auto;
        line-height: 1.7;
        padding: 15px 0;

        @media screen and (max-width: 1024px) {
          left: -100px;
          top: -70px;
          height: 32px;
          width: 160px;
          line-height: 1.4;
          font-size: rem(13);
          font-weight: bold;
        }
      }
    }

    &--contract {
      @extend .service-difference_card-item;

      &:before {
        position: absolute;
        display: block;
        left: -210px;
        width: 190px;
        height: 50px;
        background-color: $text-link-color;
        text-align: center;
        line-height: 50px;
        color: white;
        content: "契約形態";

        @media screen and (max-width: 1024px) {
          left: -100px;
          top: -60px;
          height: 32px;
          width: 160px;
          line-height: 32px;
          font-size: rem(13);
          font-weight: bold;
        }
      }
    }
  }

  &_card-headline {
    color: $primary-color;
    font-size: rem(44);
    line-height: 1;
    text-align: center;

    &--general {
      @extend .service-difference_card-headline;
      color: #252525;
    }

    @media screen and (max-width: 1024px) {
      font-size: $base-font-size;
      margin-top: 45px;
    }

    &--subtext {
      font-size: $small-font-size;
      display: block;
      margin-bottom: 15px;

      @media screen and (max-width: 1024px) {
        font-size: rem(10);
      }
    }
  }

  &_list {
    list-style: none;

    @media screen and (max-width: 1024px) {
      width: 85%;
      margin: 0 auto;
    }
  }
}
