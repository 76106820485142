.home-banner {
  padding-top: 80px;
  padding-bottom: 80px;
  @media screen and (max-width: $breakpoint-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .home-banner__container {
    border-radius: $border-radius-l;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media screen and (max-width: $breakpoint-pc) {
      grid-template-columns: 1fr;
    }
  }
  .home-banner__inner {
    padding: 48px 40px;
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 5;
    color: $neutral-white;
    height: 400px;
    position: relative;
    background-color: #00131580;
    overflow: hidden;
    text-decoration: none;
    transition-duration: 500ms;
    transition-property: background-color;
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 24px 16px;
    }

    &:hover {
      background: #0013154c;
      .home-banner__bg {
        transform: scale(1.1);
      }
      .btn-banner {
        color: $neutral-black;
        &::after {
          transform: scale(1, 1);
        }
      }
    }
  }

  .home-banner__inner--left {
    @extend .home-banner__inner;
  }
  .home-banner__inner--right {
    @extend .home-banner__inner;
    border-left: 1px solid $neutral-white;
    @media screen and (max-width: $breakpoint-pc) {
      border-left: none;
      border-top: 1px solid $neutral-white;
    }
  }
  .home-banner__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-index-bg;
    transition-duration: 500ms;
    object-fit: cover;
  }
  .title-en {
    font-family: $title-font-family;
    font-size: rem(24);
    font-weight: 900;
    line-height: 1;
    letter-spacing: 0.04em;
    margin-bottom: 40px;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    @media screen and (max-width: $breakpoint-pc) {
      font-size: rem(18);
      margin-bottom: 32px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(12);
      margin-bottom: 24px;
    }
  }
  .title-ja {
    font-size: rem(40);
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.04em;
    margin-bottom: 32px;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    @media screen and (max-width: $breakpoint-pc) {
      font-size: rem(32);
      margin-bottom: 20px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(24);
      margin-bottom: 16px;
    }
  }
  .heading {
    font-size: rem(20);
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.04em;
    margin-bottom: 8px;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    @media screen and (max-width: $breakpoint-pc) {
      font-size: rem(16);
    }
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(14);
      margin-bottom: 4px;
    }
  }
  .text {
    font-size: rem(16);
    line-height: 1.5;
    letter-spacing: 0.04em;
    margin-bottom: 32px;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    @media screen and (max-width: $breakpoint-pc) {
      font-size: rem(14);
      margin-bottom: 20px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(12);
      margin-bottom: 16px;
    }
  }
  .btn-group {
    display: flex;
    gap: 40px;
    @media screen and (max-width: $breakpoint-tablet) {
      gap: 24px;
    }
  }
  .btn-banner {
    border-width: 1px;
    border-style: solid;
    font-weight: 500;
    background: transparent;
    border-color: $neutral-white;
    color: $neutral-white;
    overflow: hidden;
    position: relative;
    z-index: 1;
    &::after {
      background: $neutral-white;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      transform: scale(0, 1);
      transform-origin: left top;
      transition: transform 0.2s cubic-bezier(0.45, 0, 0.55, 1);
      z-index: -1;
    }
    &:hover {
      &::after {
        background: $neutral-solid-100;
      }
    }
    @media screen and (max-width: $breakpoint-tablet) {
      flex: 1;
    }
  }
}
