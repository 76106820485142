.information {
  &_img {
    overflow: hidden;
    width: 100%;
    height: 500px;
    position: relative;

    @media screen and (max-width: 1024px) {
      height: auto;
      min-height: 250px;
    }

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}
