.interviews {
  * {
    box-sizing: border-box;
  }
  
  .cta-block {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 0 24px 20px;
    @media screen and (max-width: $breakpoint-tablet) {
      gap: 12px;
      padding: 16px 24px 32px;
    }
    .btn {
      max-width: 252px;
      flex: 1 auto;

    }
  }

  .interview-cards {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 64px 40px;
    padding: 16px 24px 24px;
    @media screen and (max-width: $breakpoint-md) {
      grid-template-columns: 1fr;
      gap: 48px;
    }
  }
}