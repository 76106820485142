.news-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.news-list__item {
  display: flex;
  gap: 40px;
  padding-bottom: 20px;
  border-bottom: solid 1px $neutral-opacity-100;
  @media screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
    gap: 4px;
  }
}
.news-list__date {
  width: 100px;
  flex-shrink: 0;
}