.experienced-fields {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 32px;
  max-width: $breakpoint-pc;
  margin: 0 auto;
  @media screen and (max-width: $breakpoint-tablet) {
    column-gap: 24px;
  }
  .field {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    width: 148px;
    .field__image--container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 145px;
      height: 145px;
      border-radius: 50%;
      background-color: $neutral-white;
      box-shadow: $shadow-card;
      .field__image {
        width: 100px;
        height: 100px;
      }
      @media screen and (max-width: $breakpoint-tablet) {
        width: 120px;
        height: 120px;
        .field__image {
          width: 84px;
          height: 84px;
        }
      }
    }
    .field__title {
      font-weight: 700;
      line-height: 175%;
      letter-spacing: 0.02rem;
      color: $neutral-solid-700;
    }
  }
}
