.floating-banner {
  display: none;
  position: fixed;
  margin: 36px;
  z-index: $z-index-floating-button;
  bottom: 0;
  right: 0;
  @media screen and (max-width: $breakpoint-pc) {
    display: none !important; // jsでつけ外しされるdisplay: block;を上回るように important付与
  }

  .floating-banner__close-button {
    background-color: transparent;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    border: none;
    cursor: pointer;
    z-index: inherit;
    border-radius: $border-radius-s;
    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background-color: $neutral-solid-400;
      clip-path: path('M10 10.88 5.77 15.1a.6.6 0 0 1-.43.17.59.59 0 0 1-.45-.17.6.6 0 0 1-.18-.44.6.6 0 0 1 .18-.44L9.12 10 4.9 5.77a.6.6 0 0 1-.17-.43.6.6 0 0 1 .62-.62.6.6 0 0 1 .43.17L10 9.12l4.23-4.23a.6.6 0 0 1 .43-.17.6.6 0 0 1 .62.62.6.6 0 0 1-.17.43L10.88 10l4.23 4.23a.6.6 0 0 1 .17.43.6.6 0 0 1-.62.62.6.6 0 0 1-.43-.17L10 10.88Z');
    }
    &:hover {
      background-color: $neutral-opacity-50;
      &::after {
        background-color: $primary-color;
      }
    }

  }

  .floating-banner__back-img {
    width: 300px;
    transition: transform 0.3s ease;
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05),
    0px 10px 30px 6px rgba(0, 0, 0, 0.02);
    &:hover {
      transform:scale(1.02); 
    }
  }
}