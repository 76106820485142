// NOTE: SP版のヘッダーのロゴの、ページごとに色を変える設定。
// $darker-pages　にセレクタを追加すると変更できる
$darker-pages: '.recruit';
#{$darker-pages} {
  .header {
    .sp-header-wrapper {
      .sp-header:not(.bg-colored) {
        .sp-menu-logo.green {
          display: none;
        }
        .sp-menu-logo.white {
          display: block;
        }
        .sp-header-btn {
          background-image: url('/images/ico_menu_white.svg');
          background-size: cover;
          height: 24px;
          width: 32px;
          cursor: pointer;
        }
      }
    }
  }
}

.sp-menu-logo.white {
  display: none;
}
// NOTE: END: SP版のヘッダーのロゴの、ページごとに色を変える設定。

// NOTE: sp版でメニューを開いていない時に見えるロゴ ------
.sp-header-wrapper {
  display: none;
  position: relative;
  @media screen and (max-width: $breakpoint-pc) {
    display: block;
  }
  .sp-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    position: absolute; // FIXME: トップの背景画像との兼ね合いで、positionをabsoluteにしないといけないが、重ね順が複雑になるので直したい。
    top: 0;
    height: 64px;
    width: 100%;
    z-index: $z-index-header;
    transition: .3s;
    &.bg-colored {
      background-color: rgba(255, 255, 255, 0.95);
    }

    .sp-header-btn {
      background-image: url('/images/ico_menu_primary-color.svg');
      background-size: cover;
      height: 24px;
      width: 32px;
    }
  }
}
// NOTE: END: sp版でメニューを開いていない時に見えるロゴ ------

// header全体の設定 -------------------------------

.header.global-nav {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index-header;
}
.sp-menu-logo {
  width: 144px;
}

// アニメーションキーフレーム
@keyframes kf-icon-right-bounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes kf-icon-down-bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
// 共通要素
.header-button {
  font-size: rem(14);
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: rem(0.56);
  text-align: center;
  padding: 8px 16px;
  color: $text-color-white;
  border-radius: $border-radius-s;
  background-color: $primary-color;
  border: none;
  &:hover {
    background-color: $mofmof-blue-800;
    color: $text-color-white;
  }
  &.header-button--secondary {
    background-color: $mofmof-blue-100;
    color: $mofmof-blue-900;
    &:hover {
      background-color: $mofmof-blue-200;
      color: $mofmof-blue-900;
    }
  }
}
.header-list-top-link {
  font-size: rem(16);
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: rem(0.64);
  color: $neutral-solid-800;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 8px;
  border-radius: $border-radius-s;
  &:hover {
    background-color: $neutral-opacity-30;
    &::after {
      animation: kf-icon-right-bounce 0.2s;
    }
  }
  &::after {
    content: url('/images/ico-caret-circle-right.svg');
    height: 20px;
    width: 20px;
    margin-top: 1px;
  }
}
.header-list-title {
  display: flex;
  align-items: center;
  font-size: rem(12);
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: rem(0.48);
  color: $mofmof-blue-700;
  gap: 12px;
  padding: 0 8px;
  &::after {
    content: '';
    height: 1px;
    background-color: $mofmof-blue-600;
    flex-grow: 1;
  }
}
.header-list-item {
  a {
    display: block;
    padding: 4px 8px;
  }
  border-radius: $border-radius-s;
  &:hover {
    background-color: $neutral-opacity-30;
    .header-list-item__title {
      color: $mofmof-blue-800;
      &::after {
        background-color: $mofmof-blue-800;
        animation: kf-icon-right-bounce 0.2s;
      }
    }
  }
}
.header-list-item__title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: rem(16);
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: rem(0.64);
  &::after {
    content: '';
    line-height: 1;
    width: 10px;
    height: 10px;
    background-color: $text-color;
    clip-path: path(
      'M2.907 8.968a.75.75 0 0 1 0-1.061l2.595-2.595-2.595-2.595a.75.75 0 0 1 1.061-1.061l3.125 3.125a.75.75 0 0 1 0 1.061L3.968 8.968a.75.75 0 0 1-1.061 0z'
    ); // > アイコン
  }
}
.header-list-item__desc {
  font-size: rem(12);
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: rem(0.24);
  color: $neutral-solid-700;
}

// header全体の設定: ここまで ------------------------

// pcヘッダー上部 -------------------
// NOTE: PC版のヘッダーデフォルト状態で背景が透明になる設定。
// 透明にしたいページのクラスを追加する
$pc-transparent-pages: '.home';
#{$pc-transparent-pages} {
  .pc-header {
    background-color: transparent;
    box-shadow: none;
    transition: all 0.3s;
    &.hovered, &.scrolled {
      background-color: $background-base-100;
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05),
      0px 10px 30px 6px rgba(0, 0, 0, 0.02);
    }
  }
}
.pc-header {
  background-color: $background-base-100;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 64px;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05),
    0px 10px 30px 6px rgba(0, 0, 0, 0.02);
  @media screen and (max-width: $breakpoint-pc) {
    display: none;
  }
}
.pc-gnavi {
  max-width: $breakpoint-xl;
  width: 100%;
  display: flex;
  padding: 0 16px;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.pc-gnavi__logo {
  width: 192px;
}
.pc-gnavi__right-block {
  display: flex;
  gap: 24px;
  height: 100%;
}
.pc-gnavi__list {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 100%;
  padding-top: 16px;
}
.pc-gnavi__buttons {
  display: flex;
  gap: 12px;
  align-items: center;
}
.pc-gnavi__list-item {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  padding: 0 4px 16px;
  &.pc-gnavi__list-item--link {
    padding: 0;
    a {
      padding: 0 4px 16px;
    }
  }
}
.pc-gnavi__list-item-inner {
  font: inherit;
  color: $neutral-solid-700;
  gap: 2px;
  align-items: center;
  display: flex;
  font-weight: 700;
  font-size: rem(14);
  justify-content: center;
  line-height: 1.5;
  padding: 4px 0;
  position: relative;
  transition: color 0.3s;
  border: none;
  background: none;
  &::before {
    background-color: $mofmof-blue-700;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    border-radius: 1px;
    left: 0;
    position: absolute;
    transition: 0.5s all;
    width: 0;
  }
  // 親に.pc-mega-menuがあったら ∨アイコンをつける
  .pc-mega-menu & {
    &::after {
      content: '';
      line-height: 1;
      transition: content 0.3s;
      width: 14px;
      height: 14px;
      background-color: $text-color;
      clip-path: path(
        'M3.345 5.22a.75.75 0 0 1 1.061 0L7 7.814 9.595 5.22a.75.75 0 0 1 1.061 1.061L7.53 9.405a.75.75 0 0 1-1.061 0L3.345 6.28a.75.75 0 0 1 0-1.061z'
      ); // ∨ アイコン
    }
  }
}
.pc-mega-menu:hover .pc-mega-menu__wrapper {
  -webkit-clip-path: inset(0 -50vw -100px);
  clip-path: inset(0 -50vw -100px);
  visibility: visible;
}
.pc-mega-menu:hover .pc-gnavi__list-item-inner::after {
  animation: kf-icon-down-bounce 0.2s;
  background-color: $mofmof-blue-700;
}
.pc-gnavi__list-item:hover .pc-gnavi__list-item-inner {
  &::before {
    width: 100%;
  }
  color: $mofmof-blue-700;
}

// メガメニュー内 ---------------------
.pc-mega-menu__wrapper {
  -webkit-clip-path: inset(0 -50vw 100%);
  background-color: $background-base-200;
  clip-path: inset(0 -50vw 100%);
  left: 0;
  position: absolute;
  top: 100%;
  transition: all 0.4s;
  visibility: hidden;
  width: 100%;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05),
    0px 10px 30px 6px rgba(0, 0, 0, 0.02);
}
.pc-mega-menu__container {
  max-width: $breakpoint-xl;
  width: 100%;
  padding: 40px 48px;
  display: flex;
  gap: 48px;
  margin: 0 auto;
}
.pc-mega-menu__left-box {
  width: 196px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 48px;
  border-right: solid 1px $neutral-opacity-200;
}

.pc-mega-menu__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}


.header-simple {
  height: 72px;
  display: flex;
  align-items: center;
  .header-simple__logo {
    height: 32px;
  }
}
