/// $pixelsの欄に入れたピクセル相当のremが出力されます。
///
/// @param {number (unitless)} $pixels
///   remに変換したいフォントのpxでのサイズです
/// @return {number (rem)} 
/// $pixelをremに変換した値が返されます。例： // rem(12) => 0.75rem

$baseFontSize: 16;
@function rem($pixels) {
  @return $pixels / $baseFontSize * 1rem;
}
