.service-merit {
  &_section {
    margin-bottom: 120px;

    @media screen and (max-width: 1024px) {
      position: static;
      width: 88.75%;
      margin: 60px auto 0;
    }
  }

  &_container {
    width: 1150px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &_items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    @media screen and (max-width: 1024px) {
      display: block;
      position: static;
    }

    &--background {
      width: 100%;
      height: 160px;
      position: absolute;
      background: $neutral-white url(/images/bg.png);
      z-index: -1;
      top: 80px;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &_item {
    @media screen and (max-width: 1024px) {
      margin-bottom: 15px;
    }
  }

  &_item:nth-child(3) {
    margin-top: 100px;

    @media screen and (max-width: 1024px) {
      margin-top: 0;
    }
  }

  &_item-image {
    width: 320px;
    height: 320px;
    object-fit: cover;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: auto;
    }
  }

  &_description {
    margin: 0 auto 20px auto;
    text-align: center;

    @media screen and (max-width: 1024px) {
      font-size: rem(15);
      font-weight: bold;
    }
  }

  &_more-button {
    display: flex;
    justify-content: center;
  }
}
