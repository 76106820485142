.development-team-rental.scaleup {
  .main .keyvis .copy .copy__line {
    @media screen and (max-width: $breakpoint-tablet) {
      letter-spacing: 2px;
    }
  }
  .case {
    background: $neutral-solid-50;
    margin: 0;
    overflow: hidden;
    padding: 64px 32px 112px;
    position: relative;
    @include section-splitter-setting("neutral-solid-30");
    @media screen and (max-width: $breakpoint-pc) {
      padding: 56px 24px 92px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 48px 16px 72px;
    }
    .cases {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      column-gap: 32px;
      .cases__list {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        @media screen and (max-width: $breakpoint-pc) {
          max-width: 640px;
          width: 100%;
        }
        .cases__list--item {
          background: $neutral-solid-30;
          border-radius: $border-radius-m;
          box-shadow: $shadow-card;
          display: flex;
          flex-direction: column;
          padding: 24px 32px 28px;
          row-gap: 24px;
          @media screen and (max-width: $breakpoint-pc) {
            padding: 24px;
            row-gap: 60px;
          }
          @media screen and (max-width: $breakpoint-tablet) {
            padding: 16px;
            row-gap: 48px;
          }
          .case-heading {
            display: flex;
            column-gap: 24px;
            align-items: center;
            @media screen and (max-width: $breakpoint-pc) {
              flex-direction: column;
              align-items: start;
              row-gap: 12px;
            }
            @media screen and (max-width: $breakpoint-tablet) {
              row-gap: 0px;
            }
            .index {
              font-family: 'Barlow';
              font-size: rem(24);
              font-weight: 700;
              color: $mofmof-blue-600;
              border-bottom: 2px solid $mofmof-blue-600;
              @media screen and (max-width: $breakpoint-pc) {
                font-size: rem(20);
              }
              @media screen and (max-width: $breakpoint-tablet) {
                font-size: rem(16);
              }
            }
            .text {
              font-size: rem(24);
              font-weight: 700;
              letter-spacing: 0.01rem;
              color: $neutral-solid-700;
              @media screen and (max-width: $breakpoint-pc) {
                font-size: rem(22);
              }
              @media screen and (max-width: $breakpoint-tablet) {
                font-size: rem(20);
              }
            }
          }
          .case-detail {
            $icon_width: 20px;
            $icon_height: 34px;
            $gap_width: 20px;
            display: flex;
            column-gap: calc(#{$gap_width} * 2 + #{$icon_width});
            @media screen and (max-width: $breakpoint-pc) {
              $gap_width: 8px;
              flex-direction: column;
              height: 100%;
              row-gap: calc(#{$gap_width} * 2 + #{$icon_width});
            }
            .clients-voice {
              display: flex;
              align-items: center;
              column-gap: 24px;
              padding: 20px 24px;
              background: #ebebeb;
              border-radius: 12px;
              max-width: 464px;
              box-sizing: border-box;
              position: relative;
              @media screen and (max-width: $breakpoint-pc) {
                position: relative;
                max-width: initial;
                width: 100%;
                padding: 40px 20px 16px;
              }
              &:after {
                background: url("/images/ico_triangle-forward_primary.png") no-repeat center;
                background-size: contain;
                content: '';
                display: block;
                height: $icon_height;
                position: absolute;
                right: calc(-#{$gap_width} - #{$icon_width});
                top: calc(50% - #{$icon_height} * 0.5);
                width: $icon_width;
                @media screen and (max-width: $breakpoint-pc) {
                  bottom: calc(-#{$gap_width} * 2 - #{$icon_width});
                  right: calc(50% - #{$icon_height} * 0.5);
                  top: unset;
                  transform: rotate(90deg);
                }
              }
              .clients-voice__image {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                @media screen and (max-width: $breakpoint-pc) {
                  position: absolute;
                  top: -36px;
                  width: 72px;
                  height: 72px;
                }
                @media screen and (max-width: $breakpoint-tablet) {
                  position: absolute;
                  top: -30px;
                  width: 64px;
                  height: 64px;
                }
              }
              .clients-voice__text {
                line-height: 175%;
                letter-spacing: 0.01rem;
                color: $neutral-solid-600;
              }
            }
            .solution {
              display: flex;
              flex-direction: column;
              row-gap: 8px;
              padding: 20px 24px;
              background: #dcf0f2;
              border-radius: 12px;
              max-width: 414px;
              min-width: 394px;
              height: 100%;
              box-sizing: border-box;
              @media screen and (max-width: $breakpoint-pc) {
                max-width: initial;
                min-width: initial;
                width: 100%;
              }
              .solution__title {
                font-weight: 700;
                line-height: 175%;
                letter-spacing: 0.02rem;
                color: $mofmof-blue-700;
                width: fit-content;
                border-bottom: 2px solid $mofmof-blue-700;
                word-break: keep-all;
              }
              .solution__text {
                font-weight: 500;
                line-height: 175%;
                letter-spacing: 0.01rem;
                color: $mofmof-blue-700;
              }
            }
          }
        }
      }
    }
  }

  .feature.section {
    background: $neutral-solid-30;
    margin: 0;
    overflow: hidden;
    padding: 64px 32px 112px;
    position: relative;
    @include section-splitter-setting("neutral-solid-50");
    @media screen and (max-width: $breakpoint-pc) {
      padding: 56px 24px 92px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 48px 16px 72px;
    }
    
    .features {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 880px;
      row-gap: 96px;
      width: 100%;
      .feature__item {
        display: flex;
        flex-direction: column;
        row-gap: 48px;
        width: 100%;
        @media screen and (max-width: $breakpoint-pc) {
          row-gap: 40px;
        }
        @media screen and (max-width: $breakpoint-tablet) {
          row-gap: 32px;
        }
        .headline {
          display: flex;
          flex-direction: column;
          row-gap: 12px;
          .headline__heading {
            display: flex;
            flex-direction: column;
            border-left: 2px solid $mofmof-blue-600;
            padding-left: 18px;
            @media screen and (max-width: $breakpoint-pc) {
              padding-left: 15px;
            }
            @media screen and (max-width: $breakpoint-tablet) {
              padding-left: 12px;
            }
            .text-en {
              @extend %eyecatch-text-base;
              font-size: rem(24);
              letter-spacing: 0.05rem;
              @media screen and (max-width: $breakpoint-pc) {
                font-size: rem(20);
              }
              @media screen and (max-width: $breakpoint-tablet) {
                font-size: rem(16);
              }
            }
            .text-ja {
              color: $neutral-solid-700;
              font-size: rem(28);
              font-weight: 700;
              letter-spacing: 0.01rem;
              line-height: 175%;
              word-break: keep-all;
              @media screen and (max-width: $breakpoint-pc) {
                font-size: rem(24);
              }
              @media screen and (max-width: $breakpoint-tablet) {
                font-size: rem(20);
                line-height: 150%;
              }
            }
          }
          .headline__description {
            line-height: 175%;
            letter-spacing: 0.01rem;
            color: $neutral-solid-700;
          }
        }
        .main-contents {
          display: flex;
          flex-direction: column;
          row-gap: 40px;
          .main-contents__item {
            .phase {
              @extend %h3-text-base;
              font-size: rem(14);
              letter-spacing: 0.03rem;
              margin-bottom: 6px;
            }
            .flow__list {
              display: flex;
              column-gap: 6px;
              list-style: none;
              %current-phase-setting {
                background: $mofmof-blue-600;
                color: $text-color-white;
                &::after {
                  border-color: transparent transparent transparent $mofmof-blue-600;
                }
              }
              &[data-index="1"] {
                .flow__list--item:first-child {
                  @extend %current-phase-setting;
                }
              }
              &[data-index="2"] {
                .flow__list--item:nth-child(2) {
                  @extend %current-phase-setting;
                }
              }
              &[data-index="3"] {
                .flow__list--item:last-child {
                  @extend %current-phase-setting;
                }
              }
              .flow__list--item {
                align-items: center;
                background: $mofmof-blue-100;
                box-sizing: border-box;
                color: $mofmof-blue-600;
                display: flex;
                font-size: rem(14);
                font-weight: 500;
                height: 40px;
                line-height: 100%;
                max-width: 138px;
                padding: 0px 18px;
                position: relative;
                width: 100%;
                @media screen and (max-width: $breakpoint-tablet) {
                  font-size: rem(12);
                  padding: 0px 15px;
                  height: 30px;
                  max-width: 108px;
                }
                &::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0px;
                  border-width: 20px 6px;
                  border-color: transparent transparent transparent
                    $neutral-solid-30;
                  border-style: solid;
                  @media screen and (max-width: $breakpoint-tablet) {
                    border-width: 15px 6px;
                  }
                }
                &::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  right: -12px;
                  border-width: 20px 6px;
                  border-color: transparent transparent transparent
                    $mofmof-blue-100;
                  border-style: solid;
                  @media screen and (max-width: $breakpoint-tablet) {
                    border-width: 15px 6px;
                  }
                }
              }
            }
            .main-contents__item__balloon {
              position: relative;
              display: flex;
              column-gap: 32px;
              width: fit-content;
              padding: 24px 32px 32px;
              margin-top: 36px;
              background: $neutral-white;
              border-radius: 12px;
              filter: drop-shadow($shadow-card);
              box-sizing: border-box;
              @media screen and (max-width: $breakpoint-pc) {
                flex-direction: column;
                row-gap: 28px;
                padding: 20px;
              }
              @media screen and (max-width: $breakpoint-tablet) {
                row-gap: 24px;
                padding: 16px;
              }
              .main-contents__item__balloon__item {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                flex: 1;
                max-width: 250.67px;
                @media screen and (max-width: $breakpoint-pc) {
                  max-width: initial;
                  row-gap: 8px;
                }
                @media screen and (max-width: $breakpoint-tablet) {
                  row-gap: 4px;
                }
                .main-contents__item__balloon__item__title {
                  font-size: rem(18);
                  font-weight: 700;
                  line-height: 175%;
                  letter-spacing: 0.02rem;
                  color: $neutral-solid-700;
                  @media screen and (max-width: $breakpoint-tablet) {
                    font-size: rem(16);
                  }
                }
                .main-contents__item__balloon__item__description {
                  font-size: rem(14);
                  line-height: 175%;
                  letter-spacing: 0.01rem;
                  color: $neutral-solid-700;
                }
              }
            }
            .main-contents__item__balloon::after {
              content: '';
              position: absolute;
              top: -26px;
              width: 54px;
              height: 27px;
              background: $neutral-white;
              clip-path: polygon(50% 0, 0% 100%, 100% 100%);
            }
            .main-contents__item__balloon.is-first::after {
              left: 42px;
              @media screen and (max-width: $breakpoint-tablet) {
                left: 27px;
              }
            }
            .main-contents__item__balloon.is-second::after {
              left: 186px;
              @media screen and (max-width: $breakpoint-tablet) {
                left: 141px;
              }
            }
            .main-contents__item__balloon.is-third::after {
              left: 330px;
              @media screen and (max-width: $breakpoint-tablet) {
                left: 255px;
              }
            }
          }
        }
      }

      .feature__item.second {
        .main-contents {
          display: flex;
          flex-direction: column;
          row-gap: 32px;
          .main-contents__item {
            display: flex;
            column-gap: 32px;
            padding: 24px 32px;
            background: $neutral-white;
            border-radius: 12px;
            box-shadow: $shadow-card;
            @media screen and (max-width: $breakpoint-pc) {
              padding: 20px;
              align-items: center;
              flex-direction: column;
              row-gap: 28px;
            }
            @media screen and (max-width: $breakpoint-tablet) {
              padding: 16px;
              row-gap: 24px;
            }
            .description {
              display: flex;
              flex-direction: column;
              row-gap: 12px;
              .description__title {
                display: flex;
                flex-direction: column;
                .description__index {
                  @extend %eyecatch-text-base;
                  font-size: rem(14);
                  line-height: 150%;
                  letter-spacing: 0.03rem;
                }
                .description__title--text {
                  font-size: rem(18);
                  font-weight: 700;
                  line-height: 150%;
                  letter-spacing: 0.02rem;
                  color: $neutral-solid-700;
                }
              }
              .description__text {
                line-height: 175%;
                letter-spacing: 0.01rem;
                color: $neutral-solid-700;
              }
            }
            .image {
              .img {
                width: 352px;
                @media screen and (max-width: $breakpoint-pc) {
                  width: 100%;
                  max-width: 352px;
                }
              }
            }
          }
        }
      }
      
    }
  }

  .about {
    background: $neutral-solid-50;
    margin: 0;
    overflow: hidden;
    padding: 64px 32px 112px;
    position: relative;
    @include section-splitter-setting("neutral-solid-30");
    @media screen and (max-width: $breakpoint-pc) {
      padding: 56px 24px 92px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 48px 16px 72px;
    }
    .contents {
      .top-content {
        .top-content__item {
          &.first {
            &:before {
              background-image: url("/images/service/dev-team-rental/startup/about/bg-image-03@964w.webp");
              height: 312px;
              top: 52px;
              left: -96px;
              width: 467px;
            }
          }
          &.second {
            &:after {
              background-image: url("/images/service/dev-team-rental/startup/about/bg-image-02@960w.webp");
              height: 320px;
              right: -104px;
              top: 52px;
              width: 480px;
            }
          }
          &.third {
            &:before {
              background-image: url("/images/service/dev-team-rental/scaleup/about/bg-image-04@480w.png");
              height: 322px;
              top: 160px;
              left: -120px;
              width: 482px;
            }
          }
        }
      }
    }
  }

  .process {
    align-items: center;
    background: $neutral-solid-30;
    display: flex;
    flex-direction: column;
    margin: 0;
    overflow: hidden;
    padding: 64px 32px 160px;
    position: relative;
    @include section-splitter-setting("mofmof-blue-100");
    @media screen and (max-width: $breakpoint-pc) {
      padding: 56px 24px 92px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 48px 16px 72px;
    }
    
    .info {
      width: 100%;
      max-width: 900px;
      margin-bottom: 48px;
      @media screen and (max-width: $breakpoint-pc) {
        max-width: 600px;
      }
      .info--title {
        font-size: rem(20);
        font-weight: 700;
        line-height: 150%;
        letter-spacing: 0.02rem;
        color: $neutral-white;
        text-align: center;
        padding: 12px;
        background: $mofmof-blue-600;
        border-radius: 4px;
        margin-bottom: 12px;
        word-break: keep-all;
        @media screen and (max-width: $breakpoint-tablet) {
          font-size: rem(18);
        }
      }
      .info--description {
        line-height: 175%;
        letter-spacing: 0.01rem;
        color: $neutral-solid-700;
      }
    }
  }

  .text-link:hover {
    opacity: 0.8;
  }

  .pc-except {
    display: none;
    @media screen and (max-width: $breakpoint-pc) {
      display: block;
    }
  }

  .pc-except-inline {
    display: none;
    @media screen and (max-width: $breakpoint-pc) {
      display: inline-block;
    }
  }

  .tb-except {
    display: block;
    @media screen and (min-width: 561px) and (max-width: $breakpoint-pc) {
      display: none;
    }
  }

  .sp-except-inline {
    display: inline-block;
    @media screen and (max-width: $breakpoint-tablet) {
      display: none;
    }
  }

  .sp-only {
    display: none;
    @media screen and (max-width: $breakpoint-tablet) {
      display: block;
    }
  }

  .sp-only-inline {
    display: none;
    @media screen and (max-width: $breakpoint-tablet) {
      display: inline-block;
    }
  }
}
