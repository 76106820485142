.service-process {
  &_section {
    margin-bottom: 120px;
    position: relative;

    @media screen and (max-width: 1024px) {
      width: 88.75%;
      margin: 60px auto 0;
    }

    &--background {
      width: 100%;
      height: 72%;
      position: absolute;
      // TODO: 黄色のあみあみ
      background: $neutral-white url(/images/bg.png);
      z-index: -1;
      top: 550px;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &_container {
    width: 1300px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  &_dev-img {
    text-align: center;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &_dev-cards {
    width: 89.2%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      width: 100%;
      display: block;
    }
  }

  &_dev-card {
    width: 46.6%;
    filter: drop-shadow(11.18px 16.58px 35px lightgray);
    font-size: $small-font-size;
    margin-top: 40px;
    padding: 40px 0px 40px 0px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      font-size: rem(12);
    }

    &--before {
      @extend .service-process_dev-card;
      background-color: #FFFCF5;
    }
    &--after {
      @extend .service-process_dev-card;
      background: linear-gradient(to top, #E8FDFF, $neutral-white);
      border: solid 2px #00a4b4;
    }
  }

  &_dev-card-title {
    color: $primary-color;
    font-size: rem(32);
    font-weight: bold;
    line-height: 1;
    text-align: center;

    @media screen and (max-width: 1024px) {
      font-size: rem(20);
    }

    &--sub {
      margin-top: 15px;
      font-size: rem(12);
      font-weight: normal;
      display: block;

      @media screen and (max-width: 1024px) {
        font-size: rem(10);
      }
    }

    &:after {
      display: block;
      width: 100%;
      text-align: center;
      font-size: rem(24);
      content: "･･･";
      color: $primary-color;
      margin: 15px 0 10px 0;
    }
  }

  &_dev-card-content {
    margin: 0 40px 0 40px;
  }

  &_arrow-container {
    height: 100%;
    width: 6%;

    @media screen and (max-width: 1024px) {
      width: 30px;
      margin: 0 auto;
    }
  }

  &_arrow {
    position: relative;
    display: inline-block;
    height: 50px;
    width: 30px;
    top: 200px;
    left: -3px;
    background-color: $primary-color;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 15px;
      top: 0;
    }

    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border: 50px solid transparent;
      border-left: 50px solid $primary-color;
      left: 20px;
      top: -24px;

      @media screen and (max-width: 1024px) {
        border: 30px solid transparent;
        border-top: 30px solid $primary-color;
        position: relative;
        left: -15px;
        top: 40px;
      }
    }
  }

  &_technology {
    margin-top: 100px;
    width: 100%;
    margin: 100px auto 0;

    @media screen and (max-width: 1024px) {
      width: 100%;
      margin: 40px 0 0 0;
    }
  }

  &_sec-headline {
    color: $primary-color;
    font-size: rem(24);
    font-weight: bold;
    line-height: 1;
    text-align: center;

    &--sub {
      margin-top: 15px;
      font-size: rem(12);
      font-weight: normal;
      display: block;
    }
  }

  &_technology-icons {
    margin-top: 30px;
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    gap: 12px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: auto;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  &_technology-icon {
    position: relative;
    width: 140px;
    height: 140px;
    background-color: $text-color-white;
    filter: drop-shadow(11.18px 16.58px 20px lightgray);
    text-align : center;
    vertical-align: middle;

    @media screen and (max-width: 1024px) {
      width: 70px;
      height: 70px;
      margin-top: 5px;
    }

    img {
      object-fit: contain;
      width: 100px;
      height: 100%;

      @media screen and (max-width: 1024px) {
        width: 55px;
      }
    }

    img.small-icon {
      width: 60px;

      @media screen and (max-width: 1024px) {
        width: 25px;
      }
    }
  }

  &_request {
    width: 89.2%;
    margin: 100px auto 0;

    @media screen and (max-width: 1024px) {
      width: 100%;
      margin: 40px 0 0 0;
    }
  }

  &_request-items {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      margin-top: 0;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
  }

  &_request-item {
    @media screen and (max-width: 1024px) {
      width: 50%;
      height: 195px;
      margin-top: 20px;

      img {
        width: auto;
        height: 100%;
      }
    }

    &:not(:last-child):after {
      content: "●";
      color: $primary-color;
      font-weight: bold;
      position: relative;
      top: -150px;
      left: 30px;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &_request-more {
    margin-top: 30px;
    text-align: center;

    &--weight {
      font-weight: bold;
    }
  }
}
