.home-member {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;

  .home-member__container {
    position: relative;
    z-index: 1;
  }

  .member-loop-track {
    width: 100vw;
    margin: 0 calc(50% - 50vw);
    overflow: hidden;
    display: flex;
  }

  .member-loop {
    display: flex;
    align-items: center;
    gap: 48px;
    padding-right: 48px;
    opacity: 0;
    @media screen and (max-width: $breakpoint-xl) {
      gap: 32px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      gap: 20px;
    }
  }

  .member-loop__item {
    width: 360px;
    @media screen and (max-width: $breakpoint-xl) {
      width: 300px;
    }
    @media screen and (max-width: $breakpoint-pc) {
      width: 240px;
    }
  }

  .bg-image-right-circle {
    position: absolute;
    width: 640px;
    top: -148px;
    right: -328px;
    @media screen and (max-width: $breakpoint-xl) {
      width: 480px;
      top: -24px;
      right: -240px;
    }
    @media screen and (max-width: $breakpoint-pc) {
      width: 400px;
      right: -240px;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 320px;
      top: 24px;
      right: -200px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 240px;
      top: 96px;
      right: -148px;
    }
  }
  .bg-image-left-top-dot {
    position: absolute;
    width: 290px;
    top: -40px;
    left: -108px;
    @media screen and (max-width: $breakpoint-pc) {
      width: 240px;
      top: -80px;
      left: -108px;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 200px;
      top: -64px;
      left: -108px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 145px;
      top: -24px;
      left: -72px;
    }
  }
  .bg-image-left-top-illust {
    position: absolute;
    top: 0;
    left: 52px;
    width: 280px;
    @media screen and (max-width: $breakpoint-pc) {
      width: 240px;
      top: -48px;
      left: 32px;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 200px;
      top: -24px;
      left: 16px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 140px;
      top: 8px;
      left: 16px;
    }
  }
}
