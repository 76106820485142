.news-post {
  * {
    box-sizing: border-box;
  }
  .container {
    width: 100%;
    max-width: 1200px;
  }
  .article-container {
    width: 100%;
    max-width: 800px;
    margin: 64px auto 80px;
    padding: 0 24px;
    @media screen and (max-width: $breakpoint-tablet) {
      margin-top: 32px;
    }
  }
  .article-heading {
    border-bottom: solid 1px $neutral-solid-100;
    margin-bottom: 64px;
    padding-bottom: 32px;
    position: relative;
    &::before {
      $height: 12px;
      background-color: $mofmof-blue-400;
      border-radius: 2px;
      bottom: calc(-#{$height} * 0.5);
      content: '';
      display: block;
      height: $height;
      left: 0;
      position: absolute;
      width: 300px;
    }
  }
  .article-title {
    font-size: rem(32);
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: rem(1.28);
    margin-bottom: 8px;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(24);
      line-height: 1.5;
      letter-spacing: rem(0.96);
    }
  }
  .article-date {
    font-size: rem(20);
    font-style: normal;
    font-weight: 500;
    line-height: 1.5; /* 30px */
    letter-spacing: rem(0.8);
  }
  .article-body {
    margin-bottom: 64px;

    > *:first-child {
      margin-top: 0;
    }

    @include markdown-style;

  }

  .button-box {
    display: flex;
    justify-content: center;
  }
}