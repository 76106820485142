.contents_service {
  width: 1000px;
  margin: 0 auto;
  padding: 70px 0;
  background: url(/images/bg.svg) repeat-y center;
  background-size: 600px;
  margin-bottom: 55px;
  @media screen and (max-width: 1024px) {
    width: auto;
    max-width: 550px;
    padding: 50px 0;
    margin-bottom: 0;
  }

  .sercive_img {
    float: right;
    width: 340px;
    @media screen and (max-width: 1024px) {
      float: none;
      width: auto;
      padding: 20px 30px;
      margin: 0 auto;
      img {
        max-width: 330px;
      }
    }
  }

  .ttl_right {
    padding-top: 0px;
  }

  .sercive_txt {
    float: left;
    width: 560px;
    @media screen and (max-width: 1024px) {
      width: auto;
    }

    h3 {
      span {
        background: #f4f200;
        padding: 13px 30px;
        font-size: rem(24);
        color: $primary-color;
        line-height: 2.5;
        @media screen and (max-width: 1024px) {
          padding: 9px 3px;
          font-size: rem(17);
          line-height: 2.5;
        }

        &.accent:before {
          content: url("/images/recruit/work-environment/accent_circle.png");
          position: absolute;
          top: -20px;
          left: -30px;
        }
      }

      a {
        text-decoration: none;
      }

      margin-bottom: 30px;
      position: relative;
    }

    @media screen and (max-width: 1024px) {
      float: none;
      width: auto;
      padding: 0 30px;
    }
  }
}

.contents_service.right {
  .sercive_img {
    float: left;
    @media screen and (max-width: 1024px) {
      float: none;
    }
  }

  .sercive_txt {
    float: right;
  }
}

.contents_service.rental {
  background: transparent;
  padding-top: 0;

  .sercive_img {
    padding-top: 0;
  }

  .sercive_txt {
    width: 600px;
    @media screen and (max-width: 1024px) {
      width: auto;
      padding: 30px;
    }
  }
}
