.footernav {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  margin-bottom: 64px;

  @media screen and (max-width: $breakpoint-pc) {
    flex-direction: column;
  }
  
  .footernav_link {
    &:hover {
      text-decoration: none;
    }
  }

  .footernav_item {
    width: 560px;
    height: 240px;
    margin: 30px auto;
    filter: drop-shadow(2.3px 1.9px 3px lightgray);

    @media screen and (max-width: $breakpoint-pc) {
      width: 90%;
      height: 180px;
    }
  }

  .footernav_content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .footernav_image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .footernav_title {
    display: inline-block;
    width: 400px;
    background-color: white;
    color: $primary-color;
    text-align: center;
    padding: 15px;

    @media screen and (max-width: $breakpoint-pc) {
      width: 80%;
    }
  }

  .footernav_more-button {
    position: absolute;
    right: 20px;
    bottom: -22px;
    filter: none;
  }
}
