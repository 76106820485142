body {
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  letter-spacing: $base-letter-spacing;
}

a {
  color: $text-color;
  text-decoration: none;
  @media screen and (min-width: 1025px) {
    &:hover {
      color: $text-link-color;
      text-decoration: underline;
    }
  }
}

hr {
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

img {
  vertical-align: bottom;
}

input, select, textarea {
  background-color: $background-color-old;
  &::placeholder {
    color: $text-color-light;
  }
}

select {
  appearance: none;
  background-image: url('/images/ico_expand_more.svg');
  background-position: top 50% right 8px;
  background-repeat: no-repeat;
  color: $text-color-light;
}

@media screen and (max-width: 1024px) {
  pointer-events: none;
}
