// 新スタイル お問い合わせと資料ダウンロードで使用
.form-wrapper {
  background-color: $background-base-color;
  border-radius: $border-radius-m;
  padding: 48px 24px;
  width: 100%;
  @media screen and (max-width: $breakpoint-md) {
    padding: 24px 16px 48px;
  }
}
.form-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (max-width: $breakpoint-md) {
    gap: 32px;
  }
  .form-group {
    align-items: center;
    display: flex;
    gap: 12px;
    height: 48px;
    padding: 0;
    @media screen and (max-width: $breakpoint-md) {
      align-items: flex-start;
      flex-direction: column;
      height: auto;
    }
    &:has(textarea) {
      height: auto;
      textarea {
        resize: block;
        min-height: 256px;
      }
    }
    .form-label {
      flex: 0 0 200px;
      font-size: rem(14);
      font-weight: bold;
      line-height: 150%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      @media screen and (max-width: $breakpoint-md) {
        justify-content: start;
        flex: auto;
      }
      &.required::after {
        align-items: center;
        background-color: $red-500;
        border-radius: $border-radius-s;
        color: $text-color-white;
        content: '必須';
        display: inline-flex;
        font-size: rem(12);
        font-weight: normal;
        height: 24px;
        line-height: 170%;
        margin-left: 4px;
        padding: 0 8px;
        flex-shrink: 0;
      }
    }
    .form-control {
      background: $neutral-white;
      border-radius: $border-radius-s;
      border: none;
      font-size: rem(16);
      height: rem(48);
      line-height: 1.4;
      padding: 0 16px;
      width: 100%;
      &::placeholder {
        color: $neutral-solid-300;
      }
    }
    select.form-control {
      $icon-size: 16px;
      $gap: 12px;
      background: url('/images/ico_chevron-down_gray.svg') no-repeat right
        12px center / $icon-size auto;
      background-color: $neutral-white;
      position: relative;
      width: auto;
      max-width: 100%;
      padding-right: 12px + $icon-size + $gap;
    }
    textarea.form-control {
      padding: 12px 16px;
    }
  }
}

// NOTE: ↓旧スタイル ↑に統合したい
// form (entryform.htmlのみに使われている)
.entry_form {
  .form-group {
    padding: 0 25px;
    margin-bottom: 16px;
  }
  
  .form_control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 7px 25px;
    margin-right: -25px;
    margin-left: -25px;
    margin-bottom: 25px;
    font-size: $small-font-size;
    line-height: 1.42857143;
    color: #555;
    background-color: $text-color-white;
    border: 1px solid #ccc;
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  
  .form_label {
    display: inline-block;
    color: $primary-color;
    padding-bottom: 2px;
    margin-left: -25px;
    max-width: 100%;
  }
  
  .input_check {
    width: 24px;
  }
  
  textarea.form_control {
    height: auto;
    resize: none;
    margin-bottom: 50px;
  }
  
  .submit {
    position: relative;
    width: 200px;
    text-align: left;
    padding-left: 20px;
    @media screen and (max-width: 1024px) {
      display: block;
      width: 180px;
      margin: 0 auto;
    }
  
    &:after {
      position: absolute;
      top: 0;
      right: 20px;
      display: block;
      content: '';
      width: 11px;
      height: 100%;
      background: url(/images/contact/arrow_submit.png) no-repeat center;
      background-size: contain;
    }
  }
  
  .button.submit {
    padding: 1rem 1rem;
    bottom: 30px;
  }
}
// 旧デザインここまで

.form__submit-group {
  .privacy-policy-agreement {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: 1fr;
    margin: 0 auto 20px auto;
    width: fit-content;
    input[type='checkbox'] {
      grid-row: 1;
      grid-column: 1;
    }
    label {
      grid-row: 1;
      grid-column: 2;
      br {
        display: none;
      }
    }
    .error-message {
      grid-row: 2;
      grid-column: 2;
      br {
        display: none;
      }
    }
    @media screen and (max-width: $breakpoint-tablet) {
      margin-bottom: 40px;
      max-width: 100%;
    }
  }
}
.form__submit-button {
  display: flex;
  justify-content: center;
  button {
    width: 300px;
  }
}

// デザインしたチェックボックスを使用する場合
input[type='checkbox'].designed-checkbox {
  display: none;
}

input[type='checkbox'].designed-checkbox + .label-with-designed-checkbox {
  display: inline-block;
  position: relative;
  white-space: nowrap;

  a:link {
    color: $text-link-color;
  }

  &::before {
    $size: 24px;
    background-color: $neutral-white;
    border-radius: 4px;
    border: solid 1px $primary-color;
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    height: $size;
    left: -($size + $size / 2);
    position: absolute;
    top: 2px;
    width: $size;
  }
}

input[type='checkbox']:checked + .label-with-designed-checkbox {
  &::before {
    content: '\e182';
    font-family: 'Phosphor';
    background-color: $primary-color;
    color: $neutral-white;
  }
}  
