.remove-padding {
  padding: 0 !important;
}

// ↓ .pb0, .pt10, .pr20 etc...が出力される
// 10px単位
.p {
  $directions: (t: top, b: bottom, l: left, r: right);
  @each $direction-key, $direction in $directions {
    $amounts: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
    @each $amount in $amounts {
      &#{$direction-key}#{$amount * 10} {
        padding-#{$direction}: $amount * 10 + px !important;
      }
    }
  }
}

// 8px単位
.p {
  $directions: (t: top, b: bottom, l: left, r: right);
  @each $direction-key, $direction in $directions {
    $amounts: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
    @each $amount in $amounts {
      &#{$direction-key}#{$amount * 8} {
        padding-#{$direction}: $amount * 8 + px !important;
      }
    }
  }
}

// ↓ .p0, .p10, .p20 etc...が出力される
// 10px単位
.p {
  $amounts: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
  @each $amount in $amounts {
    &#{$amount * 10} {
      padding: $amount * 10 + px !important;
    }
  }
}

// 8px単位
.p {
  $amounts: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
  @each $amount in $amounts {
    &#{$amount * 8} {
      padding: $amount * 8 + px !important;
    }
  }
}

