// MEMO: 開発チームレンタルや採用情報等、汎用的なpost用
.posts {
  @media screen and (max-width: 1024px) {
    width: auto;
  }
}

.container_posts {
  width: $base-post-width;
  margin: 0 auto;
  margin-bottom: 70px;

  p {
    margin-bottom: 1.5em;
  }

  img {
    width: 100%;
  }

  h2 {
    font-size: rem(20);
    padding-bottom: 20px;
    color: $primary-color;
    padding-top: 30px;

    &#contact_text {
      margin: 1rem;
    }
  }

  ol {
    @extend ol.list-default;
  }

  ul {
    @extend ul.list-default;
  }

  a {
    color: $text-link-color;
  }

  img {
    margin-bottom: 10px;
  }

  .img-pull-left {
    float: left;
    margin-right: 30px;
  }

  .img-pull-right {
    float: right;
    margin-left: 30px;
  }

  @media screen and (max-width: 1024px) {
    width: auto;
    padding: 30px;
    margin-bottom: 0;
  }

  @media screen and (min-width: 1024px) {
    &#blog_posts {
      display: flex;
      width: 1024px;

      .author {
        background: white;
      }
    }
  }
}
