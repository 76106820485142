.contact {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .container-lg {
    max-width: 992px;
    padding: 48px 24px 104px 24px;
    width: 100%;
    margin: 0 auto;
  }
  
  .contact__title-sub {
    width: 100%;
    margin-bottom: 64px;
    text-align: center;
  }

  // formのアイテム
  // コンタクトページだけのスタイル上書き
  .form-content {
    select.form-control {
      width: 400px; // デフォルト幅を揃える
    }
  }

}