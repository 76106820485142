// サービスセクション
.home-service {
  position: relative;
  .home-service__container {
    padding-top: 120px;
    padding-bottom: 200px;
    position: relative;
    z-index: 1;
    @media screen and (max-width: $breakpoint-tablet) {
      padding-bottom: 120px;
    }
  }
  .description {
    display: flex;
    gap: clamp(24px, 4vw, 64px);
    align-items: center;
    margin: 0 0 160px;
    @media screen and (max-width: $breakpoint-xl) {
      margin-left: clamp(24px, 3vw, 48px);
      margin-right: clamp(24px, 3vw, 48px);
    }
    @media screen and (max-width: $breakpoint-md) {
      flex-direction: column-reverse;
      margin-bottom: 80px;
      gap: 64px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      margin: 0 0 64px;
      gap: 40px;
    }
  }
  .description__image {
    img {
      width: 100%;
    }
    @media screen and (max-width: $breakpoint-md) {
      max-width: 600px;
      margin: 0 48px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      margin: 0;
    }
  }
  .description__text-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1;
    @media screen and (max-width: $breakpoint-tablet) {
      text-align: center;
      align-items: center;
    }
  }
  .description__title {
    font-size: rem(40);
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.04em;
    white-space: nowrap;
    @media screen and (max-width: $breakpoint-xl) {
      font-size: clamp(rem(24), 3vw, rem(40));
    }
  }
  .description__text {
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(14);
    }
  }
  .service-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 64px;
    margin-bottom: 200px;
    @media screen and (max-width: $breakpoint-xl) {
      gap: 64px 48px;
    }
    @media screen and (max-width: $breakpoint-md) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      max-width: 400px;
      margin: 0 auto 92px;
    }
  }
  .service-card {
    row-gap: 24px;
    container-type: inline-size;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr max-content;
    @media screen and (max-width: $breakpoint-xl) {
      row-gap: 16px;
    }
    @media screen and (max-width: $breakpoint-md) {
      grid-template-columns: 50% 1fr;
      grid-template-rows: 1fr max-content;
      column-gap: 24px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      grid-template-columns: 1fr;
    }
  }

  .service-card__image {
    width: 100%;
    @media screen and (max-width: $breakpoint-md) {
      grid-column: 1/2;
      grid-row: 1/3;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      grid-column: auto;
      grid-row: auto;
    }
  }
  .service-card__text {
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    @media screen and (max-width: $breakpoint-xl) {
      gap: 8px;
    }
    @media screen and (max-width: $breakpoint-md) {
      grid-column: 2/3;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      grid-column: auto;
    }
  }
  .service-card__label {
    font-size: rem(14);
    color: $primary-color;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.04em;
    @media screen and (max-width: $breakpoint-xl) {
      font-size: rem(12);
    }
  }
  .service-card__title {
    font-size: rem(20);
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.04em;
    @media screen and (max-width: $breakpoint-xl) {
      font-size: rem(16);
    }
  }
  .service-card__description {
    font-size: rem(16);
    line-height: 1.5;
    letter-spacing: 0.04em;
    @media screen and (max-width: $breakpoint-xl) {
      font-size: rem(14);
    }
  }
  .service-card__btn {
    padding: 12px 24px;
    font-size: rem(18);
    > i {
      font-size: rem(20);
    }
    @media screen and (max-width: $breakpoint-xl) {
      padding: 12px 20px;
      font-size: rem(14);
    }
    @media screen and (max-width: $breakpoint-md) {
      grid-column: 2/3;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      grid-column: auto;
    }
  }

  .partners-loop-track {
    width: 100vw;
    margin: 0 calc(50% - 50vw);
    overflow: hidden;
    display: flex;
  }

  .partners-loop {
    display: flex;
    align-items: center;
    gap: 48px;
    padding-right: 48px;
    opacity: 0;
  }

  .partners-loop__item {
    max-width: 132px;
    max-height: 72px;
  }

  .bg-image__left-top {
    position: absolute;
    width: 290px;
    top: -52px;
    left: -48px;
    @media screen and (max-width: $breakpoint-xl) {
      width: 240px;
      top: 0;
      left: -48px;
    }
    @media screen and (max-width: $breakpoint-pc) {
      width: 200px;
      top: 24px;
      left: -56px;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 172px;
      top: 32px;
      left: -64px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 145px;
      top: 48px;
      left: -82px;
    }
  }
}
