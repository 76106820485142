$sizes: 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;

.w {
  @each $size in $sizes {
    &#{$size} {
      width: $size * 1% !important;
    }
  }
}
// => .w10, .w50, .w100 などが出力される
