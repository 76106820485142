// home
.home {
  * {
    box-sizing: border-box;
  }
  .home-kv-service-wrapper {
    background-color: $neutral-white;
    background-image: url(/images/home/home-bg-grid.svg);
    position: relative;
    overflow: hidden;
  }
  .home-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: 0 auto 80px;
    @media screen and (max-width: $breakpoint-md) {
      margin-bottom: 52px;
    }
    .home-title__en {
      color: $primary-color;
      font-family: $title-font-family;
      font-size: rem(72);
      font-weight: 900;
      line-height: 1;
      letter-spacing: 0.08em;
      text-align: center;
      @media screen and (max-width: $breakpoint-md) {
        font-size: rem(56);
      }
    }
    .home-title__ja {
      color: $text-color;
      font-size: rem(16);
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.06em;
      text-align: center;
    }
    .home-title__en--white {
      @extend .home-title__en;
      color: $neutral-white;
    }
    .home-title__ja--white {
      @extend .home-title__ja;
      color: $neutral-white;
    }
  }
}

// FIXME: 以下、未改修ですmm

@media screen and (max-width: 1024px) {
  .interview_comment {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: left;
    padding-right: 12px;
    color: $text-link-color;
  }
}

ul.article_list {
  li {
    padding: 26px 0;
    background-image: linear-gradient(
      to right,
      $primary-color 55%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    a {
      display: block;
    }
  }
}

@media screen and (max-width: 1024px) {
  ul.article_list {
    padding: 0 30px;
    margin-bottom: 30px;
  }
  ul.blog_list_tec {
    padding: 0 30px;
    margin-bottom: 30px;

    li {
      padding-right: 82px;
      padding-left: 0;
    }

    &:after {
      right: 42px;
    }
  }
}

// common

.main_visual {
  position: relative;
  width: 100%;
  height: 550px;
  text-align: center;
  @media screen and (max-width: 1024px) {
    height: 400px;
    overflow: hidden;
    .main_img {
      width: auto;
      height: 100%;
    }
  }
}

.main_service_text {
  position: absolute;
  top: 30px;
  left: 100px;
}

.main_text_jp {
  position: absolute;
  color: $text-link-color;
  font-size: rem(20);
  width: 100%;
  text-align: center;
  top: 52px;
}

.main_text_en_wrap {
  position: absolute;
  bottom: 80px;
  left: 100px;
  text-align: left;

  p {
    margin-bottom: 40px;
  }

  .main_text_en1,
  .main_text_en2,
  .main_text_en3 {
    font-family: $title-font-family;
    font-size: rem(50);
    background: $primary-color;
    text-align: left;
    color: $text-color-white;
    padding: 6px 40px;
  }

  .main_text_en1 {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .main_visual {
    position: relative;
    width: auto;
    height: 400px;
    text-align: center;
    overflow: hidden;

    img {
      position: relative;
      width: 800px;
    }
  }
  img.main_service_text {
    position: absolute;
    width: 53%;
    top: 58px;
    left: 30px;
  }
  .main_text_jp {
    top: 30px;
    font-size: $base-font-size;
  }
  .main_text_en_wrap {
    bottom: 3px;
    left: 25px;

    .main_text_en1,
    .main_text_en2,
    .main_text_en3 {
      font-size: rem(25);
      padding: 6px 20px;
    }
  }
}
