.home-feature {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  .home-feature__container {
    position: relative;
    z-index: 1;
  }
  .features {
    display: flex;
    gap: 80px;
    @media screen and (max-width: $breakpoint-xl) {
      margin: 0 24px;
      gap: 64px;
    }
    @media screen and (max-width: $breakpoint-md) {
      gap: 48px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
      margin: 0 auto;
      width: 100%;
      max-width: 300px;
    }
  }
  .feature {
    flex: 1;
  }
  .feature__img {
    width: 100%;
    margin-bottom: 24px;
  }
  .feature__text {
    font-size: rem(20);
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.04em;
    text-align: center;
    word-break: auto-phrase;
    @media screen and (max-width: $breakpoint-xl) {
      font-size: rem(16);
    }
    @media screen and (max-width: $breakpoint-md) {
      font-size: rem(14);
    }
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(16);
    }
    > span {
      display: inline-block;
    }
  }
  .bg-image-right-top-circle {
    position: absolute;
    width: 240px;
    top: -170px;
    right: -76px;
    @media screen and (max-width: $breakpoint-md) {
      width: 200px;
      top: -100px;
      right: -72px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 160px;
      top: -100px;
      right: -72px;
    }
  }
  .bg-image-right-top-cross {
    position: absolute;
    top: -12px;
    right: -76px;
    width: 152px;
    transform: rotate(-90deg);
    @media screen and (max-width: $breakpoint-md) {
      width: 112px;
      top: 12px;
      right: -36px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 76px;
      top: 12px;
      right: -36px;
    }
  }
}
