// MEMO: 命名がなんか違う気がする
.blog-list {
  &_title {
    position: relative;
    padding-left: 20px;

    &:before {
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 450px;
      height: 1px;
      background: $primary-color;
    }
  }

  &_title-wrap {
    margin: 10px 0 20px 0;
    @media screen and (max-width: 1024px) {
      padding: 0 30px;
    }
  }

  &_word-box {
    position: relative;
    display: block;
    float: left;
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    background: $primary-color;
    font-size: $small-font-size;
    margin-right: 5px;
    color: $text-color-white;
    z-index: 2;
  }
}

// TODO: BEM対応
section.blog_list_items {
  padding: 0;
  margin: 10px;
  margin-bottom: 50px;
  width: 480px;

  ul {
    position: relative;

    li {
      list-style-type: none;

      span.date {
        display: block;
        float: left;
        width: 85px;
        margin-right: 20px;
        font-size: rem(11);
      }

      span.article_title {
        display: block;
        width: 60%;
        float: right;
        font-size: $small-font-size;
        line-height: 20px;
        @media screen and (max-width: 1024px) {
          width: auto;
        }
      }

      img.feed_thumbnail {
        object-fit: cover;
        width: 15%;
        height: 15%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  section.blog_list_items {
    margin: 0;
    width: 100%;

    span.date, span.article_title {
      float: none;
    }

    ul {
      li {
        text-align: center;
        font-size: rem(12);

        img.feed_thumbnail {
          width: 80%;
          height: 80%;
        }
      }
    }
  }
}

ul.blog_list_tec {
  position: relative;
  padding-top: 0;
  top: -12px;

  li {
    padding: 20px 70px 20px 20px;
    background-image: linear-gradient(to right, $primary-color 55%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;

    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  &:after {
    position: absolute;
    right: 27px;
    bottom: 1px;
    display: block;
    content: "";
    width: 74px;
    height: 99px;
    background: url(../images/don.png) no-repeat;
  }

  @media screen and (max-width: 1024px) {
    &:after {
      display: none;
    }
  }
}


.blog_list_title02 {
  position: relative;
  padding-left: 20px;

  span {
    position: relative;
    display: block;
    float: left;
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    background: $primary-color;
    font-size: $small-font-size;
    margin-right: 5px;
    color: $text-color-white;
    z-index: 2;
  }

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 470px;
    height: 1px;
    background: $primary-color;
  }
}

@media screen and (max-width: 1024px) {
  .blog_list_title_wrap {
    padding: 0 30px;
  }
  .blog_list_title02 {
    // padding: 0 30px;
    &:before {
      width: 100%;
    }
  }
}
