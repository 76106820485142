.download-thanks {
  $_donusagi-img-width: 400px;

  &_title {
    margin-top: 50px;

    .f-24 {
      font-size: rem(24);
    }
  }

  &_section {
    text-align: center;
    margin-top: 60px;

    a {
      display: inline-block;
      margin-top: 36px;
      margin-bottom: 40px;
      padding: 16px 40px;
      border: 2px solid #00a4b4;
      border-radius: 999px;
      color: #00a4b4;
      font-weight: bold;
    }

    img {
      width: $_donusagi-img-width;
    }

    @media screen and (max-width: 1024px) {
      width: 90%;
      margin: 0 auto;

      img {
        max-width: $_donusagi-img-width;
        width: 90%;
      }
    }
  }
}
