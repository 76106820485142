.column_section {
  margin-bottom: 64px;
}

.column_container {
  max-width: 1200px;
  width: 100%;
  margin: 24px auto 64px;
}

.column_category-tab {
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
}

.column_category-tab-item {
  display: inline-block;
  width: 110px;
}