.service-flow {
  &_section {
    margin-bottom: 120px;
    position: relative;

    @media screen and (max-width: 1024px) {
      width: 88.75%;
      margin: 60px auto 0;
    }

    &--background {
      width: 62%;
      height: 93%;
      position: absolute;
      background: $neutral-white url(/images/bg_yellow.png);
      z-index: -1;
      top: 10px;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &_container {
    width: 1200px;
    margin: 50px auto 0;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &_item-wrapper {
    position: relative;
  }

  &_items {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 1330px;
    position: relative;

    @media screen and (max-width: 1024px) {
      display: block;
      height: auto;
      width: 72.5%;
      margin: 0 0 0 auto;
    }
  }

  &_items:before {
    background: radial-gradient(circle farthest-side, #00A4B4, #00A4B4 50%, transparent 50%);
    background-size: 20px 20px;
    content: '';
    display: inline-block;
    height: 1155px;
    width: 20px;
    position: absolute;
    top: 85px;
    left: 17px;

    @media screen and (max-width: 1024px) {
      top: 192px;
      left: -97px;
      background-size: 10px 10px;
      width: 10px;
      height: 85%;
    }
  }

  &_item {
    display: flex;
    justify-content: center;
    position: relative;
    width: 900px;
    height: 150px;
    border: solid $text-link-color;
    border-width: 4px 4px 4px 0;
    margin: 0 auto;
    background-color: $text-color-white;

    @media screen and (max-width: 1024px) {
      display: block;
      width: 100%;
      height: auto;
      border-width: 2px 2px 2px 2px;
    }
  }

  &_item:first-child:before, &_item:nth-last-child(2):before {
    background: radial-gradient(circle farthest-side, #00A4B4, #00A4B4 50%, transparent 50%);
    background-size: 20px 20px;
    content: '';
    display: inline-block;
    height: 20px;
    width: 40px;
    position: absolute;
    top: 65px;
    left: -130px;

    @media screen and (max-width: 1024px) {
      left: -90px;
      top: 190px;
      background-size: 10px 10px;
      height: 10px;
      width: 20px;
    }
  }

  &_item:nth-last-child(2):after {
    content: '';
    background-color: $text-color-white;
    z-index: 2;
    width: 50px;
    height: 100px;
    position: absolute;
    top: 52%;
    left: -50%;
  }

  &_title-container {
    position: absolute;
    top: -16px;
    left: -60px;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: $text-link-color;
    text-align: center;
    color: white;

    @media screen and (max-width: 1024px) {
      width: 90px;
      height: 90px;
      top: 150px;
    }
  }

  &_item:first-child > &_title-container:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-left: 15px solid $primary-color;
    left: -23px;
    top: 75px;

    @media screen and (max-width: 1024px) {
      border: 10px solid transparent;
      border-left: 10px solid $primary-color;
      left: -10px;
      top: 35px;
    }
  }

  &_item:nth-last-child(2) > &_title-container:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-right: 15px solid $primary-color;
    left: -40px;
    top: 75px;

    @media screen and (max-width: 1024px) {
      left: -30px;
      top: 30px;
    }
  }

  &_title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 66%;
    padding-top: 30px;

    @media screen and (max-width: 1024px) {
      padding-top: 0px;
      position: relative;
      height: 100%;
    }
  }

  &_title-headline {
    font-size: rem(28);
    font-weight: normal;
    white-space: pre;
    line-height: 1.2;

    @media screen and (max-width: 1024px) {
      font-size: rem(20);
      font-weight: bold;
      line-height: 1;
    }
  }

  &_title-subtext {
    font-size: rem(12);

    @media screen and (max-width: 1024px) {
      font-size: rem(10);
      font-family: Lato;
      -webkit-transform:scale(0.5);
      -moz-transform:scale(0.5);
      -ms-transform:scale(0.5);
      -o-transform:scale(0.5);
      transform:scale(0.5);
      line-height: 1;
    }
  }

  &_summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
    margin: 30px 20px 30px 130px;
    text-align: center;

    @media screen and (max-width: 1024px) {
      width: 100%;
      margin: 20px auto 0;
      display: block;
      font-size: $small-font-size;
      font-weight: bold;
    }

    &:after {
      display: none;
      @media screen and (max-width: 1024px) {
        content:"";
        display: block;
        width: 40%;
        height: 0;
        border: 1px solid $text-link-color;
        margin: 0 auto;
        position: relative;
        top: 10px;
      }
    }
  }

  &_summary-paragraph {
    width: 300px;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &_summary-text {
    background: linear-gradient(transparent 60%, #ff6 60%);
    color: $text-link-color;
    font-size: rem(18);
  }

  &_description {
    margin: 30px 130px 30px 0;
    padding-left: 30px;
    border-left: solid 3px $text-link-color;
    position: relative;

    .service-flow_point--content {
      font-size: rem(15);
    }

    @media screen and (max-width: 1024px) {
      border-left: none;
      width: 68%;
      display: block;
      margin: 25px 9.71% 16px 22.3%;
      padding-left: 0;
    }
  }

  &_point {
    font-size: rem(20);
    color: $primary-color;
    margin-bottom: 8px;
  }

  &_image {
    position: absolute;
    top: -35px;
    right: -160px;
    width: 280px;
    object-fit: contain;

    @media screen and (max-width: 1024px) {
      position: static;
      width: 70.4%;
      margin: 0 auto 25px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &_arrow-container {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1024px) {
      position: static;
      width: 70.4%;
      margin: 0 auto 25px;
    }
  }


  &_arrow-container:nth-last-child(1) {
    display: none;
  }

  &_arrow {
    position: relative;
    top: -33px;
    display: inline-block;
    height: 40px;
    width: 60px;
    background-color: $primary-color;

    @media screen and (max-width: 1024px) {
      top: 0;
      height: 25px;
      width: 25px;
    }

    &:before {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border: 60px solid transparent;
      border-top: 60px solid $primary-color;
      left: -28px;
      top: 33px;

      @media screen and (max-width: 1024px) {
        border: 25px solid transparent;
        border-top: 25px solid $primary-color;
        position: relative;
        left: -12px;
      }
    }
  }

  &_message {
    writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    position: absolute;
    top: 0px;
    left: -30px;
    height: 100%;
    font-weight: bold;
    font-size: rem(24);
    display: inline-block;
    text-align: center;

    @media screen and (max-width: 1024px) {
      position: static;
      writing-mode: horizontal-tb;
      -ms-writing-mode: horizontal-tb;
      margin-top: 30px;
      font-size: rem(15);
    }
  }

  &_message--colored {
    color: #049AAA;
  }

  &_interview {
    width: 100%;
    border: 1px dotted #00A4B4;
    margin-top: 160px;
    display: flex;
    justify-content: space-between;
    background-color: $text-color-white;

    @media screen and (max-width: 1024px) {
      margin-top: 25px;
      display: block;
    }
  }

  &_interview--icon {
    -webkit-mask-image: url('/images/service/interview_mask.png');
    mask-image: url('/images/service/interview_mask.png');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;

    @media screen and (max-width: 1024px) {
      -webkit-mask-image: url('/images/service/interview_mask_sp.png');
      mask-image: url('/images/service/interview_mask_sp.png');
      width: 100%;
      -webkit-mask-position: top 0 left 60%;
      mask-position: top 0 left 60%;
      text-align: center;
    }

    img {
      width: 281px;
      height: 281px;

      @media screen and (max-width: 1024px) {
        width: 284px;
        height: 200px;
        object-fit: cover;
        object-position: 50% 0;
      }
    }
  }

  &_interview--content {
    margin: 35px 40px 35px 0px;

    @media screen and (max-width: 1024px) {
      margin: 25px;
    }
  }

  &_interview--name {
    font-size: $base-font-size;
    font-weight: bold;
    color: #00A4B4;
    margin-bottom: 35px;

    @media screen and (max-width: 1024px) {
      font-size: rem(12);
      margin-bottom: 15px;
    }
  }

  &_interview--title {
    font-size: rem(28);
    font-weight: bold;
    color: #00A4B4;
    margin-bottom: 35px;

    @media screen and (max-width: 1024px) {
      font-size: $base-font-size;
      margin-bottom: 20px;
    }
  }
}
