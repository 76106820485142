//新ボタン
//横幅は基本最小になる
//.btn-blockをつければ幅100%になる
//その他のサイズにしたい場合は使用箇所で都度規定
.btn {
  align-items: center;
  border-radius: $border-radius-s;
  display: flex;
  gap: 8px;
  line-height: 1.5;
  justify-content: center;
  margin: 0;
  padding: 12px 20px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  font-size: rem(14);
  font-weight: 700;
  text-decoration: none;
  box-sizing: border-box;
  transition: background-color 0.1s;
  > i {
    //アイコンがあればそのサイズを規定
    font-size: rem(16); //デフォルトで16px角
    margin-top: rem(1); //文字との高さ合わせ (Noto Sans JPは字面がセンターにないため)
  }
  &:hover {
    text-decoration: none;
  }
  &.btn-primary {
    border-width: 1px;
    border-style: solid;
    border-color: $primary-color;
    background: $primary-color;
    color: $text-color-white;
    &:hover {
      background: $mofmof-blue-800;
    }
  }
  &.btn-white {
    border-width: 1px;
    border-style: solid;
    border-color: $neutral-white;
    background: $neutral-white;
    color: $primary-color;
    &:hover {
      background: $neutral-solid-50;
    }
  }
  &.btn-outline-primary {
    border-width: 1px;
    border-style: solid;
    font-weight: 500;
    background: $neutral-white;
    border-color: $primary-color;
    color: $primary-color;
    &:hover {
      background: $neutral-solid-50;
    }
  }
  &.btn-outline-white {
    border-width: 1px;
    border-style: solid;
    font-weight: 500;
    background: transparent;
    border-color: $neutral-white;
    color: $neutral-white;
    &:hover {
      background: $neutral-opacity-50;
    }
  }
  &.btn-lg {
    font-size: rem(18);
    padding: 16px 24px;
    > i {
      font-size: rem(20);
    }
  }
  &.btn-var-lg { //スマホ時はmdに戻るlg
    font-size: rem(18);
    padding: 16px 24px;
    > i {
      font-size: rem(20);
    }
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(14);
      padding: 12px 20px;
      > i {
        font-size: rem(16);
      }
    }
  }
  &.btn-sm {
    padding: 8px 12px;
  }
  &.btn-rounded {
    border-radius: 100px;
  }
  &.btn-block {
    width: 100%;
  }
}

//旧ボタン
%btn-base {
  align-items: center;
  border: none;
  border-radius: $border-radius-s;
  display: flex;
  line-height: 1;
  justify-content: center;
  margin: 0;
  padding: 1em 5em;
  position: relative;
  text-align: center;
  vertical-align: middle;
}
%arrow-forward-green {
  &::after {
    $icon-height: 18px;
    display: block;
    content: '';
    position: absolute;
    top: calc(50% - #{$icon-height} * 0.5);
    right: 26px;
    width: 11px;
    height: $icon-height;
    background: url(/images/arrow.svg) no-repeat center;
    background-size: contain;
  }
}
.button {
  @extend %btn-base;

  color: $primary-color;
  font-family: inherit;
  font-size: 0.9rem;

  background-color: $neutral-white;
  border: $primary-color solid 2px;
  border-radius: 0;
  filter: drop-shadow(2.3px 1.9px 3px lightgray);

  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
  -webkit-appearance: none;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    padding: 1em 1em;
    width: 224px;
  }

  &:hover {
    background: $text-link-color;
    color: $text-color-white;
    text-decoration: none;

    @media screen and (max-width: 1024px) {
      background: transparent;
      color: $primary-color;
    }
  }
}

.button--rectangle {
  @extend .button;
  background: $neutral-white;
  border: solid 1px $primary-color;
  color: $primary-color;
  display: block;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  padding: 0 26px;
  text-align: left;
  width: 100%;
  text-decoration: none;

  @extend %arrow-forward-green;

  &.more {
    width: 400px;
  }

  &:hover {
    color: $text-color-white;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    font-size: $small-font-size;
    padding: 10px 20px 10px 20px;
    border: $primary-color solid 1px;
    width: 100%;
    line-height: inherit;

    &.more {
      width: 100%;
      margin-top: 30px;
    }
  }
}

.button--short {
  padding: 1.5em 1em;
}

.button--no-deco {
  border: none;
  filter: none;
}

.button_primary {
  text-align: left;
  background: $secondary-color;
  color: $text-link-color;
  position: relative;
  padding-right: 40px;

  &::after {
    position: absolute;
    display: block;
    content: '';
    height: 100%;
    width: 9px;
    top: 0;
    right: 20px;
    background: url(../images/arrow.svg) no-repeat center;
    background-size: contain;
  }

  &:hover {
    background: $text-link-color;
    color: $text-color-white;

    &::after {
      background: url(../images/arrow_white.svg) no-repeat center;
      background-size: contain;
    }

    @media screen and (max-width: 1024px) {
      background: transparent;
      color: $primary-color;
    }
  }
}

.button_entry {
  position: relative;
  display: block;
  width: 250px;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  padding: 0 26px;
  background: transparent;
  text-align: left;
  border: solid 1px $primary-color;
  color: $text-link-color;
  @media screen and (max-width: 1024px) {
    width: 200px;
    height: 40px;
    line-height: 40px;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 26px;
    width: 11px;
    height: 60px;
    background: url(/images/arrow.svg) no-repeat center;
    background-size: contain;
    @media screen and (max-width: 1024px) {
      height: 40px;
      width: 11px;
    }
  }

  &#wantedly:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 26px;
    width: 11px;
    height: 60px;
    background: url(/images/new_window_icon.svg) no-repeat center;
    background-size: contain;
    @media screen and (max-width: 1024px) {
      height: 40px;
      width: 11px;
    }
  }
}

.social-buttons {
  margin-top: 10px;
}
