// MEMO: クライアントインタビュー 前の記事、次の記事
.interview-related {
  padding-left: 30px;
  padding-right: 30px;
  .more_link {
    width: 160px;
    height: 36px;
    padding-top: 80px;
    padding-bottom: 180px;
  }
  .more_link .button_more {
    text-align: center;
    line-height: 36px;
    span {
      position: static;
    }
    &:after {
      content: none;
    }
  }
  @media screen and (max-width: 1024px) {
    .more_link {
      width: 160px;
      height: 36px;
      padding-top: 80px;
      padding-bottom: 180px;
    }
    .more_link .button_more {
      font-size: rem(12);
      background: #888;
      color: $text-color-white;
      border: none;
    }
  }
}

.interview-related_wrap {
  content: "";
  display: block;
  clear: both;
}

.interview-related_post {
  width: 230px;
  position: relative;
  font-size: $small-font-size;
  &:first-of-type {
    float: left;
  }
  &:nth-of-type(2) {
    float: right;
  }
  &_wrap {
    content: "";
    display: block;
    clear: both;
  }
  &_prev,
  &_next {
    position: relative;
    display: block;
    width: 17px;
    margin-top: 90px;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8.5px 14.7px 8.5px 0;
      border-color: transparent #888 transparent transparent;
    }
  }
  &_prev {
    float: left;
    & + .interview-related_post_content {
      margin-left: 46px;
    }
  }
  &_next {
    float: right;
    &::after {
      border-width: 8.5px 0 8.5px 14.7px;
      border-color: transparent transparent transparent #888;
    }
  }
  &_content {
    width: 184px;
  }
  &_link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &:hover + div {
      .interview-related_post_title {
        color: $primary-color;
      }
      .interview-related_post_comment {
        color: $primary-color;
        text-decoration: underline;
      }
      .interview-related_post_prev::after {
        border-color: transparent $primary-color transparent transparent;
      }
      .interview-related_post_next::after {
        border-color: transparent transparent transparent $primary-color;
      }
    }
  }
  &_image {
    width: 100%;
    height: 138px;
    margin-top: 8px;
    margin-bottom: 16px;
    background-size: cover;
    background-position: center;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    box-sizing: border-box;
    &:first-of-type,
    &:nth-of-type(2) {
      float: inherit;
    }
    &:first-of-type {
      margin-bottom: 40px;
    }
    &_prev,
    &_next {
      width: 14px;
      margin-top: 5px;
      &::after {
        border-width: 7px 12.1px 7px 0;
      }
      & + .interview-related_post_content {
        margin-left: 26px;
      }
    }
    &_next {
      float: left;
      &::after {
        border-width: 7px 0 7px 12.1px;
      }
    }
    &_content {
      width: inherit;
    }
    &_image {
      width: 136px;
      height: 102px;
    }
  }
}
