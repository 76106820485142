// homeのinterviewセクション
.home-interview {
  background-color: $neutral-white;
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;

  .home-interview_container {
    @extend .container-xl;
    position: relative;
    padding: 0 24px;
    z-index: 1;
  }
  .home-interview__cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 80px 40px;
    @media screen and (max-width: $breakpoint-pc) {
      grid-template-columns: 1fr;
      gap: 56px;
    }
    margin-bottom: 80px;
  }
  .home-interview__btn-group {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 48px;
    @media screen and (max-width: $breakpoint-tablet) {
      > .btn {
        width: 100%;
        max-width: 400px;
      }
    }
  }
  .bg-image-right-top-dot {
    position: absolute;
    width: 290px;
    top: 40px;
    right: -140px;
    @media screen and (max-width: $breakpoint-xl) {
      width: 240px;
      top: 48px;
      right: -100px;
    }
    @media screen and (max-width: $breakpoint-pc) {
      width: 200px;
      top: 40px;
      right: -80px;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 172px;
      top: 52px;
      right: -60px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 145px;
      top: 28px;
      right: -56px;
    }
  }
  .bg-image-right-top-circle {
    position: absolute;
    width: 400px;
    top: -332px;
    right: -186px;
    @media screen and (max-width: $breakpoint-xl) {
      width: 360px;
      top: -280px;
      right: -160px;
    }
    @media screen and (max-width: $breakpoint-pc) {
      width: 320px;
      top: -240px;
      right: -160px;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 240px;
      top: -160px;
      right: -120px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 200px;
      top: -152px;
      right: -108px;
    }
  }
  .bg-image-right-top-illust {
    position: absolute;
    width: 360px;
    top: -160px;
    right: 32px;
    @media screen and (max-width: $breakpoint-xl) {
      width: 300px;
      top: -120px;
      right: 32px;
    }
    @media screen and (max-width: $breakpoint-pc) {
      width: 240px;
      top: -100px;
      right: 24px;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 200px;
      top: -64px;
      right: 16px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 160px;
      top: -64px;
      right: 12px;
    }
  }
}
