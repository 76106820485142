.comment {
  &_member {
    float: left;
    width: 22%;
    text-align: center;

    @media screen and (max-width: 1024px) {
      width: 100%;
      float: none;
    }
  }

  &_img {
    width: 100%;
    @media screen and (max-width: 1024px) {
      max-width: 200px;
    }
  }

  &_content {
    float: right;
    width: 68%;
    margin-left: 15px;
    min-height: 130px;
    @media screen and (max-width: 1024px) {
      width: 80%;
      margin: 0 auto;
      float: none;
    }
  }
}
