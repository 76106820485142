.toc {
  padding: 24px 20px 8px;
  margin-bottom: 32px;
  background-color: $text-color-white;
  border: 3px solid #ccc;

  h4 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 16px;
    padding-left: 16px;
    border-bottom: 2px dotted #ccc;
    font-size: 1.125rem;

    &::before {
      content: '';
      background-image: url('/images/toc.svg');
      display: inline-block;
      height: 15px;
      width: 16px;
      margin-right: 10px;
    }
  }

  ol {
    padding-left: 42px;
    color: #4498a7;

    a {
      color: $text-color;
      text-decoration: none;
    }

    li {
      margin-top: 8px;
      margin-bottom: 8px;
      font-weight: bold;
    }

    ol {
      margin-top: 8px;
      padding-bottom: 0;
      padding-left: 24px;
    }

    ol li {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    ol li,
    ol li a {
      font-weight: normal;
    }
  }
}
