.development-team-rental, .service-faq {
  .service-footer {
    background-color: $mofmof-blue-900;
    margin: 0;
    padding: 80px 0 0 0;
    .service-footer__container {
      max-width: 1200px;
      margin: 0 auto;
      padding-left: 6%;
      padding-right: 6%;
      box-sizing: content-box;
    }
    .service-footer__top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 960px) {
        flex-direction: column;
        align-items: start;
        gap: 16px;
      }
    }
    .service-footer__divider {
      border-top: 1px solid $neutral-white;
      opacity: 0.1;
      margin: 16px 0;
    }
    .service-footer__middle {
      width: 100%;
      padding: 16px 0 48px 0;
    }
    .service-footer__bottom {
      width: 100%;
      padding: 24px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 560px) {
        flex-direction: column;
        gap: 16px;
      }
    }

    .service-footer-logo {
      .service-footer-logo__img {
        width: 392px;
        @media screen and (max-width: 560px) {
          width: calc(min(392px, 100%));
        }
      }
    }

    .service-footer-action-links {
      display: flex;
      gap: 32px;
      .service-footer-action-links__item {
        font-size: rem(14);
        font-weight: 700;
        color: $neutral-white;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .service-footer-page-links {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: repeat(3, 1fr);
      justify-content: start;
      column-gap: 48px;
      row-gap: 16px;
      @media screen and (max-width: 560px) {
        grid-template-rows: repeat(4, 1fr);
      }
      @media screen and (max-width: 400px) {
        grid-template-rows: repeat(8, 1fr);
        padding-left: 16px;
      }
      .service-footer-page-links__item {
        font-size: rem(14);
        line-height: rem(24);
        font-weight: 700;
        color: $neutral-white;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .service-footer-corp-links {
      display: flex;
      align-items: center;
      list-style-type: none;
      .service-footer-corp-links__item {
        display: flex;
        align-items: center;
        &:not(:last-child):after {
          margin: 0 16px;
          content: '';
          height: rem(12);
          border-right: 1px solid $mofmof-blue-200;
        }
      }
      .service-footer-corp-links__link {
        font-size: rem(12);
        font-weight: 700;
        color: $mofmof-blue-200;
        text-decoration: none;
        @include vertical-trim-JP;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .service-footer-copyright {
      font-size: rem(12);
      line-height: 175%;
      letter-spacing: 0.05rem;
      color: $neutral-white;
    }
  }
}
