.service-faq {
  .main {
    position: relative;
    overflow: hidden;
    height: 312px;
    margin: 0;
    background: url("/images/service/dev-team-rental/startup/keyvis/bg_green.svg") no-repeat center;
    background-size: cover;

    .header {
      align-items: center;
      background: $neutral-white;
      box-sizing: border-box;
      display: flex;
      gap: 16px;
      height: 66px;
      justify-content: space-between;
      left: 0;
      padding: 0px 32px;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: $z-index-header;
      @media screen and (max-width: 560px) {
        position: fixed;
        padding: 0px 24px;
      }
      .header__logo {
        .header__logo--img {
          width: 256px;
          max-width: 100%;
          object-fit: contain;
          display: block;
        }
      }
      .sp-menu {
        // NOTE: sp版headerのアイコンは、faqページの時は緑色のものが出る
        // 詳細度をあげて緑色のものを出しているので、セレクタの変更は注意して行なってほしい
        .sp-menu__box {
          img.menu-icon {
            &.menu-icon-white {
              display: none;
            }
            &.menu-icon-primary {
              display: block;
            }
          }
        }
      }
      .main__header__right {
        display: flex;
        align-items: center;
        column-gap: 20px;
        @media screen and (max-width: 560px) {
          display: none;
        }
        .main__header__right__nav {
          display: flex;
          column-gap: 20px;
          list-style-type: none;
          .main__header__right__nav--item {
            .main__header__right__nav--item-link {
              font-size: rem(14);
              font-weight: 700;
              color: $mofmof-blue-600;
              text-decoration: none;
            }
          }
        }
        .main__header__right__nav--download-button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          font-size: rem(14);
          font-weight: 700;
          color: $neutral-white;
          text-decoration: none;
          padding: 0px 22px;
          background: $mofmof-blue-600;
          border-radius: 24px;
          cursor: pointer;
          @media screen and (max-width: 960px) {
            display: none;
          }
        }
        .main__header__right__nav--download-button:hover {
          background: $mofmof-blue-700;
          transition: 0.2s;
        }
        .main__header__right__nav--contact-button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          font-size: rem(14);
          font-weight: 700;
          color: $neutral-solid-700;
          text-decoration: none;
          padding: 0px 22px;
          background: $yellow-300;
          border-radius: 24px;
          cursor: pointer;
          @media screen and (max-width: 960px) {
            display: none;
          }
        }
        .main__header__right__nav--contact-button:hover {
          background: $yellow-500;
          transition: 0.2s;
        }
      }
    }
    .page-title {
      position: absolute;
      top: 130px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      .page-title__top {
        font-size: rem(16);
        font-weight: 700;
        line-height: 150%;
        letter-spacing: 0.02rem;
        color: $mofmof-blue-100;
        @media screen and (max-width: 560px) {
          font-size: rem(14);
        }
      }
      .page-title__bottom {
        font-size: rem(32);
        font-weight: 700;
        line-height: 175%;
        letter-spacing: 0.01rem;
        color: $neutral-white;
        border-bottom: solid 2px $neutral-white;
        white-space: nowrap;
      }
    }
  }

  .faq {
    position: relative;
    margin: 0;
    background: $neutral-solid-50;
    .faq__splitter-image {
      position: absolute;
      top: calc(100% - 3px);
      left: 0;
      width: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 1;
    }
    .faq__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .faq__content__menu {
        display: grid;
        grid-template-columns: repeat(4, auto);
        column-gap: 32px;
        row-gap: 16px;
        max-width: 960px;
        padding: 64px 0px;
        box-sizing: border-box;
        @media screen and (max-width: 740px) {
          grid-template-columns: repeat(2, auto);
        }
        @media screen and (max-width: 560px) {
          grid-template-columns: repeat(1, auto);
          width: 100%;
          padding: 40px 16px;
        }
        .faq__content__menu__item {
          padding: 0px 8px;
          list-style: none;
          .faq__content__menu__item__link {
            display: flex;
            column-gap: 4px;
            font-size: rem(14);
            font-weight: 700;
            color: $neutral-solid-600;
            text-decoration: none;
          }
        }
      }
      .faq__content__faq-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 80px;
        width: 100%;
        max-width: 960px;
        padding: 0px 16px 80px;
        box-sizing: border-box;
        .faq__content__faq-list__block {
          display: flex;
          flex-direction: column;
          row-gap: 24px;
          width: 100%;
          .faq__content__faq-list__block__title {
            font-size: rem(20);
            font-weight: 700;
            line-height: 150%;
            letter-spacing: 0.02rem;
            padding: 0px 8px;
            color: $neutral-solid-700;
          }
          .faq__content__faq-list__block__content {
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            width: 100%;
            .faq__content__faq-list__block__content__item {
              background: $neutral-white;
              border-radius: 12px;
              box-shadow: $shadow-card;
              list-style: none;
              .faq__content__faq-list__block__content__item__question {
                position: relative;
                display: flex;
                column-gap: 16px;
                padding: 16px 20px;
                z-index: 1;
                caret-color: transparent;
                cursor: pointer;
                .faq__content__faq-list__block__content__item__question--q {
                  font-family: 'Barlow';
                  font-size: rem(28);
                  font-weight: 700;
                  color: $mofmof-blue-600;
                  line-height: 0.92;
                }
                .faq__content__faq-list__block__content__item__question--text {
                  font-weight: 700;
                  line-height: 175%;
                  letter-spacing: 0.02rem;
                  color: $neutral-solid-700;
                  width: 100%;
                }
                .faq__content__faq-list__block__content__item__question--down-icon {
                  display: block;
                }
                .faq__content__faq-list__block__content__item__question--up-icon {
                  display: none;
                }
              }
              .faq__content__faq-list__block__content__item__answer {
                display: none;
                column-gap: 16px;
                padding: 16px 20px;
                background: $neutral-solid-30;
                border-radius: 0px 0px 12px 12px;
                .faq__content__faq-list__block__content__item__answer--a {
                  font-family: 'Barlow';
                  font-size: rem(28);
                  font-weight: 700;
                  color: $mofmof-blue-600;
                  line-height: 0.92;
                }
                .faq__content__faq-list__block__content__item__answer--text {
                  line-height: 175%;
                  letter-spacing: 0.01rem;
                  color: $neutral-solid-700;
                  width: 100%;
                }
              }
            }
            .faq__content__faq-list__block__content__item.active {
              .faq__content__faq-list__block__content__item__question {
                box-shadow: $shadow-card;
                .faq__content__faq-list__block__content__item__question--down-icon {
                  display: none;
                }
                .faq__content__faq-list__block__content__item__question--up-icon {
                  display: block;
                }
              }
              .faq__content__faq-list__block__content__item__answer {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  .text-link:hover {
    opacity: 0.8;
  }

  .link {
    color: $mofmof-blue-600;
    text-decoration: none;
    .link--icon {
      padding: 6px 0px;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 560px) {
      row-gap: 24px;
    }
    .list__item {
      display: flex;
      @media screen and (max-width: 560px) {
        flex-direction: column;
      }
      .list__item__left {
        flex: 0 0 180px;
        @media screen and (max-width: 560px) {
          flex: 0 0 content;
        }
      }
    }
  }

  .sp-only {
    display: none;
    @media screen and (max-width: 560px) {
      display: block;
    }
  }
}
