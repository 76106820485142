.about {
  .main_visual {
    .main_img {
      background-repeat: none;
      background-position: center;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }

  &_01 {
    position: relative;
    width: 1000px;
    height: 450px;
    margin-bottom: 100px;
    background-repeat: no-repeat;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: auto;
    }
  }

  &_01.hover {
    background: url(/images/about/about_harada_on.png) no-repeat;

  }

  &_message {
    font-size: $small-font-size;
    color: $text-color;
    float: right;
    width: 580px;
    height: 450px;
    padding-right: 60px;
    padding-top: 60px;

    p {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 1024px) {
      float: none;
      width: 100%;
      height: auto;
      padding: 40px 0 0;

      p {
        padding: 0 30px;
      }
    }
  }

  &_meta {
    position: relative;
    width: 360px;
    height: 450px;
    float: left;

    @media screen and (max-width: 1024px) {
      float: none;
      width: 100%;
    }

    span {
      margin-right: 10px;

      @media screen and (max-width: 1024px) {
        margin: 1px;
      }

      img {
        width: auto;
      }
    }
  }

  &_name {
    position: absolute;
    bottom: 0;
    padding: 30px;

    @media screen and (max-width: 1024px) {
      bottom: auto;
      right: 0;
      text-align: center;
    }
  }

  &_name-jp {
    font-size: rem(20);
    line-height: 3;

    @media screen and (max-width: 1024px) {
      font-size: rem(28);
      line-height: 1.6;
    }
  }

  &_job-title {
    font-size: rem(10);
  }

  &_name-en {
    font-size: rem(10);
    margin-bottom: 20px;
  }
}

#map {
  width: 100%;
  height: 450px;
  margin-bottom: 150px;
}
