.request-flow_heading {
  width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.request-flow_main-img {
  overflow: hidden;
  width: 100%;
  height: 300px;
  position: relative;

  @media screen and (max-width: 1024px) {
    height: 160px;
  }

  img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.request-flow_section {
  position: relative;
}

.request-flow_container {
  position: relative;
  width: 1160px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.request-flow_container::after {
  content: '';
  width: 0;
  height: 75%;
  position: absolute;
  border: 1px solid #049AAA;
  top: 0;
  left: 579px;
  @media screen and (max-width: 1024px) {
    height: 62%;
    top: 850px;
    left: 50%;
  }
}

.request-flow_overview {
  filter: drop-shadow(11.18px 16.58px 35px lightgray);
  background-color: white;
  width: 960px;
  margin: 0 auto;
  padding: 60px 0 60px 0;
  position: relative;
  top: -45px;
  z-index: 2;

  @media screen and (max-width: 1024px) {
    width: 85%;
    margin: 0 auto;
  }
}

.request-flow_overview-headline {
  color: $primary-color;
  font-size: 2rem;
  text-align: center;
  width: 700px;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0 auto;
    line-height: 2rem;
  }
}

.request-flow_overview-headline--sub {
  display: block;
  font-size: 1rem;
  margin: 20px 0;
  color: $primary-color;
}

.request-flow_headline--block {
  display: block;

  @media screen and (max-width: 1024px) {
    display: inline;
  }
}

.request-flow_overview-button {
  position: absolute;
  top: 375px;
  right: 50px;

  @media screen and (max-width: 1024px) {
    position: relative;
    top: 80px;
    right: 0;
    width: 83.3%;
    margin: 0 auto;
    text-align: center;
  }
}

.request-flow_spacer {
  color: $primary-color;
  font-size: 2rem;
  margin: 20px 0;
  text-align: center;
}

.request-flow_overview-desc {
  width: 700px;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    width: 83.3%;
  }
}

.request-flow_grobal-headline {
  font-size: rem(160);
  margin-bottom: 100px;
  width: 50%;
  margin: 100px 0 0 auto;
  color: $primary-color;
  text-align: center;
  position: relative;
  top: 100px;

  @media screen and (max-width: 1024px) {
    font-size: rem(80);
    margin: 100px auto;
    width: 100%;
    z-index: 3;
    position: static;
  }
}

.request-flow_grobal-headline.last-headline {
  position: relative;
  top: -350px;
  @media screen and (max-width: 1024px) {
    top: 0px;
    width: 90%;
    margin: 50px 0 100px auto;
    text-align: right;
  }
}

.request-flow_items {
  width: 100%;
  position: relative;
  top: -50px;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 300px;

  @media screen and (max-width: 1024px) {
    position: static;
    top: 0;
    margin-top: 0;
  }
}

.request-flow_item {
  filter: drop-shadow(11.18px 16.58px 35px lightgray);
  background-color: white;
  width: 520px;
  margin-bottom: 120px;

  @media screen and (max-width: 1024px) {
    width: 90%;
    margin: 0 0 40px auto;
    z-index: 3;
  }
}

.request-flow_item::after {
  content: '';
  width: 0;
  height: 100px;
  position: absolute;
  border: 1px solid #049AAA;
  top: 200px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.request-flow_circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #049AAA;
  position: absolute;
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.request-flow_circle.circle-left {
  right: -65px;
  top: 295px;
}

.request-flow_circle.circle-right {
  left: -65px;
  top: 298px;
}

.request-flow_item:nth-child(2n+1)::after {
  right: -32px;
  transform: skewX(-150deg);
}

.request-flow_item:nth-child(2n)::after {
  left: -30px;
  transform: skewX(150deg);
}

.request-flow_item:nth-child(2n+1) {
  position: relative;
  top: -300px;

  @media screen and (max-width: 1024px) {
    position: static;
    margin: auto 0 40px 0;
  }
}

.request-flow_item:nth-child(2n) {
  position: relative;
  top: 70px;

  @media screen and (max-width: 1024px) {
    position: static;
  }
}

.request-flow_item-image {
  height: 336px;
  width: 430px;
  z-index: 2;
  position: absolute;
  object-fit: contain;

  @media screen and (max-width: 1024px) {
    width: 70%;
    height: auto;
  }
}

.request-flow_item-image.img-left {
  top: -50px;
  left: -120px;
  @media screen and (max-width: 1024px) {
    top: -10px;
    left: 0px;
  }
}

.request-flow_item-image.img-right {
  top: -50px;
  right: -120px;
  @media screen and (max-width: 1024px) {
    top: -10px;
    right: 0px;
  }
}

.request-flow_item-image.img-last {
  top: -250px;
  left: 50px;
  @media screen and (max-width: 1024px) {
    position: relative;
    top: 170px;
    left: 0;
  }
}

.request-flow_item-number {
  position: absolute;
  top: 50px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: rem(58);
  color: white;
  background-color: $primary-color;
  border-radius: 50%;
  text-align: center;
  @media screen and (max-width: 1024px) {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: rem(32);
    top: 80px;
  }
}

.request-flow_item-number.number-1 {
  right: 70px;
  @media screen and (max-width: 1024px) {
    right: 25px;
  }
}

.request-flow_item-number.number-2 {
  left: 50px;
  @media screen and (max-width: 1024px) {
    left: 25px;
  }
}

.request-flow_item-number.number-3 {
  right: 70px;
  @media screen and (max-width: 1024px) {
    right: 25px;
  }
}

.request-flow_item-number.number-4 {
  left: 110px;
  @media screen and (max-width: 1024px) {
    left: 25px;
  }
}

.request-flow_item-number.number-5 {
  top: -140px;
  right: 530px;
  @media screen and (max-width: 1024px) {
    position: relative;
    top: -180px;
    left: 0;
    margin: 0 auto;
  }
}

.request-flow_item-headline {
  color: $primary-color;
  font-size: 2rem;
  text-align: center;
  position: absolute;
  top: 150px;
  @media screen and (max-width: 1024px) {
    position: static;
    margin-top: 50%;
  }
}

.request-flow_item-headline.headline-left {
  right: 50px;
  @media screen and (max-width: 1024px) {
    right: 0;
  }
}

.request-flow_align-right {
  @extend .request-flow_item-headline;
  text-align: right;

  @media screen and (max-width: 1024px) {
    text-align: center;
  }
}

.request-flow_slash {
  @media screen and (max-width: 1024px) {
    &::after {
      content: "/";
    }
  }
}

.request-flow_item-headline.headline-right {
  left: 50px;
  @media screen and (max-width: 1024px) {
    left: 0;
  }
}

.request-flow_item-headline.headline-last {
  top: -40px;
  right: 510px;
  @media screen and (max-width: 1024px) {
    margin-top: 0;
    position: relative;
    top: -180px;
    right: 0;
  }
}

.request-flow_item-headline--sub {
  display: block;
  font-size: 1rem;
  margin: 10px 0;
  color: $primary-color;
}

.request-flow_item-descs {
  padding: 40px;
  margin-top: 280px;
  @media screen and (max-width: 1024px) {
    margin-top: 0;
    padding: 25px;
  }
}

.request-flow_item-desc {
  margin-top: 30px;
}

.request-flow_item-descs.last-descs {
  margin-top: 400px;

  @media screen and (max-width: 1024px) {
    margin-top: 0;
    padding: 20px 0 0;
    text-align: left;
  }
}

.request-flow_item-last {
  width: 100%;
  position: relative;
  top: -300px;

  @media screen and (max-width: 1024px) {
    top: 0px;
    width: 90%;
    margin: 50px auto 0;
  }
}

.request-flow_item-last-content {
  background-color: white;
  width: 600px;
  text-align: center;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.request-flow_link {
  color: $primary-color;
  text-decoration: none;
  font-weight: bold;
}
