
.privacy_container {
  margin: 0 auto;
}

.privacy_list>* {
  list-style: none;
}

.privacy_title {
  font-size: rem(30);
  font-weight: bold;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 32px;
  line-height: 1.5;
  letter-spacing: rem(0.06);

  @media screen and (max-width: $breakpoint-pc) {
    line-height: 1.5;
    font-size: rem(24);
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }
}

.privacy_subtitle {
  text-align: inherit;
  font-size: rem(20);
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 5px;
}

.privacy_section {
  box-sizing: border-box;
  margin: 0 auto 32px;
  padding: 24px;
  text-align: left;
  width: $breakpoint-pc - 32px;
  background-color: $neutral-white;
  border-radius: $border-radius-m;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10), 0px 2px 12px 2px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: $breakpoint-pc) {
    margin: 0 16px 32px;
    width: auto;
  }
  @media screen and (max-width: $breakpoint-tablet) {
    padding: 16px;
  }
}