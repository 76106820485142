// container 関連 ----------------------------
// NOTE: 旧コンテナ 整理予定
.container {
  width: 1200px;
  margin: 0 auto;
  @media screen and (max-width: $breakpoint-xl) {
    width: 100%;
  }
}

.container01 {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}

.container02 {
  width: 1000px;
  margin: 0 auto;
}

.container03 {
  width: 800px;
  margin: 0 auto;
}

.container04 {
  width: 900px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .container,
  .container01,
  .container02,
  .container03,
  .container04 {
    width: 100%;
  }
}
// 旧コンテナここまで

.container-xl {
  max-width: $breakpoint-xl; //1200px
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}
.container-pc {
  max-width: $breakpoint-pc; //992px
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}
.container-md {
  max-width: $breakpoint-md; //768px
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}
.container-tablet {
  max-width: $breakpoint-tablet; //576px
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.container-pd {
  padding-right: 24px;
  padding-left: 24px;
  @media screen and (max-width: $breakpoint-tablet) {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.container-pd-const {
  padding-right: 24px;
  padding-left: 24px;
}

// section 関連 -----------------------------------
section {
  margin-bottom: 150px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 50px;
  }
}

section.section_bg_center {
  position: relative;

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 1%;
    left: 11%;
    width: 78.5%;
    height: 98%;
    background: $background-color-old;
    z-index: -1;
  }
}

section.section_bg_all {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 70px;

  &:before {
    position: absolute;
    content: "";
    top: 30px;
    right: 0;
    width: 1000%;
    height: 100%;
    background: $background-color-old;
    z-index: -1;
    @media screen and (max-width: 1024px) {
      top: 23px;
    }
  }
}

.text_area {
  width: 791px;
  margin: 0 auto;
  margin-bottom: 150px;

  p {
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 1024px) {
  .text_area {
    margin-bottom: 20px;
    width: 100%;

    p {
      padding: 30px;
    }
  }
  .service_link { // MEMO: 利用されていないかも
    position: relative;
    width: 100%;
    height: 300px;
    margin: 0 auto;
    background: url(/images/service_link_bg_sp.png) no-repeat top center;
    background-size: contain;
    margin-bottom: 30px;
  }
  .button.service_link_button {
    display: none;
  }
}
