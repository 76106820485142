.entry-area {
  * {
    box-sizing: border-box;
  }
  // include/recruit/nav.html内のスタイル
  .recruit-section {
    position: relative;
    padding-top: 64px;
    padding-bottom: 64px;
    margin: 0;
  }
  .recruit-section--bg-gray {
    @extend .recruit-section;
    background-color: $background-base-200;
  }
  .recruit-section--bg-blue {
    @extend .recruit-section;
    background-color: $mofmof-blue-50;
  }
  .form-link-block {
    background-color: $background-base-100;
    border-radius: $border-radius-m;
    padding: 48px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    > .btn {
      max-width: 296px;
      width: 100%;
    }
  }

  .recruit-footer-nav {
    background-color: $background-base-100;
    border-radius: $border-radius-m;
    padding: 40px 48px;
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: repeat(3, auto);
    grid-auto-flow: column;
    width: 100%;
    gap: 12px;
    position: relative;
    margin: 16px 0;
    justify-items: start;
    @media screen and (max-width: $breakpoint-pc) {
      grid-template-rows: repeat(6, auto);
      grid-template-columns: repeat(2, auto);
    }
    @media screen and (max-width: $breakpoint-md) {
      grid-template-rows: repeat(11, auto);
      grid-template-columns: repeat(1, auto);
    }
    .recruit-footer__link {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 4px 8px;
      font-size: rem(16);
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.04em;
      color: $primary-color;
      border-radius: $border-radius-s;
      text-decoration: none;
      &::after {
        content: '';
        line-height: 1;
        width: 10px;
        height: 10px;
        background-color: $primary-color;
        clip-path: path(
          'M2.907 8.968a.75.75 0 0 1 0-1.061l2.595-2.595-2.595-2.595a.75.75 0 0 1 1.061-1.061l3.125 3.125a.75.75 0 0 1 0 1.061L3.968 8.968a.75.75 0 0 1-1.061 0z'
        ); // > アイコン
      }
      &:hover {
        color: $primary-color-hover;
        background-color: $neutral-opacity-30;
        text-decoration: none;
        &::after {
          background-color: $mofmof-blue-800;
          animation: kf-icon-right-bounce 0.2s;
        }
      }
    }
    .footer-illust {
      position: absolute;
      bottom: 0;
      width: 80px;
      transition: .3s;
    }
    .footer-illust--left {
      @extend .footer-illust;
      left: -80px;
    }
    .footer-illust--right {
      @extend .footer-illust;
      right: -80px;
    }
    @media screen and (max-width: calc(#{$breakpoint-pc} + 160px)){
      .footer-illust--left, .footer-illust--right {
        visibility: hidden;
        opacity: 0;
      }
    }
    @media screen and (max-width: $breakpoint-pc){
      .footer-illust--right {
        visibility: visible;
        opacity: 1;
        top: -48px;
        right: 24px;
      }
    }
    @media screen and (max-width: $breakpoint-tablet){
      .footer-illust--right {
        right: 16px;
      }
    }
  }
}
