// _includes/thanks.htmlに対応。thanks.htmlとrecruit/thanks.htmlから利用。この場合はcomponentのが良い？
// TODO: recruit_thanks　もその他のThanksと合わせて良いか確認。不必要なclassは消す

.thanks {
  .main_visual {
    .main_img {
      background: url("/images/about/about_harada_on.png") left center no-repeat;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }

  .page_title {
    @media screen and (max-width: 1024px) {
      padding: 30px 10px;
    }
  }

  .container02 {
    // margin-bottom: 70px;

    @media screen and (max-width: 1024px) {
      margin-bottom: 35px;
      padding-bottom: 30px;
    }
  }

  .ttl {
    margin-bottom: 30px;
  }

  &_notice {
    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
      padding: 0 10px;
    }
  }

  &_button {
    position: relative;
    width: 220px;

    @media screen and (max-width: 1024px) {
      display: block;
      margin: 0 auto;
    }
  }
}
