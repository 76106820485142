.service-feature {
  &_section {
    margin-bottom: 120px;
    position: relative;

    @media screen and (max-width: 1024px) {
      margin-top: 60px;
    }

    &--background {
      width: 62%;
      height: 93%;
      position: absolute;
      background: $neutral-white url(/images/bg.png);
      z-index: -1;
      top: 10px;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &_container {
    width: 1300px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &_desc-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    @media screen and (max-width: 1024px) {
      display: block;
      width: 88.75%;
      margin: 0 auto;
    }
  }

  &_desc--img {
    margin-right: -60px;

    @media screen and (max-width: 1024px) {
      margin-right: 0;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &_desc--detail {
    margin-left: -60px;
    font-size: rem(13);
    line-height: 26px;

    @media screen and (max-width: 1024px) {
      font-size: rem(12);
      margin-left: 0;
      line-height: 24px;
    }

    p {
      margin-bottom: 40px;
    }
  }

  &_cards {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 50px;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &_card {
    width: 420px;
    margin: 30px;
    padding: 70px;
    filter: drop-shadow(2.3px 1.9px 3px lightgray);

    @media screen and (max-width: 1024px) {
      width: 43.75%;
      margin: 0;
      padding: 0;
    }

    &--general {
      @extend .service-feature_card;
      background: linear-gradient(to top, #EFEFEF, $neutral-white);
      border: solid 2px #EFEFEF;
    }
    &--mofmof {
      @extend .service-feature_card;
      background: linear-gradient(to top, #E8FDFF, $neutral-white);
      border: solid 2px $text-link-color;
    }
  }

  &_card-icon-container {
    width: 100%;
    position: relative;
    top: -115px;

    @media screen and (max-width: 1024px) {
      top: -25px;
    }
  }

  &_card-icon {
    color: $text-color;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 50%;
    text-align:center;
    line-height: 80px;
    border: solid 5px #EFEFEF;
    font-size: rem(48);
    font-weight: 900;
    background-color: $text-color-white;

    @media screen and (max-width: 1024px) {
      width: 48px;
      height: 48px;
      line-height: 48px;
      border: solid 3px #EFEFEF;
      font-size: rem(20);
    }

    &--mofmof {
      @extend .service-feature_card-icon;
      color: $primary-color;
      border: solid 5px $primary-color;

      @media screen and (max-width: 1024px) {
        border: solid 3px $primary-color;
      }
    }
  }

  &_card-headline {
    color: $primary-color;
    font-size: rem(24);
    line-height: 1;
    text-align: center;
    min-height: 60px;

    @media screen and (max-width: 1024px) {
      font-size: $small-font-size;
      font-weight: bold;
      width: 74.3%;
      margin: 0 auto;
    }

    &--general {
      @extend .service-feature_card-headline;
      color: #252525;
    }

    &--subtext {
      font-size: $small-font-size;
      display: block;
      margin-bottom: 15px;

      @media screen and (max-width: 1024px) {
        font-size: rem(10);
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }

  &_lists {
    list-style: none;
    font-size: $small-font-size;

    @media screen and (max-width: 1024px) {
      font-size: rem(12);
      width: 74.3%;
      margin: 0 auto;
    }
  }

  &_list {
    margin-top: 80px;
    position: relative;
    text-align: center;

    @media screen and (max-width: 1024px) {
      min-height: 120px;
      text-align: left;
    }

    &:after {
      position: absolute;
      display: block;
      top: -50px;
      width: 100%;
      text-align: center;
      font-size: rem(24);
      content: "･･･";
      color: $primary-color;

      @media screen and (max-width: 1024px) {
        top: -80px;
      }
    }

    &--start {
      @extend .service-feature_list;

      &:before {
        position: absolute;
        display: block;
        left: -210px;
        width: 190px;
        height: 50px;
        background-color: $text-link-color;
        text-align: center;
        line-height: 50px;
        color: white;
        content: "立ち上がり";

        @media screen and (max-width: 1024px) {
          width: 160px;
          height: 32px;
          line-height: 32px;
          left: -110px;
          top: -50px;
        }
      }
    }

    &--cost {
      @extend .service-feature_list;

      &:before {
        position: absolute;
        display: block;
        left: -210px;
        width: 190px;
        height: 50px;
        background-color: $text-link-color;
        text-align: center;
        line-height: 50px;
        color: white;
        content: "コスト";

        @media screen and (max-width: 1024px) {
          width: 160px;
          height: 32px;
          line-height: 32px;
          left: -110px;
          top: -50px;
        }
      }
    }

    &--flexible {
      @extend .service-feature_list;

      @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
      }

      &:before {
        position: absolute;
        display: block;
        left: -210px;
        width: 190px;
        height: 50px;
        background-color: $text-link-color;
        text-align: center;
        line-height: 50px;
        color: white;
        content: "柔軟性";

        @media screen and (max-width: 1024px) {
          width: 160px;
          height: 32px;
          line-height: 32px;
          left: -110px;
          top: -50px;
        }
      }
    }
  }
}
