.work {
  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  // FIXME: 既存クラスのリセットなので、できたら消したい -----
  .header {
    padding-bottom: 0;
  }

  .pc-only {
    // FIXME: 2024年6月現在、既存のブレークポイントのほとんどが１０２４pxなので
    // .pc-only のクラスを使った時に 992~1024pxの間を調整しないといけない
    // その調整のためのコードなのでいつか消したい
    display: block !important;
    @media screen and (max-width: $breakpoint-pc) {
      display: none !important;
    }
  }

  .sp-only {
    // FIXME: 2024年6月現在、既存のブレークポイントのほとんどが１０２４pxなので
    // .sp-only のクラスを使った時に 992~1024pxの間を調整しないといけない
    // その調整のためのコードなのでいつか消したい
    display: none !important;
    @media screen and (max-width: $breakpoint-pc) {
      display: block !important;
    }
  }

  // 消したい行、ここまで ------------------------------

  .container {
    margin-bottom: 104px;
    max-width: $breakpoint-xl;
    padding: 0 24px;
    width: 100%;
    @media screen and (max-width: $breakpoint-pc) {
      padding: 0;
    }
  }

  .breadcrumbs__outer {
    @media screen and (max-width: $breakpoint-pc) {
      padding-left: 16px;
    }
  }

  .main-visual {
    border-radius: $border-radius-m;
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
    object-fit: contain;
    overflow: hidden;
    height: 400px;
    @media screen and (max-width: $breakpoint-lg) {
      border-radius: 0;
    }
    @media screen and (max-width: $breakpoint-pc) {
      margin-bottom: 32px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      height: 280px;
    }
  }

  .work-main-content {
    $gap-width: 64px;
    $information-width: 280px;

    align-items: flex-start;
    color: $text-color;
    display: flex;
    gap: $gap-width;
    width: 100%;

    @media screen and (max-width: $breakpoint-pc) {
      flex-direction: column;
      padding: 0 24px;
    }

    .information {
      border-radius: $border-radius-m;
      border: solid 1px $neutral-opacity-100;
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding: 16px;
      width: $information-width;

      @media screen and (max-width: $breakpoint-pc) {
        display: grid;
        gap: 32px;
        grid-template-columns: 1fr 1fr;
        width: 100%;
      }

      @media screen and (max-width: $breakpoint-tablet) {
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: 100%;
      }

      // 左側の情報一覧に共通のスタイル --------
      [class$='__label'] {
        color: $neutral-solid-700;
        font-size: rem(14);
        font-weight: bold;
        margin-bottom: 12px;
      }

      [class$='__list'] {
        display: flex;
        flex-wrap: wrap;
        gap: 12px 16px;
        list-style-type: none;
      }

      // 左側の情報一覧に共通のスタイル ここまで --------

      .service {
        .service__name {
          @extend %work_tag;
          border-color: $primary-color;
          color: $primary-color;
          padding: 12px 16px;
        }
      }

      .industry {
        .industry__list {
          .industry__list--item {
            @extend %work_tag;
          }
        }
      }

      .technologies {
        .technologies__list {
          .technologies__list--item {
            @extend %work_tag;
          }
        }
      }

      .duration {
        .duration__name {
          color: $neutral-solid-600;
        }
      }
    }

    .headline {
      @media screen and (max-width: $breakpoint-pc) {
        margin-bottom: 0;
      }
      .heading {
        border-bottom: solid 1px $neutral-solid-100;
        margin-bottom: 32px;
        padding-bottom: 32px;
        position: relative;
        &::before {
          $height: 12px;
          background-color: $mofmof-blue-400;
          border-radius: 2px;
          bottom: calc(-#{$height} * 0.5);
          content: '';
          display: block;
          height: $height;
          left: 0;
          position: absolute;
          width: 300px;
        }
        .project-title {
          font-size: rem(48);
          line-height: 140%;
          font-weight: bold;
          margin-bottom: 8px;
          @media screen and (max-width: $breakpoint-pc) {
            font-size: rem(40);
          }
          @media screen and (max-width: $breakpoint-tablet) {
            font-size: rem(28);
          }
        }
        .client-name {
          font-size: rem(20);
          font-weight: 500;
          @media screen and (max-width: $breakpoint-tablet) {
            font-size: rem(16);
          }
        }
      }
      .brief-introduction {
        .brief-introduction__title {
          color: #333;
          font-size: rem(24);
          font-weight: 500;
          line-height: 150%;
          @media screen and (max-width: $breakpoint-tablet) {
            font-size: rem(16);
          }
          margin: 0 0 16px 40px;
          position: relative;
          @media screen and (max-width: $breakpoint-tablet) {
            font-size: rem(20);
          }
          &::before {
            $border-length: 30px;
            border-bottom: solid 1px #333;
            content: '';
            display: block;
            left: calc(-#{$border-length} - 10px);
            position: absolute;
            top: 20px;
            width: $border-length;
          }
        }
        .brief-introduction__content {
          margin-bottom: 16px;
        }

        .brief-introduction__link {
          text-align: right;
          a {
            color: $primary-color;
            font-size: rem(14);
            position: relative;
            text-decoration: none;
            &:hover {
              text-decoration: underline $primary-color;
            }
            &:before {
              $icon-size: 16px;
              background-image: url('/images/ico_link.svg');
              background-size: cover;
              content: '';
              display: block;
              height: $icon-size;
              left: calc(-#{$icon-size} - 8px);
              position: absolute;
              top: calc(50% - #{$icon-size} * 0.5);
              width: $icon-size;
            }
          }
        }
      }
    }

    .article {
      max-width: calc(100% - #{$gap-width} - #{$information-width});

      @media screen and (max-width: $breakpoint-pc) {
        max-width: 100%;
      }

      // article内の複数要素に共通のスタイル -----
      * {
        text-align: justify;
      }

      section {
        margin-bottom: 64px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      // article内の複数要素に共通のスタイル ここまで -----

      .separator {
        width: 140px;
        border-bottom: solid 1px $neutral-opacity-100;
        margin: 0 auto 64px auto;
      }

      .approach {
        .approach__content {
          > *:first-child {
            margin-top: 0;
          }

          @include markdown-style;

        }
      }

      %content-gap {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
      .clients-voice {
        .clients-voice__content {
          @extend %content-gap;
        }
        .btn-box {
          display: flex;
          justify-content: end;
        }
      }

      .members-voice__content {
        @extend %content-gap;
      }
    }
  }
}
