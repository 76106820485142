.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mt80 {
  margin-top: 80px;
}

.mt90 {
  margin-top: 90px;
}

.mt100 {
  margin-top: 100px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 100px;
}

.m {
  $directions: (t: top, b: bottom, l: left, r: right);
  @each $direction-key, $direction in $directions {
    $amounts: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
    @each $amount in $amounts {
      &#{$direction-key}#{$amount * 10} {
        margin-#{$direction}: $amount * 10 + px !important;
      }
    }
  }
}

// => .mb0, .mt10, .mr20 etc...が出力される

.m {
  $directions: (t: top, b: bottom, l: left, r: right);
  @each $direction-key, $direction in $directions {
    $amounts: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;
    @each $amount in $amounts {
      &#{$direction-key}#{$amount * 4} {
        margin-#{$direction}: $amount * 4 + px !important;
      }
    }
  }
}

// => .mb4, .mt8, .mr12 etc...が出力される(48まで)
