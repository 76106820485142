.member {
  .main_visual {
    .main_img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }

  &_container {
    width: 1000px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    &::after {
      content: "";
      display: block;
      width: 300px;

      @media screen and (max-width: 1024px) {
        margin: 0 auto;
        margin-bottom: 50px;
      }
    }
  }

  &_name {
    float: left;
    font-size: rem(13);
    line-height: 3;
  }

  &_jobtitle {
    float: right;
    font-size: rem(11);
    line-height: 3.5;
    color: $primary-color;
  }


  &_thumb {
    position: relative;
    width: 300px;
    height: 200px;

    img {
      transition: .3s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__out {
      z-index: 1;
    }

    &__over {
      z-index: 0;
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      .member_thumb__out {
        z-index: 0;
        opacity: 0;
        visibility: hidden;
      }

      .member_thumb__over {
        z-index: 1;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &_item {
    width: 300px;
    border-top: solid 1px $primary-color;
    margin-bottom: 50px;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
      margin: 0 auto;
      margin-bottom: 50px;
    }
  }

  &_message {
    font-size: rem(12);
    padding-top: 20px;
  }

  &_area {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 60px;

    @media screen and (max-width: 1024px) {
      padding-top: 50px;
    }

    .service_area_inner {
      margin-top: 120px;

      @media screen and (max-width: 1024px) {
        margin-top: 40px;
        padding: 0 20px;
      }
    }
  }

  &_link {
    border-color: #00a4b4;
    background: white;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-top: auto;
    margin-right: auto;
  }

  &_link-button {
    margin-top: 16px;
  }
}
