.bg {
  background: $background-color-old;
  position: relative;
  padding-bottom: 80px;
}

.bg-white {
  background: $neutral-white;
}

@media screen and (max-width: 1024px) {
  .bg {
    padding-bottom: 0;
  }
}
