// 福利厚生
.work_environment {
  .main_visual {
    .main_img {
      background: url(/images/main_work_environment.jpg) no-repeat center center;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }

  &_nav {
    margin: 0 30px;
  }
}

.recruit {
  * {
    box-sizing: border-box;
  }
  .main_visual {
    height: 600px;
    position: relative;

    &::after {
      background: url('/images/recruit/dot.png') left top repeat;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .main_img {
      background: url(/images/main_contact.jpg) no-repeat center top;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }

  // mainvisual内の表示制御
  .main_text_wrap {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    width: 100%;
  }

  .main_text_jp {
    position: static;
    color: $text-color-white;
    font-size: rem(72);
    font-weight: bold;
    line-height: 1.5em;

    @media screen and (max-width: 1024px) {
      font-size: rem(32);
      line-height: 1.2;
    }

    br {
      display: none;

      @media screen and (max-width: 1024px) {
        display: inline;
      }
    }
  }

  .main_text_en {
    position: static;
    color: $text-color-white;
    font-size: rem(36);
    font-weight: bold;

    @media screen and (max-width: 1024px) {
      font-size: rem(18);
    }
  }

  .recruit_container {
    background-color: $background-base-200;
    padding: 50px 0;

    p {
      padding-bottom: 50px;
    }

    a {
      color: $text-link-color;
    }

    .flow-image {
      width: 80%;
      margin-left: 10%;
    }

    @media screen and (max-width: 1024px) {
      font-size: rem(12);
      padding: 20px;
      background: $neutral-white;
      .flow-image {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .recruit-title {
    color: $primary-color;
    font-size: rem(24);
    margin-bottom: 24px;
    text-align: center;
    line-height: 1.5;
    letter-spacing: 0.04em;


    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(20);
      margin-bottom: 32px;
    }
  }

  .recruit_content {
    width: 700px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .recruit_flow {
    width: 700px;
    margin: 40px auto;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .recruit_guideline {
    margin-bottom: 50px;
    background-image: linear-gradient(
      to right,
      $primary-color 55%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .recruit_guideline-list {
    padding-left: 25px;
    padding-bottom: 10px;
    padding-top: 7px;
    line-height: 1.8;

    @media screen and (max-width: 1024px) {
      padding: 7px 0 10px 0;
      line-height: 1.8;
      width: 70%;
      margin: 0 auto;
    }
  }

  .recruit_guideline-term {
    width: 10.3em;
    float: left;
    background-image: linear-gradient(
      to right,
      $primary-color 55%,
      rgba(255, 255, 255, 0) 0
    );
    background-position: top;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    padding-top: 17px;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;

    @media screen and (max-width: 1024px) {
      width: 10em;
      padding: 15px 0 15px 0;
    }
  }

  .recruit_guideline-desc {
    margin-left: 10em;
    background-image: linear-gradient(
      to right,
      $primary-color 55%,
      rgba(255, 255, 255, 0) 0
    );
    background-position: top;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    padding: 17px 0 15px 0;

    @media screen and (max-width: 1024px) {
      margin-left: 9em;
    }
  }

  .recruit_entry-text {
    float: right;
    width: 350px;
    font-size: $small-font-size;

    @media screen and (max-width: 1024px) {
      float: none;
      width: 100%;
      font-size: rem(12);
      margin-bottom: 30px;
    }
  }

  .recruit_entry-notice {
    text-align: left;
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
      padding: 0 30px;
    }
  }

  .recruit_entry-section {
    width: 50%;
    float: left;

    input[type='file'] {
      height: auto;
    }

    @media screen and (max-width: 1024px) {
      padding-bottom: 80px;
    }

    .entry_form {
      width: 100%;
    }

    @media screen and (max-width: 1024px) {
      float: none;
      width: 100%;
      padding-top: 0;
      padding-bottom: 60px;
      form {
        width: 80%;
        margin: 0 auto;
      }
    }

    label {
      display: inline-block;
      color: $primary-color;
      padding-bottom: 2px;
      margin-left: -25px;
      max-width: 100%;
    }

    label.label-with-designed-checkbox {
      float: right;
      bottom: 200px;
      right: -550px;
      color: $text-color;
      @media screen and (max-width: 1024px) {
        float: initial;
        bottom: initial;
        right: initial;
        font-size: rem(12);
        margin-left: 24px;
      }

      a {
        color: $text-link-color;
      }
    }

    .text-error {
      float: right;
      position: absolute;
      bottom: 176px;
      right: -18px;
      @media screen and (max-width: 1024px) {
        float: initial;
        position: initial;
        bottom: initial;
        right: initial;
        font-size: rem(12);
      }
    }
  }

  .recruit_entry-button {
    float: right;
    bottom: 100px;
    width: 330px;
    height: 60px;
    position: absolute;
    right: 20px;
    padding: 1rem 1rem;
    text-align: left;
    color: #00a4b4;

    @media screen and (max-width: 1024px) {
      float: initial;
      bottom: initial;
      right: initial;
      position: initial;
      height: initial;
      text-align: center;
      padding: 1em 1em;
      width: 224px;
    }

    &:hover {
      background: #00a4b4;
      color: $text-color-white;
    }
  }

  .recruit_nav {
    background: $primary-color;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: $breakpoint-pc) {
      padding: 0;
    }
  }

  .recruit_nav-list {
    max-width: $breakpoint-pc;
    display: flex;
    justify-content: center;
    gap: 0;
    list-style: none;
    @media screen and (max-width: $breakpoint-pc) {
      padding: 0;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .recruit_nav-item {
    @media screen and (max-width: $breakpoint-pc) {
      width: 50%;
      box-sizing: border-box;

      &:nth-child(odd) {
        border-right: 1px solid $neutral-white;
      }

      &:nth-child(odd):last-child {
        width: 100%;
      }

      &:nth-child(n + 3) {
        border-top: 1px solid $neutral-white;
      }
      &:last-child {
        border-right: none;
      }
    }
  }

  .recruit_nav-link {
    display: block flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding: 0 16px;
    margin: 0;
    font-family: inherit;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: rem(14);
    line-height: 1.2;
    text-align: center;
    background: $primary-color;
    color: $text-color-white;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(12)
    }
    &:hover {
      text-decoration: none;
      background: $neutral-white;
      color: $primary-color;
    }
  }

  .recruit-section {
    position: relative;
    padding-top: 64px;
    padding-bottom: 64px;
    margin: 0;
  }
  .recruit-section--bg-gray {
    @extend .recruit-section;
    background-color: $background-base-200;
  }
  .recruit-section--bg-blue {
    @extend .recruit-section;
    background-color: $mofmof-blue-50;
  }

  .recruit_message-list {
    font-size: $small-font-size;
    padding-left: 25px;
    padding-bottom: 10px;
    padding-top: 7px;
    line-height: 1.8;

    @media screen and (max-width: 1024px) {
      padding: 7px 0 10px 0;
      line-height: 1.8;
      width: 70%;
      margin: 0 auto;
    }
  }

  // 代表挨拶
  .ceo-section {
    margin-top: 80px;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 48px;
    transition: 0.3s ease-in-out;
    @media screen and (max-width: $breakpoint-md) {
      grid-template-columns: 1fr;
    }
  }

  .recruit_ceo-meta {
    width: 100%;
    @media screen and (max-width: $breakpoint-md) {
      max-width: 400px;
      margin: 0 auto;
    }
    img.img-ceo {
      border: solid 1px $primary-color;
    }
  }

  .recruit_ceo-name {
    color: $primary-color;

    .name-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0 15px;
    }
    @media screen and (max-width: 1024px) {
      .name {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .name-wrap {
        display: block;
        margin: 15px 0 10px;
      }
    }
  }

  .recruit_ceo-link-icon {
    margin-right: 10px;
    @media screen and (max-width: 1024px) {
      margin: 1px;
    }
  }

  .recruit_ceo-link-icon-image {
    width: auto !important; // container04のimgを上書き
  }

  .recruit_ceo-name-jp {
    font-size: rem(20);
    display: inline-block;
    font-weight: bold;
    margin-right: 5px;
  }

  .recruit_ceo-job-title {
    font-size: $small-font-size;
  }

  .recruit_ceo-name-en {
    font-size: $small-font-size;
  }

  .recruit_ceo-message {
    width: 100%;
  }

  .recruit_ceo-message-header {
    font-size: rem(17);
    margin-bottom: 20px;
    color: $primary-color;
    padding: 15px;
    background: $background-base-200;
    border-radius: $border-radius-s;

    @media screen and (max-width: 1024px) {
      padding: 15px;
    }
  }

  .recruit_ceo-message-paragraph {
    margin-bottom: 15px;
    font-size: $small-font-size;

    @media screen and (max-width: 1024px) {
      padding: 5px 0;
    }
  }

  .recruit_bnr {
    margin: 80px 0 0;
    display: block;

    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }
  }

  // 特徴（カバー画像＋説明テキスト のセクション）
  .feature-cover {
    margin-bottom: 32px;
    aspect-ratio: 9/4;
    object-fit: cover;
  }

  .recruit_feature-heading {
    color: $primary-color;
    font-size: $base-font-size;
    margin-bottom: 15px;
  }

  // mofmofの働く環境
  .environment {
    list-style: none;
    display: flex;
    align-items: start;
    gap: 48px;

    li {
      width: 100%;
    }

    img {
      border: solid 1px $primary-color;
      margin-bottom: 24px;
    }

    h4 {
      text-align: center;
      background: $secondary-color;
      padding: 10px;
    }

    @media screen and (max-width: $breakpoint-md) {
      flex-direction: column;
      align-items: center;
      li {
        max-width: 440px;
      }
    }
  }

  // メンバーインタビュー
  .recruit_top-interview {
    .interview-list {
      background: $neutral-white;
      border: solid 1px $primary-color;
      border-radius: 5px;
      list-style: none;
      text-align: center;
      margin: 0 15px;
      min-height: 345px;
    }

    .interview-link {
      display: block;
      padding: 30px;

      &:hover {
        opacity: 0.7;
        transition: 0.3s;
        text-decoration: none;
      }
    }

    .interview-img {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      object-fit: cover;
      margin-bottom: 15px;
      display: inline-block;
    }

    .interview-name {
      color: $primary-color;
    }

    .interview-job {
      display: block;
      color: $primary-color;
      font-size: rem(13);
      margin-bottom: 10px;
    }

    .interview-txt {
      font-size: $small-font-size;
      text-align: left;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .slick-next,
    .slick-prev {
      $size: 40px;
      background-color: $primary-color;
      border-radius: 50%;
      color: $text-color-white;
      height: $size;
      width: $size;
      box-shadow: $shadow-btn;
      &:hover {
        background-color: $primary-color-hover;
      }
    }

    .slick-next {
      right: 0;
    }

    .slick-prev {
      left: 0;
    }

    @media screen and (max-width: 1024px) {

    }
  }

  .recruit_container-posts {
    display: flex;
    width: 900px;
    justify-content: space-around;
    margin-top: 100px;
    margin-bottom: 70px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-top: 100px;
      padding: 0;
    }
  }

  .recruit-slide {
    aspect-ratio: 16/9;
  }

  // TODO: 要素指定をやめる
  .recruit-faq {

    a {
      color: $text-link-color;
    }

    .recruit-faq_question {
      font-size: rem(16);
      display: flex;
      gap: 16px;
      margin-bottom: 8px;
      padding: 0 16px;
      &:before {
        display: block;
        flex-shrink: 0;
        content: '';
        background: url(../../images/recruit/recruit_qa_question.svg) no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        margin-top: calc(0.5lh - 10px); //縦位置合わせ: 0.5行-画像の高さの半分
      }
    }
    .recruit-faq_answer {
      font-size: rem(14);
      background: $background-base-200;
      border-radius: $border-radius-s;
      padding: 16px;
      display: flex;
      gap: 16px;
      margin-bottom: 24px;
      &:before {
        display: block;
        flex-shrink: 0;
        content: '';
        background: url(../../images/recruit/recruit_qa_answer.svg) no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        margin-top: calc(0.5lh - 10px); //縦位置合わせ: 0.5行-画像の高さの半分
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.mofmof-charactor {
  background: $background-color-old;
  padding: 60px 80px;

  h2 {
    color: $primary-color;
    font-size: rem(35);
    text-align: center;
    margin-bottom: 40px;
  }

  .list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    img {
      display: block;
      width: 25%;
    }

    .contents {
      width: 70%;
    }

    span {
      background: $primary-color;
      padding: 10px 15px;
      font-size: rem(12);
      margin-right: 5px;
      border-radius: 5px;
      color: $text-color-white;
      vertical-align: text-bottom;
    }

    h3 {
      color: $primary-color;
      font-size: rem(28);
      margin-bottom: 25px;
    }

    p {
      font-size: rem(17);
      line-height: 1.8;
      background: $neutral-white;
      padding: 40px;
      border: solid 2px $primary-color;
    }
  }
}

// スマホのスタイル
@media screen and (max-width: 1024px) {
  .mofmof-charactor {
    padding: 40px 30px;

    h2 {
      font-size: rem(25);
      margin-bottom: 20px;
    }

    .list li {
      display: block;
      margin-bottom: 30px;

      img {
        width: 200px;
        margin: 0 auto;
      }

      .contents {
        width: 100%;
      }

      span {
        font-size: rem(11);
      }

      h3 {
        font-size: rem(24);
        margin: 20px 0 20px 0;
      }

      p {
        font-size: $small-font-size;
        padding: 20px;
      }
    }
  }
}
