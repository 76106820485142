.connpass {
  &_event {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: $primary-color solid 1px;
    text-align: center;
  }

  &_logo {
    width: 60%;
  }

  &_date {
    color: $text-color;
  }

  &_head {
    color: $primary-color;
    margin-bottom: 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  &_catch {
    font-size: 0.9em;
  }

  &_desc {
    font-size: 0.9em;
  }

  &_desc-head {
    color: $text-color;
  }

  &_empty {
    font-size: 0.9em;
    color: $text-color;
    text-align: center;
    margin: 20px 0;
  }

  &_link {
    color: $text-link-color;

    &--wrap {
      color: $text-color;

      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
