.blog {
  &_content {
    $heading-border-width: 3px;
    padding: 35px;
    background: $background-color-old;
    overflow-wrap: break-word;
    width: 75%;

    @media screen and (max-width: 1024px) {
      width: auto;
    }

    a {
      font-weight: bold;
      text-decoration: underline;
    }

    h2 {
      margin-bottom: 12px;
      padding: 10px 12px;
      background-color: $text-color-white;
      border-left: $heading-border-width solid #48a2b1;
      color: $text-color;
    }

    h3 {
      margin-bottom: 10px;
      padding: 10px 0;
      border-bottom: 1px dashed $neutral-black;
    }

    ol {
      padding-top: 0;
      padding-left: 25px;
      padding-bottom: 10px;
    }

    tr {
      border-bottom: 1px solid $neutral-solid-100;
    }

    tr:last-child {
      border-bottom: none;
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .blog_banner-above-h2 {
      margin-bottom: 20px;

      .notification-text {
        color: $text-color;
        font-size: $small-font-size;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 8px;
      }
    }

    .blog-cta__link {
      color: $primary-color;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: end;
      margin-bottom: 8px;
      padding: 8px 16px 8px 16px;
      text-decoration: none;
      border: 1px solid $neutral-solid-100;
      border-radius: 4px;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content: none;
        align-items: center;
        gap: 16px;
        margin-left: 0;
      }

      &:hover {
        background-color: darken($background-color-old, 5%);

        .blog-cta__link--right {
          color: $text-color-white;
          background-color: $primary-color;
        }
      }

      .blog-cta__link--right {
        background: $neutral-white;
        border: solid 2px $primary-color;
        border-radius: 20px;
        padding: 2px 12px;
        text-decoration: none;
      }

      .catchphrase-text {
        margin-bottom: 0;
        text-decoration: none;
      }
    }
  }

  &_related-information {
    background: transparent;

    @media screen and (min-width: 1024px) {
      width: 25%;
      height: 0;
      margin-left: 20px;
    }

    @media screen and (max-width: 1024px) {
      width: auto;
      height: auto;
      display: block;
      margin-top: 15px;
    }
  }

  &_related-information-header {
    font-size: rem(18);
    font-weight: bold;
    color: $primary-color;
    display: inline-block;
    margin-bottom: 8px;
  }

  &_related-infromation-author {
    margin: 10px;

    .description {
      font-size: 0.8rem;
    }

    @media screen and (max-width: 1024px) {
      padding: 15px;
      margin: 0;
    }
  }

  &_related-posts {
    font-size: 0.9rem;
    font-weight: bold;

    // 関連記事の表示件数
    $display-post-count: 6;
    $count: 0;
    @while $count < $display-post-count {
      &-container-#{$count} {
        margin-bottom: 32px;

        a {
          text-decoration: none;

          &:hover {
            opacity: 0.7;
            transition: 0.3s;

            .post_title {
              color: $primary-color;
              text-decoration: underline;
            }
          }
        }

        @media screen and (max-width: 1024px) {
          margin-bottom: 24px;
          // スマートフォンは関連記事2件だけ表示
          @if $count > 1 {
            display: none;
          }
        }
      }
      $count: $count + 1;
    }

    .post_title {
      font-size: $base-font-size;
      color: $text-color;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }

    .post_tag {
      color: $text-color-light;
      font-size: $small-font-size;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &_related-information-spacer {
    padding: 16px 0;
  }

  &_share-buttons {
    &--top {
      width: 75%;
      position: relative;
      top: 0;
      padding: 16px 0;
      padding-left: 70px;
      text-align: right;

      a:hover {
        text-decoration: none;
      }

      @media screen and (max-width: 1024px) {
        display: none;
      }

      &-sp {
        display: none;

        img {
          margin: 5px;
        }

        @media screen and (max-width: 1024px) {
          display: block;
        }
      }
    }

    &--bottom {
      position: relative;
      top: 10px;
      text-align: right;

      a {
        text-decoration: none;
      }

      @media screen and (max-width: 1024px) {
        display: none;
      }

      &-sp {
        display: none;
        text-align: center;

        img {
          width: 26px;
          margin: 5px;
        }

        a {
          display: inline-block;
          text-decoration: none;
        }

        @media screen and (max-width: 1024px) {
          display: block;
        }
      }
    }
  }

  &_share-button {
    &--facebook {
      @extend %share-button;
      background-color: #3b5998;
    }

    &--twitter {
      @extend %share-button;
      background-color: #55acee;
    }

    &--hatebu-b {
      @extend %share-button;
      background-color: #008fde;
      speak: none;
      font-size: $small-font-size;
      font-weight: 600;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  &_cta-section {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    padding: 30px;

    @media screen and (max-width: 1024px) {
      background-color: $background-color-old;
    }

    &--mail-magazine {
      width: 100%;
      height: 420px;
    }
  }

  &_cta-banner {
    background-color: transparent !important;
  }

  &_cta-banner-image {
    @media screen and (max-width: 650px) {
      width: 100%;
      object-fit: contain;
    }
  }

  &_cta-container {
    text-align: center;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    @media screen and (min-width: 1024px) {
      background-color: $background-color-old;
      padding: 30px;
      width: 700px;
    }
  }

  &_footer-contents {
    max-width: 1150px;
    margin: 0 auto 56px auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
    }
  }

  &_banners-container {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .blog_banners-container-sp {
    display: none;
    text-align: center;

    .blog_banner-about {
      // FIXME: iPhone SEの横幅向けに若干厳しい調整をしているので、いつか何とかしたい
      font-size: rem(15);
      letter-spacing: -0.05rem;
    }

    .blog_banner-text {
      font-size: $base-font-size;
      margin-bottom: 12px;
    }

    .blog_banner-dl-button {
      color: $primary-color;
      width: 60%;
      background: $neutral-white;
      border: solid 2px $primary-color;
      border-radius: 20px;
      padding: 2px 12px;
      text-decoration: none;
    }

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  &_banner-image {
    object-fit: contain;
    width: 450px;
    height: 300px;

    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }

    @media screen and (max-width: 1024px) {
      width: 95%;
      height: auto;
    }
  }

  &_banner-image-above-h2 {
    object-fit: contain;

    @media screen and (max-width: 1024px) {
      width: 95%;
      height: auto;
    }
  }

  &_banner {
    position: relative;
    padding-top: 20px;
    width: 450px;
    height: 300px;
    margin: 0 5px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: auto;
      margin: 0;
      padding-top: 20px;
    }

    &-link {
      display: block;

      @media screen and (max-width: 1024px) {
        display: flex;
        justify-content: center;
      }
    }
  }

  &_author-container {
    &-pc {
      display: block;
      background: linear-gradient(0deg, rgba(2, 154, 170, 0.05), rgba(2, 154, 170, 0.05)), url(/images/bg.png);
      padding: 40px 0;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    &-sp {
      display: none;

      @media screen and (max-width: 1024px) {
        display: block;
        background: linear-gradient(0deg, rgba(2, 154, 170, 0.05), rgba(2, 154, 170, 0.05)), url(/images/bg.png);
        margin: 40px 0;
        padding: 40px 0;
      }
    }
  }

  &_author {
    max-width: 1024px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      text-align: center;
    }

    &-title {
      margin-bottom: 10px;
      font-weight: bold;
      color: $primary-color;
    }

    &-img {
      width: 160px !important;
      height: 160px !important;
      object-fit: cover;
      border-radius: 50%;

      @media screen and (max-width: 1024px) {
        margin-bottom: 10px;
      }
    }

    &-name {
      display: flex;
      justify-content: center;
      gap: 2px;
      font-weight: 700;
      color: $primary-color;

      &-ja {
        font-size: 1rem;
      }

      &-en {
        font-size: 0.75rem;
      }
    }

    &-job {
      font-size: 0.75rem;
      color: $primary-color;
    }

    &-about {
      font-size: 0.75rem;
      text-align: left;
    }

    &-card {
      width: 70%;
      margin-bottom: 20px;
      padding: 20px;
      display: flex;
      gap: 40px;
      background-color: white;
      border: 1px solid #E8E8E8;
      border-radius: 4px;

      @media screen and (max-width: 1024px) {
        display: block;
        margin: 0 auto 20px;
      }

      &-content {
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
        .blog_author-name {
          .name_ja {
            font-size: $base-font-size;
            font-weight: 700;
          }
          .name_en {
            font-size: rem(12);
            font-weight: 700;
          }
        }
        .blog_author-job {
          font-size: rem(12);
          font-weight: 700;
        }

        @media screen and (max-width: 1024px) {
          display: block;
        }
      }
    }
  }

  &_index-link-button-container {
    max-width: 758px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .blog_index-link-button-container-sp {
    display: none;

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  &_index-link-button {
    width: 227px;
    margin: 0 auto;
    background-color: white;
    box-shadow: $shadow-btn;
    border: 1px solid $primary-color;

    &-content {
      display: block;
      display: flex;
      justify-content: space-between;
      padding: 14px;
      color: $primary-color;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &_document-download-section {
    a:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &_download-banner {
    display: block;
    border: $primary-color solid 1px;

    img {
      margin: 0;
    }

    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }
  }

  &_tags {
    a {
      color: $text-color-light;
      font-weight: medium;
      text-decoration: none;
      margin-right: 8px;
      white-space: nowrap;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &_top-container {
    width: 1024px;
    margin: 0 auto;
    text-align: center;

    @media screen and (max-width: 1024px) {
      width: auto;
      padding: 0 30px;
    }
  }

  &_top {
    width: 75%;
    padding: 0 35px;

    @media screen and (max-width: 1024px) {
      width: auto;
    }
  }

  &_post-date {
    color: $text-color-light;
    font-weight: medium;
  }

  &_recommended-contents {
    a {
      color: $text-color;
      text-decoration: none;
      margin-right: 8px;

      &:hover {
        color: $primary-color;
      }
    }

    .list {
      padding: 0px;
      .list__item {
        $border-style: solid 2px $neutral-solid-100;
        border-bottom: $border-style;
        border-top: $border-style;
        list-style-type: none;
        padding: 8px;
      }
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}

%share-button {
  width: 110px;
  display: inline-block;
  text-align: center;
  color: white;
  font-size: rem(12);
  text-decoration: none;
  font-family: 'icomoon';
}

.facebook-page-section {
  padding-bottom: 24px;
}

.facebook-page-container {
  display: flex;
  justify-content: center;
}

.facebook-page-content {
  display: flex;
  justify-content: center;
}
