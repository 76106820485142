// KVセクション
.home-kv {
  display: contents;
  .home-kv__container {
    position: relative;
    display: grid;
    column-gap: 144px;
    row-gap: 32px;
    padding-top: 200px;
    padding-bottom: 64px;
    grid-template-rows: max-content 1fr;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 1;
    @media screen and (max-width: $breakpoint-xxl) {
      padding-left: 24px;
      padding-right: 24px;
    }
    @media screen and (max-width: $breakpoint-xl) {
      padding-left: 48px;
      padding-right: 48px;
      column-gap: calc(14vw - 24px);
    }
    @media screen and (max-width: $breakpoint-pc) {
      padding-top: 120px;
      padding-left: 48px;
      padding-right: 48px;
      grid-template-rows: max-content 1fr max-content;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 104px 24px 0;
    }
  }

  .home-kv__title {
    font-size: rem(64);
    line-height: 1.4;
    letter-spacing: 0.085em;
    grid-column: 1/2;
    z-index: 1;
    @media screen and (max-width: $breakpoint-xxl) {
      font-size: rem(56); 
    }
    @media screen and (max-width: $breakpoint-pc) {
      grid-row: 1/2;
    }
    @media screen and (max-width: $breakpoint-md) {
      font-size: rem(48);
    }
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: clamp(rem(32), 8vw, rem(48));
      text-align: center;
    }
  }
  .home-kv__title--emphasized {
    @extend .home-kv__title;
    color: $primary-color;
    margin-right: 8px;
    position: relative;
    z-index: 0;
    &::before {
      //ハイライト
      position: absolute;
      content: '';
      left: 0;
      bottom: 0.1em;
      width: 100%;
      height: 0.4em;
      background-color: $secondary-color;
      border-radius: 4px;
      z-index: $z-index-bg;
    }
  }
  .text-box {
    display: flex;
    flex-direction: column;
    gap: 32px;
    top: 228px;
    z-index: 1;
    grid-column: 1/2;
    @media screen and (max-width: $breakpoint-pc) {
      grid-row: 3/4;
      align-items: center;
      text-align: center;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(14);
    }
  }

  .text-box__buttons {
    display: flex;
    gap: 24px;
    .btn {
      min-width: 200px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 400px;
      gap: 16px;
      .btn {
        width: 100%;
      }
    }
  }

  // メイン画像グループ
  $base-width: 560px; //メイン画像のデフォルト幅
  $base-height: 420px; //メイン画像のデフォルト高さ
  // カンプの幅・横位置(px)をメイン画像を基準とした%表記に変換する関数
  @function kv-x($pixels) {
    @return $pixels / $base-width * 100%;
  }
  // カンプの高さ・縦位置(px)をメイン画像を基準とした%表記に変換する関数
  @function kv-y($pixels) {
    @return $pixels / $base-height * 100%;
  }

  .photo-group {
    z-index: 0;
    grid-column: 2/3;
    grid-row: 1/3;
    display: grid;
    place-items: center;
    @media screen and (max-width: $breakpoint-xl) {
      font-size: clamp(rem(24), 3vw, rem(40));
    }
    @media screen and (max-width: $breakpoint-pc) {
      grid-column: 1/2;
      grid-row: 2/3;
      padding: 64px 24px 24vw;
    }
  }
  .photo-group__wrapper {
    position: relative;
    width: 100%;
  }
  .kv-photo {
    opacity: 0;
  }
  .kv-photo--left-top-cross {
    position: absolute;
    top: kv-y(-80px);
    left: kv-x(-124px);
    width: kv-x(152px);
    z-index: 2;
    @media screen and (max-width: $breakpoint-pc) {
      top: kv-y(-72px);
      left: kv-x(-140px);
    }
  }
  .kv-photo--left-bottom {
    position: absolute;
    bottom: kv-y(-109px);
    left: kv-x(-138px);
    width: kv-x(360px);
    z-index: 1;
    @media screen and (max-width: $breakpoint-pc) {
      left: kv-x(-70px);
      width: kv-x(320px);
      bottom: kv-y(-102px);
    }
  }
  .kv-photo--center {
    width: 100%;
  }
  .kv-photo--right-bottom {
    position: absolute;
    bottom: kv-y(-176px);
    right: kv-x(-126px);
    width: kv-x(300px);
    z-index: -1;
    @media screen and (max-width: $breakpoint-pc) {
      bottom: kv-y(-146px);
      right: kv-x(-102px);
      width: kv-x(264px);
    }
  }
  .kv-photo--right-top {
    position: absolute;
    top: kv-y(-144px);
    right: kv-x(-120px);
    width: kv-x(180px);
  }
  .kv-photo--bottom-dot {
    position: absolute;
    bottom: kv-y(-240px);
    left: kv-x(220px);
    width: kv-x(130px);
    z-index: -1;
    @media screen and (max-width: $breakpoint-pc) {
      bottom: kv-y(-162px);
      left: kv-x(200px);
    }
  }

  // 背景エレメント
  .bg-group {
    width: 100%;
    height: 100%;
  }
  .bg-group__left-circle {
    position: absolute;
    width: 640px;
    top: -300px;
    left: -360px;
    @media screen and (max-width: $breakpoint-xl) {
      width: 560px;
      top: -240px;
      left: -300px;
    }
    @media screen and (max-width: $breakpoint-pc) {
      width: 480px;
      top: -200px;
      left: -240px;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 320px;
      top: -100px;
      left: -160px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 240px;
      top: -36px;
      left: -108px;
    }
  }
  .bg-group__right-circle {
    position: absolute;
    width: 800px;
    top: 180px;
    right: -400px;
    @media screen and (max-width: $breakpoint-xl) {
      width: 640px;
      top: 180px;
      right: -300px;
    }
    @media screen and (max-width: $breakpoint-pc) {
      width: 600px;
      top: 200px;
      right: -240px;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 400px;
      top: 500px;
      right: -180px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 320px;
      top: 300px;
      right: -148px;
    }
  }
}
