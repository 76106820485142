// font
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@700&family=GFS+Didot&family=M+PLUS+Rounded+1c:wght@700&display=swap');

$base-font-family: 'Noto Sans JP', '游ゴシック', YuGothic,
  'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo,
  sans-serif;
$title-font-family: 'Lato', sans-serif;
$base-font-size: rem(16);
$small-font-size: rem(14);
$base-line-height: 1.7;
$base-letter-spacing: 0.04em;

// border-radius
$border-radius-s: 6px;
$border-radius-m: 12px;
$border-radius-l: 16px;

// primitive color -----------------------
// 目的と紐づいていない、広範なカラーパレット
$neutral-white: #ffffff;
$neutral-solid-20: #fafafa;
$neutral-solid-30: #f7f7f7;
$neutral-solid-50: #f2f2f2;
$neutral-solid-100: #e6e6e6;
$neutral-solid-200: #cccccc;
$neutral-solid-300: #b3b3b3;
$neutral-solid-400: #999999;
$neutral-solid-420: #949494;
$neutral-solid-500: #7f7f7f;
$neutral-solid-536: #767676;
$neutral-solid-600: #666666;
$neutral-solid-700: #4d4d4d;
$neutral-solid-800: #333333;
$neutral-solid-900: #1a1a1a;

$neutral-black: #000000;
$neutral-opacity-20: #00000005;
$neutral-opacity-30: #00000008;
$neutral-opacity-50: #0000000d;
$neutral-opacity-100: #0000001a;
$neutral-opacity-200: #00000033;
$neutral-opacity-300: #0000004d;
$neutral-opacity-400: #00000066;
$neutral-opacity-420: #0000006b;
$neutral-opacity-500: #00000080;
$neutral-opacity-536: #00000089;
$neutral-opacity-600: #00000099;
$neutral-opacity-700: #000000b2;
$neutral-opacity-800: #000000cc;
$neutral-opacity-900: #000000e5;

$mofmof-blue-50: #e2f8fa;
$mofmof-blue-100: #c7ebef;
$mofmof-blue-200: #a3dde3;
$mofmof-blue-300: #80ced7;
$mofmof-blue-400: #5dbec9;
$mofmof-blue-500: #30acb9;
$mofmof-blue-600: #029aaa;
$mofmof-blue-700: #008c9b;
$mofmof-blue-800: #007c8a;
$mofmof-blue-900: #006b77;
$mofmof-blue-1000: #005863;
$mofmof-blue-1100: #00444d;
$mofmof-blue-1200: #002f35;

$yellow-50: #ffffcd;
$yellow-100: #fefd8c;
$yellow-200: #faf94e;
$yellow-300: #f4f212;
$yellow-400: #e7e508;
$yellow-500: #d3d300;
$yellow-600: #bebe00;
$yellow-700: #a8a800;
$yellow-800: #929200;
$yellow-900: #7c7c00;
$yellow-1000: #666600;
$yellow-1100: #515100;
$yellow-1200: #3b3b00;

$red-50: #fceeee;
$red-100: #f7d0d0;
$red-200: #f1b3b3;
$red-300: #ea9696;
$red-400: #e37a7a;
$red-500: #db5e5e;
$red-600: #c94e4e;
$red-700: #b54040;
$red-800: #9f3232;
$red-900: #892727;
$red-1000: #711c1c;
$red-1100: #571313;
$red-1200: #3b0c0c;

$green-50: #edf9ec;
$green-100: #ccedcb;
$green-200: #abe0aa;
$green-300: #8cd38a;
$green-400: #6cc66a;
$green-500: #4db84b;
$green-600: #40a93e;
$green-700: #349832;
$green-800: #298627;
$green-900: #1f731e;
$green-1000: #175f16;
$green-1100: #104a0e;
$green-1200: #093309;
// primitive color(終わり) --------------

// semantic color
// 目的と紐づいた変数。プリミティブカラーを参照する。
// 基本的に色指定をするときは semantic color の変数を使うが、
// 再利用性の高くない色指定においては、プリミティブカラーから直接指定する

$text-color: $neutral-solid-800;
$text-color-light: $neutral-solid-536;
$text-color-white: $neutral-white;

$primary-color: $mofmof-blue-600;
$text-link-color: $mofmof-blue-600;
$primary-color-hover: $mofmof-blue-700;
$secondary-color: $yellow-300;
$attention-color: $red-500;
$success-color: $green-500;

$background-base-100: $neutral-white;
$background-base-200: $neutral-solid-30;
$background-base-300: $neutral-solid-50;
$background-color-old: #f8f8f4; // NOTE: 古い色なので、新しく作るページや改修したページでは使用しない
$background-base-color: $background-base-200;
$background-middle-color: #f2f2ed;

// service unique color
// 各サービス固有のカラー。
$mvp-green-50: #e3f8f3; // 「サクッとmvpラボ」を表す時に使う
$mvp-green-100: #c2e6df;
$mvp-green-200: #a2d8ce;
$mvp-green-300: #7ccabb;
$mvp-green-400: #56bba8;
$mvp-green-500: #2fae95;
$mvp-green-600: #08a082;
$mvp-green-700: #068f74;
$mvp-green-800: #037d65;
$mvp-green-900: #026c57;
$mvp-green-1000: #015948;
$mvp-green-1100: #004638;
$mvp-green-1200: #003329;

$goro-orange-50: #fef4eb; // 「GOROさんとの連携プロジェクト」を表す時に使
$goro-orange-100: #f9dec7;
$goro-orange-200: #f6c7a2;
$goro-orange-300: #f4b17c;
$goro-orange-400: #f29a55;
$goro-orange-500: #f1832e;
$goro-orange-600: #f06b06;
$goro-orange-700: #d65e03;
$goro-orange-800: #bc5200;
$goro-orange-900: #a14500;
$goro-orange-1000: #863800;
$goro-orange-1100: #6a2c00;
$goro-orange-1200: #4d1f00;

// breakpoints
$grid-breakpoints: (
  // 参考： bootstrap https://getbootstrap.com/docs/5.0/layout/breakpoints/
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$breakpoint-sm: map-get($grid-breakpoints, sm);
$breakpoint-tablet: $breakpoint-sm;
$breakpoint-md: map-get($grid-breakpoints, md);
$breakpoint-lg: map-get($grid-breakpoints, lg);
$breakpoint-pc: $breakpoint-lg;
$breakpoint-xl: map-get($grid-breakpoints, xl);
$breakpoint-xxl: map-get($grid-breakpoints, xxl);

// container
$base-post-width: 750px;

// shadow
$shadow-card: 2px 2px 10px rgba(#d3d3d3, 0.3);
$shadow-btn: 2px 2px 3px #d3d3d3;

// spacing
$spacing-unit: 30px;
$on-palm: 600px;
$on-laptop: 800px;
$global-margin: 10px;

// easing
$linear: cubic-bezier(0.25, 0.25, 0.75, 0.75);
$ease: cubic-bezier(0.25, 0.1, 0.25, 1);
$ease-in: cubic-bezier(0.42, 0, 1, 1);
$ease-out: cubic-bezier(0, 0, 0.58, 1);
$ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

// z-index
$z-index-bg: -1;
$z-index-floating-button: 10;
$z-index-header: 30;
$z-index-sp-menu-overlay: 100;
