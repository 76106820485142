.download {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .content {
    max-width: 944px;
    padding: 48px 24px 104px 24px;
    width: 100%;
    margin: 0 auto;
  }

  .document-info {
    margin-bottom: 64px;
    .title {
      font-size: rem(32);
      line-height: 1.5;
      margin-bottom: 24px;
      text-align: center;
      @media screen and (max-width: $breakpoint-md) {
        font-size: rem(24);
      }
    }

    .description {
      font-size: rem(14);
      line-height: 180%;
      margin-bottom: 24px;
      text-align: center;
    }

    .image__wrap {
      margin-bottom: 40px;
      @media screen and (max-width: $breakpoint-md) {
        height: 320px;
      }
      @media screen and (max-width: $breakpoint-sm) {
        height: 240px;
      }
      .image {
        border-radius: $border-radius-m;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .what-is-included {
      padding-left: 24px;
      .what-is-included__item {
        font-weight: bold;
        &.nested {
          font-weight: normal;
          list-style-type: none;
          padding-left: 16px;
          &::before {
            content: '\2022';
            position: relative;
            right: 12px;
          }
        }
      }
    }
  }
}
