.home-skilled-at {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  background-color: $neutral-solid-20;
  background-image: url(/images/home/home-bg-grid.svg);

  .home-skilled-at__container {
    position: relative;
    z-index: 1;
  }
  .home-skilled-at__heading {
    font-size: rem(24);
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.04em;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 80px;
  }
  .techs {
    //コンポーネントのマージン後付け
    margin-bottom: 20px;
  }
  .bg-image-left-top-circle {
    position: absolute;
    width: 640px;
    top: -340px;
    left: -296px;
    @media screen and (max-width: $breakpoint-xl) {
      width: 480px;
      top: -200px;
      left: -200px;
    }
    @media screen and (max-width: $breakpoint-pc) {
      width: 320px;
      top: -200px;
      left: -160px;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 240px;
      top: -200px;
      left: -120px;
    }
  }
  .bg-image-left-top-cross {
    position: absolute;
    top: -144px;
    left: 96px;
    width: 152px;
    transform: rotate(180deg);
    @media screen and (max-width: $breakpoint-pc) {
      width: 112px;
      top: -144px;
      left: 48px;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 76px;
      top: -148px;
      left: 16px;
    }
  }
}
