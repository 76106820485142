#survey {
  display: none;
  position: fixed;
  z-index: 5;
  bottom: 30px;
  right: 30px;
  margin: 20px;
  background-color: white;

  filter: drop-shadow(5px 5px 7px rgba(0, 0, 0, .15));
}

#survey_description-form {
  display: none;
}

.survey {
  &_container {
    position: relative;
    padding: 25px 20px;
  }

  &_headline {
    font-size: 1.1em;
    color: $primary-color;
  }

  &_form {
    margin-top: 20px;
    width: 330px;
  }

  &_close-button {
    position: absolute;
    top: -23px;
    right: -23px;
    display: block;
    width: 50px; /*枠の大きさ*/
    height: 50px; /*枠の大きさ*/
    background: white;
    border: none;
    filter: drop-shadow(5px 5px 7px rgba(0, 0, 0, .15));
    cursor: pointer;
  }

  &_close-button::before, &_close-button::after {
    content: "";
    display: block;
    width: 65%; /*バツ線の長さ*/
    height: 2px; /*バツ線の太さ*/
    background: $primary-color;
    transform: rotate(45deg);
    transform-origin: 0% 50%;
    position: absolute;
    top: calc(37% - 5px);
    left: 27%;
  }

  &_close-button::after {
    transform: rotate(-45deg);
    transform-origin: 100% 50%;
    left: auto;
    right: 26%;
  }

  &_submit:not(:first-child) {
    margin-top: 20px;
  }

  &_contact-link {
    font-weight: bold;
    color: $primary-color;
  }

  &_contact-message {
    margin: 14px 0;
  }

  &_textarea {
    width: calc(100% - 22px);
    border: 1px solid #dedede;
    padding: 10px;
    margin-top: 20px;

    &:hover {
      border: 1px solid #444;
    }
  }

  &_request {
    margin: 10px 0;
  }
}
