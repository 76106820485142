// NOTE: mixinは {} の外側に書かないとスコープのエラーになる
@mixin section-splitter-setting($next-section-bg-color) {
  // NOTE: background-colorのプロパティよりも後に設置してください。
  background-image: url("/images/service/dev-team-rental/shared/splitter-#{$next-section-bg-color}.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

@mixin text-with-icon-setting($icon_width, $icon_height, $gap_width, $image_path) {
  position: relative;
  margin-left: calc(#{$icon_width} + #{$gap_width});
  &:before {
    background: url(#{$image_path}) no-repeat center;
    content: '';
    display: block;
    height: $icon_height;
    position: absolute;
    width: $icon_width;
    top: calc(50% - #{$icon_height} * 0.5);
    left: calc(-#{$icon_width} - #{$gap_width});
  }
}

.development-team-rental {
  /* 以下の要素は個別ファイルの書き出してある
    - main
    - footer
    - faq
  */
  
  *, *::before, *::after {
    box-sizing: border-box;
  }

  section {
    // NOTE: 他ページのスタイルの打ち消し
    margin-bottom: 0;
  }

  %h3-text-base {
    color: $primary-color;
    font-size: rem(20);
    font-weight: 700;
    line-height: 150%;
  }

  %eyecatch-text-base {
    color: $primary-color;
    font-family: 'Barlow';
    font-weight: 700;
  }

  %sp-header-logo-base {
    width: 256px;
    max-width: 100%;
    object-fit: contain;
  }

  %pseudo-element-icon-base {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
    }
  }

  %vertical-line-base {
    background: $neutral-solid-200;
    display: block;
    height: 100%;
    left: 36px;
    position: absolute;
    width: 2px;
    z-index: 0;
  }

  .btn {
    align-items: center;
    border-radius: 24px;
    cursor: pointer;
    display: flex;
    font-size: rem(14);
    font-weight: 700;
    height: 40px;
    justify-content: center;
    padding: 0px 22px;
    text-decoration: none;
    white-space: nowrap;
    &.btn-block {
      width: 100%;
    }
    &.btn-download {
      background: $neutral-white;
      color: $primary-color;
      &:hover {
        // NOTE: 要素のある位置によりホバーアクションが現れないことがあり、!importantをつけている
        background: $neutral-solid-50 !important;
        transition: 0.2s;
      }
    }
    &.btn-contact {
      color: $neutral-solid-700;
      background: $yellow-300;
      &:hover {
        // NOTE: 要素のある位置によりホバーアクションが現れないことがあり、!importantをつけている
        background: $yellow-500 !important;
        transition: 0.2s;
      }
    }
    &.btn-primary {
      color: $neutral-white;
      background: $mofmof-blue-600;
      &:hover {
        background: $mofmof-blue-800;
        transition: 0.2s;
      }
    }
  }

  .heading {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    row-gap: 4px;
    width: 100%;
    @media screen and (max-width: $breakpoint-pc) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      margin-bottom: 32px;
    }
    .heading__title {
      @extend %eyecatch-text-base;
      font-size: rem(64);
      letter-spacing: 4px;
      line-height: 72px;
      @media screen and (max-width: $breakpoint-pc) {
        font-size: rem(56);
      }
      @media screen and (max-width: $breakpoint-tablet) {
        font-size: rem(48);
      }
    }
    .heading__sub-title {
      font-size: rem(14);
      font-weight: 700;
      line-height: 150%;
      color: $neutral-solid-600;
    }
  }

  .section {
    padding: 64px 32px 112px;
    overflow: hidden;
    @media screen and (max-width: $breakpoint-pc) {
      padding: 56px 24px 92px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 48px 16px 72px;
    }
  }

  .slashed-text {
    @extend %h3-text-base;
    display: inline-block;
    letter-spacing: 0.02rem;
    font-size: rem(16);
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 2px; /* Adjust the thickness of the line */
      width: 28px; /* Adjust the length of the line */
      background-color: $primary-color; /* Adjust the color of the line */
    }
    &::before {
      top: 50%;
      left: -40px; /* Adjust the starting position of the line */
      transform: rotate(72deg);
    }
    &::after {
      top: 50%;
      right: -40px; /* Adjust the ending position of the line */
      transform: rotate(-72deg);
    }
  }

  // NOTE: 各セクションの内容
  .about {
    background: $neutral-solid-50;
    @include section-splitter-setting("neutral-solid-30");
    .contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 48px;
      .top-content {
        display: flex;
        flex-direction: column;
        row-gap: 48px;
        width: 100%;
        max-width: 1008px;
        .top-content__item {
          display: flex;
          justify-content: end;
          position: relative;
          width: 100%;

          // NOTE: 全てのitemに共通のスタイル
          &:before, &:after {
            background-repeat: no-repeat;
            background-size: cover;
            content: '';
            display: block;
            position: absolute;
            @media screen and (max-width: $breakpoint-pc) {
              display: none;
            }
          }
          @media screen and (max-width: $breakpoint-pc) {
            justify-content: center;
          }
          .item__main {
            align-items: center;
            background: $neutral-solid-30;
            border-radius: 12px;
            box-shadow: $shadow-card;
            display: flex;
            flex-direction: column;
            max-width: 736px;
            padding: 16px 24px 24px;
            row-gap: 24px;
            z-index: 1;
            @media screen and (max-width: $breakpoint-pc) {
              max-width: 640px;
              padding: 16px 20px 24px;
            }
            @media screen and (max-width: $breakpoint-tablet) {
              max-width: 640px;
              padding: 16px 16px 24px;
            }
            %top-content-heading-setting {
              align-items: center;
              column-gap: 24px;
              display: flex;
              flex-direction: unset; // FIXME: 上方の.headingの打ち消し
              margin-bottom: 0; // FIXME: 上方の.headingの打ち消し
              width: 100%;
              @media screen and (max-width: $breakpoint-pc) {
                flex-direction: column;
                align-items: start;
                row-gap: 4px;
              }
            }
            .heading {
              @extend %top-content-heading-setting;
              .heading__number {
                @extend %eyecatch-text-base;
                font-size: rem(64);
                line-height: 1.0;
                @media screen and (max-width: $breakpoint-pc) {
                  font-size: rem(20);
                }
                @media screen and (max-width: $breakpoint-tablet) {
                  font-size: rem(16);
                }
              }
              .heading__title {
                @extend %h3-text-base;
                color: $neutral-solid-700;
                font-family: $base-font-family;
                .heading__title--main {
                  font-size: rem(24);
                  letter-spacing: 0.01rem;
                  line-height: 175%;
                  word-break: keep-all;
                  @media screen and (max-width: $breakpoint-pc) {
                    font-size: rem(22);
                  }
                  @media screen and (max-width: $breakpoint-tablet) {
                    font-size: rem(20);
                    line-height: 150%;
                  }
                }
                .heading__title--sub {
                  color: $neutral-solid-500;
                  display: block;
                  font-size: rem(12);
                  letter-spacing: 0.03rem;
                  line-height: 150%;
                }
              }
              
            }
            .info {
              $icon_size: 20px;
              $gap_size: 4px;
              font-size: rem(14);
              line-height: 175%;
              letter-spacing: 0.01rem;
              color: $neutral-solid-600;
              position: relative;
              margin-left: calc(#{$icon_size} + #{$gap_size});
              &:before {
                background: url("/images/ico_info_orange.svg") no-repeat center;
                content: '';
                display: block;
                height: $icon_size;
                position: absolute;
                width: $icon_size;
                top: 2px;
                left: calc(-#{$icon_size} - #{$gap_size});
              }
            }
            .card {
              background-color: $neutral-white;
              border-radius: $border-radius-m;
              box-shadow: $shadow-card;
              .card__title {
                background: $mofmof-blue-600;
                border-radius: 12px 12px 0px 0px;
                padding: 4px 12px;
                .card__title--text {
                  $icon_size: 20px;
                  $gap_size: 4px;
                  color: $neutral-white;
                  font-weight: 700;
                  letter-spacing: 0.02rem;
                  line-height: 175%;
                  position: relative;
                  margin-left: calc(#{$icon_size} + #{$gap_size});
                  &:before {
                    background: url("/images/ico_check_white.svg") no-repeat center;
                    content: '';
                    display: block;
                    height: $icon_size;
                    position: absolute;
                    width: $icon_size;
                    top: calc(50% - #{$icon_size} * 0.5);
                    left: calc(-#{$icon_size} - #{$gap_size});
                  }
                }
              }
              .card__content {
                padding: 12px 16px;
                .card__content--text {
                  line-height: 175%;
                  letter-spacing: 0.01rem;
                  color: $neutral-solid-700;
                }
              }
            }
            .image {
              display: none;
              width: 100%;
              max-width: 320px;
              @media screen and (max-width: $breakpoint-pc) {
                display: block;
              }
            }
            .read-more-list {
              color: $neutral-solid-500;
              display: flex;
              flex-direction: column;
              align-items: start;
              row-gap: 4px;
              list-style-type: none;
              margin-left: auto;
              @media screen and (max-width: $breakpoint-tablet) {
                align-self: flex-start;
                margin-left: unset;
                &:before {
                  content: 'READMORE';
                }
              }
              .read-more-list__item {
                .read-more-list__item--link {
                  $icon_size: 24px;
                  $gap_size: 4px;
                  color: $neutral-solid-500;
                  letter-spacing: 0.01rem;
                  line-height: 175%;
                  position: relative;
                  @media screen and (max-width: $breakpoint-tablet) {
                    margin-left: $icon_size + $gap_size;
                  }
                  &:before {
                    content: 'READMORE：';
                    @media screen and (max-width: $breakpoint-tablet) {
                      background: url("/images/ico_chevron-right_gray.svg") no-repeat center;
                      content: '';
                      display: inline-block;
                      height: $icon_size;
                      left: calc(-#{$icon_size} - #{$gap_size});
                      position: absolute;
                      top: calc(50% - #{$icon_size} * 0.5);
                      width: $icon_size;
                    }
                  }
                  &:hover {
                    color: $neutral-solid-500;
                    opacity: 0.8;
                    text-decoration: none;
                  }
                }
              }
            }
          }

          // NOTE: 特定のitemのスタイル
          &:nth-child(2n) {
            justify-content: start;
          }
          &.first {
            &:before {
              background-image: url("/images/service/dev-team-rental/startup/about/bg-image-01@934w.webp");
              height: 312px;
              left: -96px;
              top: 124px;
              width: 467px;
            }
          }
          &.second {
            &:after {
              background-image: url("/images/service/dev-team-rental/startup/about/bg-image-02@960w.webp");
              height: 320px;
              right: -104px;
              top: 90px;
              width: 480px;
            }
          }
          &.third {
            &:before {
              background-image: url("/images/service/dev-team-rental/startup/about/bg-image-03@964w.webp");
              height: 322px;
              top: 60px;
              left: -104px;
              width: 482px;
            }
          }
        }
      }
    }
    
    .bottom-content {
      display: flex;
      width: 100%;
      max-width: 1008px;
      @media screen and (max-width: $breakpoint-pc) {
        max-width: 640px;
      }
      .container {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        padding: 24px 48px 40px 48px;
        border-radius: 12px;
        background: $neutral-solid-30;
        box-shadow: $shadow-card;
        @media screen and (max-width: $breakpoint-pc) {
          padding: 20px 32px 40px 32px;
        }
        @media screen and (max-width: $breakpoint-tablet) {
          padding: 16px 16px 40px 16px;
        }
        .container__title {
          @extend %h3-text-base;
          background: $neutral-white;
          border-radius: 12px;
          box-shadow: $shadow-card;
          font-size: rem(24);
          letter-spacing: 0.01rem;
          padding: 12px 0px;
          text-align: center;;
          @media screen and (max-width: $breakpoint-pc) {
            font-size: rem(22);
          }
          @media screen and (max-width: $breakpoint-tablet) {
            font-size: rem(20);
            line-height: 150%;
          }
        }
        .container__content {
          display: flex;
          flex-direction: column;
          row-gap: 32px;
          @media screen and (max-width: $breakpoint-pc) {
            row-gap: 28px;
          }
          @media screen and (max-width: $breakpoint-tablet) {
            row-gap: 24px;
          }
          .what-we-can-do {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 64px;
            position: relative;
            @media screen and (max-width: $breakpoint-pc) {
              display: flex;
              flex-direction: column;
              row-gap: 28px;
            }
            @media screen and (max-width: $breakpoint-tablet) {
              row-gap: 24px;
            }
            &:after {
              position: absolute;
              top: calc(50% - 2px); // NOTE: 2pxはボーダーの太さ
              content: '';
              width: 100%;
              height: 2px;
              border-bottom: 2px dashed $neutral-solid-200;
              @media screen and (max-width: $breakpoint-pc) {
                display: none;
              }
            }
            .item {
              display: flex;
              flex: 1;
              flex-direction: column;
              align-items: center;
              row-gap: 16px;
              @media screen and (max-width: $breakpoint-pc) {
                padding-bottom: 24px;
                &:not(:last-child) {
                  border-bottom: 2px dashed $neutral-solid-200;
                }
              }
              .item__image {
                width: 200px;
                height: 200px;
              }
              .item__content {
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 8px;
                .item__content--title {
                  @extend %h3-text-base;
                  letter-spacing: 0.02rem;
                  color: $neutral-solid-700;
                }
                .item__content--description {
                  line-height: 175%;
                  letter-spacing: 0.01rem;
                  color: $neutral-solid-700;
                }
              }
            }
          }
        }
      }
    }
  }

  .process {
    .container {
      display: flex;
      flex-direction: column;
      row-gap: 120px;
      @media screen and (max-width: $breakpoint-pc) {
        row-gap: 88px;
      }
      @media screen and (max-width: $breakpoint-tablet) {
        row-gap: 48px;
      }
      .before, .during, .after {
        //NOTE: contents内共通要素のスタイル
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 42px;
        align-items: center;
        .title {
          align-items: center;
          color: $neutral-solid-700;
          display: flex;
          flex-direction: column;
          font-size: rem(28);
          font-weight: 700;
          position: relative;
          width: 100%;
          @media screen and (max-width: $breakpoint-pc) {
            font-size: rem(24);
          }
          @media screen and (max-width: $breakpoint-tablet) {
            font-size: rem(20);
          }
          &::after {
            @media screen and (max-width: $breakpoint-pc) {
              background: $neutral-solid-700;
              content: '';
              display: block;
              height: 2px;
              position: absolute;
              top: 37px; // NOTE: font-size + gap-size(10px) - height(2px) * 0.5
              width: 144px;
            }
            @media screen and (max-width: $breakpoint-tablet) {
              top: 27px; // NOTE: font-size + gap-size(10px) - height(2px) * 0.5
              width: 116px;
            }
          }
        }
        .contents {
          .steps, .steps-and-check-points {
            &:before {
              @extend %eyecatch-text-base;
              color: $neutral-solid-200;
              font-size: rem(96);
              line-height: 1.0;
              position: absolute;
              top: -0.92em;
              @media screen and (max-width: $breakpoint-pc) {
                display: none;
              }
            }
          }
          .steps, .steps-and-check-points {
            .vertical-line {
              @extend %vertical-line-base;
              display: none;
              @media screen and (max-width: $breakpoint-pc) {
                display: block;
              }
            }
            .step {
              background: $neutral-white;
              border-radius: 12px;
              box-shadow: $shadow-card;
              display: flex;
              flex-direction: column;
              padding: 12px 16px;
              row-gap: 4px;
              z-index: 0;
              @media screen and (max-width: $breakpoint-tablet) {
                padding: 8px 16px;
              }
              &:not(:last-child) {
                @extend %pseudo-element-icon-base;
                &:before {
                  $icon_size: 32px;
                  background: url("/images/ico_chevron-down_gray.svg") no-repeat center;
                  bottom: -40px; //NOTE: iconの32pxと元々あったgapの8px
                  height: $icon_size;
                  left: 48px;
                  width: $icon_size;
                  @media screen and (max-width: $breakpoint-pc) {
                    content: none;
                  }
                }
              }
              .step__title {
                @extend %h3-text-base;
                @media screen and (max-width: $breakpoint-tablet) {
                  font-size: rem(18);
                }
              }
              .step__description {
                line-height: 175%;
                letter-spacing: 0.01rem;
                color: $neutral-solid-700;
              }
            }
          }
          .important-point {
            // NOTE: タブレットサイズ以下でポイントを解説するパーツのスタイル
            display: none;
            border: 2px dashed $mofmof-blue-400;
            border-radius: 12px;
            padding: 12px 16px;
            margin-left: 64px;
            box-sizing: border-box;
            @media screen and (max-width: $breakpoint-pc) {
              display: block;
            }
            .important-point__title {
              @extend %h3-text-base;
              align-items: center;
              column-gap: 4px;
              display: flex;
              @include text-with-icon-setting($icon_width: 20px, $icon_height: 20px, $gap_width: 4px, $image_path: "/images/ico_pencil_primary.svg");
            }
            .important-point__description {
              line-height: 175%;
              color: $neutral-solid-700;
              letter-spacing: 0.01rem;
            }
          }
          .balloon {
            @media screen and (max-width: $breakpoint-pc) {
              display: none;
            }
            .balloon__text {
              position: absolute;
              top: 88px;
              left: 108px;
              display: flex;
              flex-direction: column;
              row-gap: 8px;
              align-items: center;
              width: 196px;
              .balloon__text--title {
                @extend %eyecatch-text-base;
                font-size: rem(20);
              }
              .balloon__text--description {
                line-height: 175%;
                color: $neutral-solid-700;
                text-align: center;
                letter-spacing: 0.01rem;
              }
            }
          }
        }
      }
      .before {
        .contents {
          display: flex;
          column-gap: 43px;
          @media screen and (max-width: $breakpoint-pc) {
            flex-direction: column;
          }
          .steps {
            position: relative;
            display: flex;
            flex-direction: column;
            row-gap: 48px;
            max-width: 480px;
            &:before {
              content: '01';
            }
            @media screen and (max-width: $breakpoint-pc) {
              row-gap: 24px;
              max-width: 600px;
            }
          }
          .additional-contents {
            background-image: url("/images/service/dev-team-rental/startup/process/flow-first-image@2x.webp");
            background-position: right bottom 32px;
            background-repeat: no-repeat;
            background-size: 100%;
            @media screen and (max-width: $breakpoint-pc) {
              background-image: url("/images/service/dev-team-rental/startup/process/flow-first-image@1x.png");
              background-position: center;
              background-size: contain;
              height: 256px;
            }
            @media screen and (max-width: $breakpoint-tablet) {
              height: 230px;
            }
            .balloon {
              background: url("/images/service/dev-team-rental/startup/process/balloon-image.svg") no-repeat;
              background-size: cover;
              height: 358px;
              position: relative;
              top: 24px;
              width: 382px;
            }
          }
        }
      }
      .during {
        .contents {
          display: flex;
          flex-direction: column;
          row-gap: 24px;
          .steps__outer {
            position: relative;
            .steps__number {
              left: 102px;
            }
            .loop {
              align-items: center;
              display: flex;
              flex-direction: column;
              left: -275px;
              position: absolute;
              top: 43%;
              transform: rotate(270deg);
              @media screen and (max-width: $breakpoint-pc) {
                left: -200px;
                top: 40%
              }
              @media screen and (max-width: $breakpoint-tablet) {
                left: -260px;
              }
              .loop--text {
                margin-bottom: 8px;
                text-align: center;
                .break {
                  @media screen and (max-width: $breakpoint-tablet) {
                    display: none;
                  }
                }
              }
              .loop--arrow {
                border-left: 2px dashed $primary-color;
                border-right: 2px dashed $primary-color;
                border-top: 2px dashed $primary-color;
                height: 21px;
                position: relative;
                width: 420px;
                @media screen and (max-width: $breakpoint-pc) {
                  width: 460px;
                }
                @media screen and (max-width: $breakpoint-tablet) {
                  width: 560px;
                }
                &::after {
                  content: '';
                  position: absolute;
                  top: 20px;
                  right: -6px;
                  border: 5px solid transparent;
                  border-top: 8px solid $primary-color;
                }
              }
            }
            .steps-and-check-points {
              position: relative;
              display: flex;
              flex-direction: column;
              row-gap: 48px; //NOTE: iconの32pxと元々あったgapの8px*2
              &:before {
                content: '02';
              }
              @media screen and (max-width: $breakpoint-pc) {
                row-gap: 24px;
                max-width: 600px;
                margin-left: 100px;
              }
              @media screen and (max-width: $breakpoint-pc) {
                margin-left: 64px;
              }
              .vertical-line {
                left: 24px;
                height: 80%;
              }
              .step-and-check-point {
                display: flex;
                align-items: center;
                column-gap: 24px;
                max-width: 800px;
                @media screen and (max-width: $breakpoint-pc) {
                  flex-direction: column;
                  align-items: start;
                  row-gap: 4px;
                }
                &:last-child {
                  .step {
                    &:before {
                      content: none;
                    }
                  }
                }
                .step {
                  width: 400px;
                  @media screen and (max-width: $breakpoint-pc) {
                    width: 100%;
                  }
                  .step--title {
                    @media screen and (max-width: $breakpoint-pc) {
                      font-size: rem(18);
                    }
                  }
                }
                .check-point {
                  line-height: 175%;
                  letter-spacing: 0.01rem;
                  color: $neutral-solid-700;
                  max-width: calc(50% - 24px);
                  @include text-with-icon-setting($icon_width: 20px, $icon_height: 24px, $gap_width: 8px, $image_path: "/images/ico_check_primary.svg");
                  &:before {
                    top: 4px;
                  }
                  @media screen and (max-width: $breakpoint-pc) {
                    max-width: 100%;
                    margin-left: 72px;
                  }
                  @media screen and (max-width: $breakpoint-tablet) {
                    font-size: rem(14);
                  }
                }
              }
            }
          }
          .additional-contents {
            background-image: url("/images/service/dev-team-rental/startup/process/flow-second-image@2x.webp");
            background-position: left 25% top 0;
            background-repeat: no-repeat;
            background-size: auto 220px;
            height: 240px;
            position: relative;
            @media screen and (max-width: $breakpoint-pc) {
              background-image: url("/images/service/dev-team-rental/startup/process/flow-second-image@1x.png");
              background-position: bottom;
              height: 350px;
            }
            @media screen and (max-width: $breakpoint-tablet) {
              height: 380px;
            }
            .important-point {
              max-width: 600px;
              margin-left: 93px;
              @media screen and (max-width: $breakpoint-tablet) {
                max-width: 100%;
                margin-left: 0px;
              }
            }
            .balloon {
              background: url("/images/service/dev-team-rental/startup/process/balloon-image-2.svg") no-repeat;
              background-size: cover;
              height: 405px;
              position: absolute;
              top: -72px;
              right: -120px;
              width: 404px;
              .balloon__text {
                top: 116px;
                left: 114px;
              }
            }
          }
        }
      }
      .after {
        row-gap: unset;
        .title {
          position: absolute;
          top: 28px;
          @media screen and (max-width: $breakpoint-pc) {
            margin-bottom: 40px;
            position: static;
          }
        }
        .contents {
          background: url("/images/service/dev-team-rental/startup/process/flow-third-image-left@2x.webp"),
                      url("/images/service/dev-team-rental/startup/process/flow-third-image-right@2x.webp");
          background-position: left top 0, right top 0;
          background-repeat: no-repeat;
          background-size: 226px;
          display: flex;
          height: 200px;
          justify-content: center;
          max-width: 1028px;
          position: relative;
          width: 100%;
          @media screen and (max-width: $breakpoint-pc) {
            display: unset;
            background: url("/images/service/dev-team-rental/startup/process/flow-third-image-left@1x.png");
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: 343px;
            height: 360px;
          }
          @media screen and (max-width: $breakpoint-tablet) {
            height: 380px;
          }
          
          .steps {
            align-self: flex-end;
            position: relative;
            display: flex;
            justify-content: center;
            max-width: 434px;
            width: 100%;
            &:before {
              content: '03';
              left: 0;
            }
            @media screen and (max-width: $breakpoint-pc) {
              row-gap: 24px;
              max-width: 100%;
            }
            .steps__number {
              left: 0px;
            }
            .step {
              width: 100%;
            }
          }
          .illust__left-sp {
            display: none;
            width: 100%;
            max-width: 343px;
            @media screen and (max-width: $breakpoint-pc) {
              display: block;
            }
          }
        }
      }
    }
  }

  .works {
    background-color: $mofmof-blue-100;
    background-image: url("/images/service/dev-team-rental/shared/splitter-neutral-solid-50.png"),
                      url("/images/service/works/bg-object.svg");
    background-repeat: no-repeat, no-repeat;
    background-size: contain, cover;
    background-position: bottom, center;
    padding: 64px 0px 112px;
    @media screen and (max-width: $breakpoint-pc) {
      padding: 56px 0px 92px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 48px 0px 72px;
    }
    .contents {
      .text-with-forward-icon {
        line-height: 1;
        position: relative;
        &:after {
          $icon_size: 16px;
          content: '';
          display: inline-block;
          width: $icon_size;
          height: $icon_size;
          background: url("/images/ico_chevron-right_primary.svg") no-repeat center;
          position: absolute;
          top: calc(50% - #{$icon_size} * 0.5);
          right: - 24px;
        }
      }
      .top-content {
        .works__slider {
          margin: 0px;
          .works__slider--item {
            display: flex;
            flex-direction: column;
            width: 336px;
            height: 488px;
            padding: 8px;
            background: $neutral-white;
            border-radius: 12px;
            box-shadow: $shadow-card;
            @media screen and (max-width: $breakpoint-pc) {
              width: 304px;
              height: 470px;
            }
            @media screen and (max-width: $breakpoint-tablet) {
              width: 288px;
              height: 452px;
            }
            .image {
              border: 1px solid $neutral-solid-50;
              border-radius: 12px;
              width: 100%;
            }
            .info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              height: 100%;
              padding: 12px 16px 16px;
              @media screen and (max-width: $breakpoint-pc) {
                padding: 12px;
              }
              @media screen and (max-width: $breakpoint-tablet) {
                padding: 12px 8px 8px;
              }
              .project-name {
                display: flex;
                flex-direction: column;
                padding-bottom: 16px;
                border-bottom: 1px solid $neutral-solid-50;
                .project-name__title {
                  font-weight: 700;
                  line-height: 175%;
                  letter-spacing: 0.02rem;
                  color: $mofmof-blue-600;
                }
                .project-name__description {
                  font-size: rem(14);
                  line-height: 175%;
                  letter-spacing: 0.01rem;
                  color: $neutral-solid-700;
                }
              }
              .client-info {
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                .client-info__company {
                  font-size: rem(14);
                  font-weight: 700;
                  line-height: 150%;
                  letter-spacing: 0.03rem;
                  color: $neutral-solid-700;
                }
                .client-info__interviewee {
                  font-size: rem(12);
                  line-height: 175%;
                  letter-spacing: 0.01rem;
                  color: $neutral-solid-700;
                }
                .client-info__button {
                  align-items: center;
                  background: $mofmof-blue-50;
                  border-radius: 24px;
                  box-sizing: border-box;
                  color: $mofmof-blue-600;
                  column-gap: 8px;
                  cursor: pointer;
                  display: flex;
                  font-size: rem(14);
                  font-weight: 700;
                  height: 40px;
                  margin-top: 12px;
                  padding: 0px 36px 0px 44px;
                  position: relative;
                  text-decoration: none;
                  width: 100%;
                  &:hover {
                    background: $mofmof-blue-100;
                    transition: 0.2s;
                  }
                  @media screen and (max-width: $breakpoint-pc) {
                    padding: 0px 32px 0px 40px;
                  }
                  @media screen and (max-width: $breakpoint-tablet) {
                    padding: 0px 28px 0px 36px;
                  }
                }
              }
            }
          }
          .works__slider--item.slick-slide {
            margin: 0px 16px;
            @media screen and (max-width: $breakpoint-pc) {
              margin: 0px 14px;
            }
            @media screen and (max-width: $breakpoint-tablet) {
              margin: 0px 12px;
            }
          }
          .slick-next,
          .slick-prev {
            background-color: $primary-color;
            border-radius: 50%;
            color: $text-color-white;
            height: 40px;
            width: 40px;
            box-shadow: $shadow-btn;
            &:hover {
              background-color: $primary-color-hover;
            }
          }
          .slick-dots {
            bottom: -40px;
            @media screen and (max-width: $breakpoint-pc) {
              bottom: -38px;
            }
            @media screen and (max-width: $breakpoint-tablet) {
              bottom: -36px;
            }
            button:before {
              color: $mofmof-blue-200;
            }
            .slick-active button:before {
              color: $mofmof-blue-600;
            }
          }
        }
        .interview {
          position: relative;
          display: flex;
          justify-content: end;
          width: 100%;
          padding: 24px 48px;
          z-index: 1;
          box-sizing: border-box;
          @media screen and (max-width: $breakpoint-pc) {
            justify-content: center;
            padding: 60px 0px;
          }
          @media screen and (max-width: $breakpoint-tablet) {
            padding: 52px 0px;
          }
          .interview__link {
            display: flex;
            column-gap: 6px;
            font-weight: 700;
            color: $mofmof-blue-600;
            text-decoration: none;
            cursor: pointer;
            .text-with-forward-icon {
              right: 28px;
            }
          }
        }
      }
      .banner-and-guide {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 24px;
        z-index: 1;
        width: 100%;
        padding: 0px 16px;
        box-sizing: border-box;
        .banner {
          width: 100%;
          max-width: 640px;
          .banner__image {
            width: 100%;
          }
        }
      }
    }
  }

  .skilled-at {
    background: $neutral-solid-50;
    @include section-splitter-setting("neutral-solid-30");
    .contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      .technologies {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        padding: 0px 24px 48px;
        width: 100%;
        max-width: 1076px;
        box-sizing: border-box;
        @media screen and (max-width: 1020px) {
          padding: 0px 0px 48px;
        }
        .technologies__title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: rem(24);
          font-weight: 700;
          line-height: 175%;
          color: $neutral-solid-700;
          width: 100%;
        }
      }
      .experienced-field {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        padding: 0px 24px;
        width: 100%;
        max-width: 1076px;
        box-sizing: border-box;
        @media screen and (max-width: 1020px) {
          padding: 0px;
        }
        .experienced-field__title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: rem(24);
          font-weight: 700;
          line-height: 175%;
          color: $neutral-solid-700;
          width: 100%;
        }
      }
    }
  }

  .flow {
    background: $neutral-solid-30;
    @include section-splitter-setting("mofmof-blue-100");
    .contents {
      display: flex;
      justify-content: center;
      .flow-diagram {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 48px;
        width: 100%;
        max-width: 760px;
        @media screen and (max-width: $breakpoint-tablet) {
          row-gap: 24px;
        }
        .vertical-line {
          @extend %vertical-line-base;
          left: 160px;
          @media screen and (max-width: $breakpoint-tablet) {
            left: 95px;
            margin-top: 50px;
          }
        }
        .flow-diagram__items {
          display: flex;
          column-gap: 32px;
          align-items: start;
          width: 100%;
          z-index: 0;
          @media screen and (max-width: $breakpoint-pc) {
            column-gap: 24px;
          }
          @media screen and (max-width: $breakpoint-tablet) {
            column-gap: 16px;
          }
          .image-and-index {
            align-items: start;
            display: flex;
            justify-content: space-between;
            min-width: 269px;
            width: 269px;
            @media screen and (max-width: $breakpoint-tablet) {
              flex-direction: column;
              justify-content: start;
              min-width: initial;
              row-gap: 16px;
              width: initial;
            }
            .index {
              @extend %eyecatch-text-base;
              color: $mofmof-blue-400;
              font-size: rem(48);
              letter-spacing: 0.075rem;
              line-height: 100%;
              @media screen and (max-width: $breakpoint-tablet) {
                border-bottom: 2px solid $mofmof-blue-600;
                color: $mofmof-blue-600;
                font-size: rem(16);
                line-height: 24px;
              }
              .index__text {
                display: none;
                @media screen and (max-width: $breakpoint-tablet) {
                  display: inline !important;
                }
              }
            }
            .image-container {
              background: $neutral-white;
              border-radius: 12px;
              box-shadow: $shadow-card;
              height: 144px;
              width: 200px;
              @media screen and (max-width: $breakpoint-tablet) {
                height: 128px;
                width: 128px;
              }
              .image {
                border-radius: 12px;
                height: 144px;
                object-fit: cover;
                object-position: center;
                overflow: hidden;
                width: 200px;
                @media screen and (max-width: $breakpoint-tablet) {
                  height: 128px;
                  width: 128px;
                }
              }
            }
          }
          .description {
            align-items: start;
            display: flex;
            flex-direction: column;
            justify-content: start;
            row-gap: 4px;
            .description__title {
              color: $neutral-solid-700;
              font-size: rem(20);
              font-weight: 700;
              letter-spacing: 0.02rem;
              line-height: 150%;
            }
            .description__text {
              color: $neutral-solid-700;
              letter-spacing: 0.01rem;
              line-height: 175%;
            }
          }
        }
      }
    }
  }

  .contact-us {
    background: $mofmof-blue-100;
    @include section-splitter-setting("mofmof-blue-900");
    .contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 64px;
      .cards {
        display: flex;
        column-gap: 40px;
        width: 100%;
        max-width: 820px;
        @media screen and (max-width: 720px) {
          flex-direction: column;
          row-gap: 32px;
          max-width: 480px;
        }
        @media screen and (max-width: $breakpoint-tablet) {
          flex-direction: column;
          row-gap: 32px;
        }
        .card {
          align-items: center;
          background: $neutral-solid-30;
          border-radius: $border-radius-m;
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 24px;
          .card__image {
            width: 153px;
            height: 148px;
            margin-bottom: 20px;
          }
          .card__content {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 100%;
            .card__content--title {
              @extend %h3-text-base;
              color: $neutral-solid-700;
              letter-spacing: 0.02rem;
            }
            .card__content--description {
              color: $neutral-solid-700;
              letter-spacing: 0.01rem;
              line-height: 175%;
              margin-bottom: 24px;
              text-align: center;
            }
            .btn {
              height: 52px;
            }
          }
        }
      }
      .faq {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 8px;
        width: 100%;
        max-width: 820px;
        .faq__title {
          @extend %h3-text-base;
          letter-spacing: 0.02rem;
        }
        .faq__card {
          align-items: center;
          background: $neutral-solid-30;
          border-radius: 12px;
          box-sizing: border-box;
          column-gap: 24px;
          display: flex;
          padding: 24px;
          width: 100%;
          @media screen and (max-width: $breakpoint-pc) {
            flex-direction: column;
            row-gap: 24px;
            padding: 24px 20px;
          }
          @media screen and (max-width: 720px) {
            max-width: 480px;
          }
          @media screen and (max-width: $breakpoint-tablet) {
            row-gap: 24px;
            padding: 24px 16px;
          }
          .faq__card--image {
            width: 152px;
            height: 148px;
          }
          .categories {
            display: grid;
            gap: 24px 20px;
            grid-template-columns: 1fr 1fr;
            list-style-type: none;
            width: 100%;
            @media screen and (max-width: $breakpoint-tablet) {
              display: flex;
              flex-direction: column;

            }
            .categories__item {
              align-items: center;
              background: $neutral-white;
              border-radius: 26px;
              color: $mofmof-blue-600;
              display: flex;
              font-size: rem(14);
              font-weight: 700;
              height: 52px;
              justify-content: start;
              padding: 0px 28px;
              text-decoration: none;
              @media screen and (max-width: 720px) {
                width: 100%;
              }
              &:hover {
                background: $neutral-solid-50;
                transition: 0.2s;
              }
              .categories__item--link {
                @include text-with-icon-setting($icon_width: 16px, $icon_height: 16px, $gap_width: 8px, $image_path: "/images/ico_chevron-right-circle_primary.svg");
                color: $mofmof-blue-600;
                display: block;
                padding: 12px 0;
                width: 100%;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
