.faq {
  .main_visual {
    .main_img {
      background: url(/images/main_faq.png) no-repeat center center;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }

  &_contents {
    @media screen and (max-width: 1024px) {
      padding: 30px;
    }

    a {
      color: $text-link-color;
    }

    p {
      position: relative;
      padding-left: 50px;
      line-height: 2;
    }

    .question {
      position: absolute;
      top: 0;
      left: 0;
      border: solid 1px $primary-color;
      color: $primary-color;
      padding: 0 6px 0 8px;
    }

    dt {
      min-height: 34px;
      margin-bottom: 20px;
    }

    dd {
      position: relative;
      background: $background-color-old;
      padding: 26px 26px 26px 70px;
      margin-bottom: 30px;

      p {
        padding-left: 0;
        font-size: rem(13);
      }

      &:before {
        position: absolute;
        top: 28px;
        left: 30px;
        display: block;
        content: "";
        background: url(../images/logo/logo-symbol.svg) no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
      }
    }
  }
}
