.info_items {
  width: 100%;

  &:hover {
    color: $text-link-color;
  }
}

.info_items_inner {
  position: relative;
  margin: 0 auto;
  padding: 15px;
  background: $neutral-white;
  border: 1px solid #eee;
}

.info_item_box {
  position: relative;
  margin: 0 auto;
}

.info_img_trim {
  background: {
    position: center center;
    repeat: no-repeat;
    size: cover;
  };
  overflow: hidden;
  position: relative;
  padding-top: 66.667%;
}

.info_item_text {
  font-size: $small-font-size;
  font-weight: bold;
}
