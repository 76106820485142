.case {
  .main_visual {
    .main_img {
      background: url(/images/case/main_case.png) no-repeat center center;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }

  &_headline {
    font-size: $base-font-size;
    text-align: center;
    color: $primary-color;
    padding-bottom: 20px;
    font-family: $title-font-family;
    display: block;
    @media screen and (max-width: 1024px) {
      padding-bottom: 0;
    }
  }

  &_background {
    padding-bottom: 50px;
    span {
      color: $primary-color;
    }
    @media screen and (max-width: 1024px) {
      padding: 30px;
    }
  }

  &_cause {
    background: $background-color-old;
    padding: 40px;
    @media screen and (max-width: 1024px) {
      padding: 20px 30px;
      .cause, .reason {
        font-weight: normal;
        width: auto;
      }
      .cause {
        border-right: none;
      }
    }
  }

  &_cause-head {
    float: left;
    border-right: solid 1px $neutral-black;
    line-height: 42px;
    padding-right: 30px;
  }

  &_cause-body {
    float: right;
    list-style-type: none;
    width: 490px;
  }

  &_arrow {
    text-align: center;
    padding: 50px 0;
    @media screen and (max-width: 1024px) {
      padding: 20px 0;
    }

    img {
      width: 14px;
      height: 25px;
    }
  }

  &_item-box {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-image: linear-gradient(to right, $primary-color 55%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    padding: 50px 0;
    @media screen and (max-width: 1024px) {
      display: block;
      padding-bottom: 20px;
    }
  }


  &_check-box {
    position: relative;
    width: 180px;
    padding: 36px 30px;
    border: solid 1px $primary-color;

    &:after {
      display: block;
      content: "";
      position: absolute;
      width: 40px;
      height: 40px;
      top: -20px;
      left: 101px;
      background: url(/images/case/checkmark.svg) no-repeat;
      background-size: contain;
    }

    @media screen and (max-width: 1024px) {
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }


  &_label-round {
    border-radius: 20px 20px 20px 20px;
    background: $secondary-color;
    font-size: $base-font-size;
    width: 364px;
    height: 40px;
    margin: 0 auto;
    margin-bottom: 37px;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
  }

  &_voice {
    padding: 40px;
    margin-bottom: 70px;
    background: $background-color-old;
  }

  &_voice-txt {
    float: left;
    width: 540px;
  }

  &_voice-img {
    text-align: center;
    float: right;
    color: $primary-color;

    span {
      display: block;
      text-align: center;
      padding-top: 25px;
      @media screen and (max-width: 1024px) {
        padding-top: 10px;
      }
    }

    img {
      width: 73px;
      height: 108px;
    }
  }

  @media screen and (max-width: 1024px) {
    &_voice-txt, &_voice-img {
      font-weight: normal;
      width: auto;
    }
  }
}
