.client-interview {
  .main_visual {
    .main_img {
      background: url(/images/interview/main_interview.png) no-repeat center center;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }
}

.member_interview {
  .main_visual {
    .main_img {
      background: url(/images/main_member_interview.jpg) no-repeat center center;
      background-size: cover;
      width: auto;
      height: 100%;
    }
  }
}

.interview {
  .interview_item_list {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    @media screen and (max-width: 1024px) {
      max-width: 480px;
      margin: 0 auto;
      padding: 30px;
    }
  }

  .interview_item {
    position: relative;
    width: 480px;
    margin-bottom: 50px;
    @media screen and (max-width: 1024px) {
      min-height: 150px;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 420px) {
      min-height: 180px;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 380px) {
      min-height: 200px;
    }
  }

  .interview_item_img {
    float: left;
    padding-right: 30px;
    width: 150px;
    height: 145px;

    img {
      width: 150px;
    }

    @media screen and (max-width: 1024px) {
      width: 150px;
      height: 150px;
      padding-right: 10px;
    }
  }

  .interview_label {
    position: absolute;
    top: 20px;
    left: 120px;
    background: $primary-color;
    padding: 6px 17px;
    color: $text-color-white;
    margin-bottom: 40px;
    @media screen and (max-width: 1024px) {
      position: relative;
      top: auto;
      left: auto;
      padding: 3px 14px;
      font-size: rem(10);
      margin-bottom: 10px;
    }
  }

  .interview_comment_wrap {
    padding-top: 77px;
    padding-left: 180px;
    @media screen and (max-width: 1024px) {
      padding-left: 160px;
      font-size: rem(12);
    }
    @media screen and (max-width: 330px) {
      padding-top: 0px;
    }
  }

  .interview_comment {
    margin-bottom: 10px;
    @media screen and (max-width: 1024px) {
      top: 20px;
      width: auto;
    }
    @media screen and (max-width: 330px) {
      top: 0px;
    }
  }

  .interview_name {
    font-size: rem(12);
    @media screen and (max-width: 1024px) {
      font-size: rem(10);
      text-align: right;
      margin-right: 10px;
    }
    @media screen and (max-width: 420px) {
      position: absolute;
      font-size: rem(10);
      text-align: left;
      top: 160px;
      left: 10px;
    }
    @media screen and (max-width: 330px) {
      top: 180px;
    }
  }


  .interview_main {
    height: 500px;
  }

  .dialog_main {
    height: 550px;
  }

  .dialog_img {
    @media screen and (max-width: 1024px) {
      height: 420px;
    }
    @media screen and (max-width: 768px) {
      height: 230px;
    }
  }

  .interview_main, .dialog_main {
    position: relative;

    .interview_main_title {
      position: relative;
      width: 675px;
      margin: 0 auto;
      text-align: center;
      padding-top: 110px;
      padding-bottom: 40px;
      z-index: 2;
      font-size: rem(26);
      color: $primary-color;
      line-height: $base-line-height;
    }

    .interview_img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .interview_speaker {
      width: 400px;
      text-align: center;
      padding: 20px;
    }

    .interview_interviewer {
      width: 300px;
      text-align: right;
      padding: 20px;
    }

    @media screen and (max-width: 1024px) {
      position: relative;
      height: auto;
      padding-bottom: 20px;
      .interview_title {
      }
      .interview_img {
        padding: 80px 0;
        position: relative;
        height: 100px;
      }
      .interview_main_title {
        position: absolute;
        top: 0;
        width: 74%;
        font-size: $base-font-size;
        padding: 40px 13%;
      }
      .interview_speaker {
        width: 100%;
        text-align: left;
        padding: 3px 20px;
      }
      .interview_interviewer {
        width: 100%;
        text-align: left;
        padding: 3px 20px;
      }
    }
  }

  .dialog_main {
    .interview_main_title {
      padding-top: 0;
      @media screen and (max-width: 1024px) {
        padding-top: 20px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .interview_main {
      .interview_img {
        padding-top: 0;
        padding-bottom: 45px;
      }

      .interview_main_title {
        padding: 20px 13%;
      }
    }
  }

  .interviewer_name {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    width: 1200px;
    position: absolute;
    bottom: 70px;
    text-shadow: 2px 2px 3px $neutral-white;
    @media screen and (max-width: 1024px) {
      width: auto;
      bottom: 0;
    }
  }
}

.interview_banner {
  position: relative;
  min-height: 300px;

  .interview_title {
    position: relative;
    width: 350px;
    margin: 0 auto;
    padding-top: 80px;
    z-index: 2;

    h2 {
      text-align: center;
      font-size: rem(13);
      margin-bottom: 14px;
    }

    span {
      font-size: rem(10);
    }
  }

  .interview_post_title {
    font-size: rem(21);
    color: $primary-color;
  }

  .interview_img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .interview_interviewer, .interview_speaker {
    z-index: 2;
  }

  .interview_interviewer {
    position: absolute;
    bottom: 20px;
    left: 30px;
  }

  .interview_speaker {
    position: absolute;
    bottom: 20px;
    right: 30px;
  }
}

.interview_img {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(/images/interview/interview_base.png) no-repeat center;
  background-size: cover;
}

@media screen and (max-width: 1024px) {
  .interview_banner {
    // top: 80px;
    height: auto;

    .interview_title {
      width: auto;
      position: relative;
      padding: 0 30px;

      h2 {
        float: left;
        width: 55px;
        padding: 5px 1px;
        font-size: rem(13);
        color: $text-color-white;
        background: $primary-color;
      }
    }

    .interview_img {
      padding: 0 30px 10px 30px;
      position: relative;
    }

    .interview_post_title {
      float: right;
      width: 80%;
      font-size: $base-font-size;
      line-height: 1.4;
    }

    .interview_interviewer, .interview_speaker {
      position: relative;
      left: auto;
      right: auto;
      bottom: auto;
      padding: 0 30px;
      padding-top: 12px;
      font-size: rem(12);
    }
  }
  .home {
    .interview_banner {
      .interview_img {
        padding: 0;
      }
    }
  }
}
