.client-interview {
  * {
    box-sizing: border-box;
  }
  .container02 {
    padding: 0 24px;
  }
  .client-interview_title {
    position: relative;
    margin-top: 110px;
    margin-bottom: 60px;
    &::before {
      content: '';
      position: absolute;
      top: -160px;
      left: 0;
      right: 0;
      margin: auto;
      width: 1px;
      height: 100px;
      background: $primary-color;
    }
    @media screen and (max-width: 1024px) {
      margin-top: 70px;
      margin-bottom: 40px;
      font-size: rem(34);
      span {
        font-size: $small-font-size;
      }
      &::before {
        top: -100px;
        height: 60px;
      }
    }
  }

  .client-interview_item_list {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 48px;
  }

  .client-interview_item {
    position: relative;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 40px;
    @media screen and (max-width: 1024px) {
      border-right: none;
      padding-bototm: 16px;
    }
  }

  .client-interview_item_bg {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 80px);
    height: 100%;
    background: $background-color-old;
  }

  .client-interview_item_label {
    position: absolute;
    bottom: -20px;
    left: 0;
    z-index: 2;
    padding: 10px 30px;
    color: $text-color-white;
    font-size: rem(18);
    &.rental {
      background: #c3443e;
    }
    &.mvp {
      background: $secondary-color;
      color: $text-color;
    }
    &.share {
      background: $primary-color;
    }
    &.product-team-plus {
      background: $primary-color;
    }
    @media screen and (max-width: 1024px) {
      left: -20px;
    }
  }

  .client-interview_item_container {
    position: relative;
    z-index: 1;
    display: flex;
    @media screen and (max-width: 1024px) {
      display: block;
      padding-left: 20px;
    }
  }

  .client-interview_item_container_main-content {
    padding-top: 65px;
    padding-right: 30px;
    @media screen and (max-width: 1024px) {
      padding-top: 0;
      padding-right: 36px;
    }
  }

  .client-interview_item_image-wrap {
    position: relative;
    height: 100%;
    @media screen and (max-width: 1024px) {
      padding-top: 40px;
      margin-bottom: 40px;
    }
    .client-interview_item_image-wrap_image {
      width: 290px;
      height: 218px;
      margin: 40px 30px 0;
      background-size: cover;
      background-position: center;
      @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 290px;
        margin-top: 0;
        margin-left: 0;
      }
    }
  }
  .client-interview_item_link:hover {
    .client-interview_item_num,
    .client-interview_item_comment,
    .client-interview_item_name {
      color: $primary-color;
      text-decoration: underline;
    }
    .client-interview_item_date {
      text-decoration: none;
    }
  }

  .client-interview_item_num {
    margin-bottom: 20px;
  }

  .client-interview_item_comment {
    margin-bottom: 10px;
    font-size: rem(24);
    line-height: 34px;
  }

  .client-interview_item_date {
    margin-top: 20px;
    color: #888;
  }
}
