.development-team-rental.startup {
  .issue {
    background-color: $neutral-solid-50;
    @include section-splitter-setting("neutral-solid-30");
    .content {
      align-items: center;
      column-gap: 32px;
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
      @media screen and (max-width: $breakpoint-pc) {
        flex-direction: column;
      }
      .content__left {
        margin-bottom: 12px;
        .content__left--image {
          width: 283px;
        }
      }
      .content__right {
        max-width: 635px;
        .list {
          display: flex;
          flex-direction: column;
          row-gap: 26px;
          @media screen and (max-width: $breakpoint-tablet) {
            row-gap: 16px;
          }
          .list__item {
            background: $neutral-white;
            border-radius: 32px 32px 32px 0px;
            box-shadow: $shadow-card;
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 16px 32px;
            row-gap: 4px;
            @media screen and (max-width: $breakpoint-tablet) {
              padding: 16px;
            }
            .list__item--title {
              $icon_size: 24px;
              $gap_size: 12px;
              @extend %h3-text-base;
              margin-left: calc(#{$icon_size} + #{$gap_size});
              position: relative;
              @media screen and (max-width: $breakpoint-tablet) {
                font-size: rem(18);
              }
              &:before {
                position: absolute;
                background: url("/images/ico_baloon_primary.svg") no-repeat center;
                content: '';
                display: block;
                height: $icon_size;
                left: calc(-#{$icon_size} - #{$gap_size});
                top: calc(#{50% - #{$icon_size} * 0.5});
                width: $icon_size;
              }
            }
            .list__item--description {
              line-height: 175%;
              letter-spacing: 0.01rem;
              color: $neutral-solid-700;
            }
          }
          .list__item:nth-child(1) {
            margin-left: 0px;
            margin-right: 27px;
            @media screen and (max-width: $breakpoint-tablet) {
              margin-right: 0px;
            }
          }
          .list__item:nth-child(2) {
            margin-left: 48px;
            margin-right: 0px;
            @media screen and (max-width: $breakpoint-tablet) {
              margin-left: 0px;
            }
          }
          .list__item:nth-child(3) {
            margin-left: 16px;
            margin-right: 32px;
            @media screen and (max-width: $breakpoint-tablet) {
              margin-left: 0px;
              margin-right: 0px;
            }
          }
        }
      }
    }
    .connecter {
      @extend %h3-text-base;
      letter-spacing: 0.02rem;
      margin-bottom: 32px;
      text-align: center;
      @media screen and (max-width: $breakpoint-pc) {
        font-size: rem(18);
      }
      @media screen and (max-width: $breakpoint-tablet) {
        font-size: rem(16);
      }
    }
  }

  .feature {
    background: $neutral-solid-30;
    @include section-splitter-setting("neutral-solid-50");
    .content {
      display: flex;
      justify-content: center;
      .content__list {
        display: flex;
        column-gap: 32px;
        width: 100%;
        max-width: 1144px;
        padding: 48px 34px 0px;
        @media screen and (max-width: $breakpoint-xl) {
          padding: 48px 0px 0px;
        }
        @media screen and (max-width: $breakpoint-pc) {
          flex-direction: column;
          row-gap: 72px;
          max-width: 640px;
        }
        .content__list--item {
          position: relative;
          display: flex;
          flex: 1;
          flex-direction: column;
          row-gap: 12px;
          height: fit-content;
          padding: 32px 24px 16px;
          background: $neutral-white;
          border-radius: $border-radius-m;
          box-shadow: $shadow-card;
          @media screen and (max-width: $breakpoint-pc) {
            padding: 32px 20px 16px;
          }
          @media screen and (max-width: $breakpoint-tablet) {
            padding: 32px 16px 16px;
          }
          .number {
            position: absolute;
            top: -40px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            .number__label {
              @extend %eyecatch-text-base;
              font-size: rem(12);
              letter-spacing: 0.01rem;
              line-height: 1.0;
            }
            .number__text {
              @extend %eyecatch-text-base;
              font-size: rem(52);
              line-height: 1.0;
            }
          }
          .image {
            position: absolute;
            top: -50px;
            right: -36px;
            width: 80px;
            height: 112px;
            @media screen and (max-width: $breakpoint-pc) {
              right: -20px;
            }
            @media screen and (max-width: $breakpoint-tablet) {
              right: 0px;
            }
          }
          .title {
            @extend %h3-text-base;
            letter-spacing: 0.02rem;
            color: $neutral-solid-700;
            @media screen and (max-width: $breakpoint-tablet) {
              font-size: rem(18);
            }
          }
          .description {
            line-height: 175%;
            letter-spacing: 0.01rem;
            color: $neutral-solid-700;
          }
        }
      }
    }
  }

  .process {
    background: $neutral-solid-30;
    @include section-splitter-setting("mofmof-blue-100");
    
  }

  

  .service-footer {
    // NOTE: startupをリファクタリングした時の臨時措置。scaleupのリファクタリングしたら双方に共通のcssにする
    padding-top: 64px;
  }

  .pc-except {
    display: none;
    @media screen and (max-width: $breakpoint-pc) {
      display: block;
    }
  }

  .pc-except-inline {
    display: none;
    @media screen and (max-width: $breakpoint-pc) {
      display: inline-block;
    }
  }

  .tb-except {
    display: block;
    @media screen and (min-width: 561px) and (max-width: $breakpoint-pc) {
      display: none;
    }
  }

}
