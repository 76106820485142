.recruit-item {
  &_container {
    display: flex;
    justify-content: space-around;
    width: 900px;
    margin: 100px auto;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &_content {
    width: 49%;

    @media screen and (max-width: 1024px) {
      width: 90%;
      &--erasable {
        display: none;
      }
    }
  }

  &_ttl {
    font-size: $base-font-size;
    padding-bottom: 20px;
    color: $primary-color;
  }

  &_connpass-section {
    margin-bottom: 30px;
  }

  &_logo-wrapper {
    text-align: center;
  }

  &_event-logo {
    width: 60%;
    margin: 15px;
  }

  &_link-section {
    margin-bottom: 15px;
    text-align: center;
  }

  &_link {
    color: $text-link-color;
  }
}
