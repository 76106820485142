// もふもふ診断ページ ---------------------------
$right: $success-color;
$right-pale: $green-50;
$wrong: $attention-color;
$wrong-pale: $red-50;

// トップ
.mof-mof-assessment {
  .header_wrapper {
    height: 300px;
  }

  .section.beige {
    background: $background-color-old;
  }

  .mofmof-charactor {
    max-width: 1000px;
    margin: 0 auto;
    padding: 80px;
  }

  .assessment-menu {
    display: flex;
    gap: 50px;
    justify-content: space-between;
    width: 100%;

    .card {
      align-items: center;
      border: solid 2px $primary-color;
      border-radius: 10px;
      color: $primary-color;
      display: flex;
      flex-direction: column;
      padding: 50px 40px;
      width: 50%;

      .card__explanation {
        font-size: rem(18);
        margin-bottom: 15px;
      }

      .card__image {
        text-align: center;
        margin-bottom: 15px;

        .card__image--pic {
          width: 75%;
        }
      }

      .card__title {
        font-size: rem(30);
        font-weight: bold;
        letter-spacing: 2px;
      }

      .card__wrap {
        background: $background-color-old;
        padding: 20px;
        margin-bottom: 25px;

        p {
          color: $text-color;
          font-size: $small-font-size;
        }
      }

      .btn {
        width: 100%;
      }
    }
  }

  .page-title {
    margin-top: 24px;
    margin-bottom: 40px;
    line-height: 1.4;
    color: $primary-color;
    font-size: rem(60);
    text-align: center;
    letter-spacing: 2px;
  }

  .introduction {
    color: $primary-color;
    font-size: rem(20);
    line-height: 1.8;
    text-align: center;
  }
}

.quiz {
  color: $primary-color;

  &__section {
    padding: 80px 0;
    background: $background-color-old;
  }

  &__tit {
    text-align: center;
    font-size: rem(35);
    letter-spacing: 2px;
    margin-bottom: 50px;
  }

  // ボタン
  &__btns {
    justify-content: center;
  }

  &__btn {
    text-align: center;
    display: block;
    color: $text-color-white;
    border-radius: 99px;
    border: solid 1px $primary-color;
    background: $primary-color;
    width: 200px;
    padding: 0;
    box-sizing: border-box;
    font-weight: bold;
    margin: 0 10px;
    position: relative;
    font-size: rem(15);
    letter-spacing: 1px;
    line-height: 41px;

    &::before {
      display: block;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &:hover {
      opacity: 0.7;
      transition: 0.3s;
      text-decoration: none;
      color: $text-color-white;
    }

    &.disable {
      opacity: 0.5;
      cursor: no-drop;
      pointer-events: none;
    }
  }

  &__btn.outline {
    color: $primary-color;
    background: $neutral-white;
    &:hover {
      color: $primary-color;
    }
  }

  &__btn.previous::before {
    content: '←';
    left: 15px;
  }

  &__btn.next::before {
    content: '→';
    right: 15px;
  }

  &__btn.last {
    font-size: rem(22);
    width: 350px;
    line-height: 60px;
    margin: 15px auto 0;
  }

  // 解答ボックス
  .answer {
    background: $neutral-white;
    padding: 80px;
    width: 850px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;

    &__number {
      font-size: rem(40);
      position: absolute;
      left: 50px;
      top: -10px;
    }

    &__icon {
      width: 150px;
      position: absolute;
      right: 0;
      top: -80px;
    }

    &__tag {
      width: 115px;
      position: absolute;
      right: 125px;
      top: -70px;
    }

    &__tit {
      text-align: center;
      font-size: rem(20);
      letter-spacing: 1px;
      margin-bottom: 20px;
    }

    // ステータスバー（インジケーター）
    &__status {
      position: relative;
      width: 600px;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 40px;

      .bar {
        width: 100%;
        height: 10px;
        border-radius: 99px;
        border: solid 1px $primary-color;
        margin-bottom: 15px;
      }

      // ※要確認※ このクラスでステータスバーの進行度を変化させる？
      .running {
        $steps_count: 8;
        width: calc(1 / #{$steps_count} * 100%);
        height: 10px;
        background: $primary-color;
        border: solid 1px $primary-color;
        border-radius: 99px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &__list {
      list-style: none;
      margin-bottom: 50px;

      .item {
        position: relative;
        margin-bottom: 30px;
      }

      .label {
        position: absolute;
        top: -15px;
        left: 15px;
        display: inline-block;
        background-color: $primary-color;
        padding: 3px 8px;
        font-size: rem(13);
        color: $text-color-white;
        letter-spacing: 2px;
        font-weight: bold;
      }

      // コードボックス
      .code-box {
        background: #4d4d4d;
        color: $text-color-white;
        border-radius: 3px;
        margin-bottom: 15px;
      }

      // 選択肢
      .choice {
        display: block;
        width: 100%;
        border: solid 1px $primary-color;
        height: 60px;
        padding: 10px;
        box-sizing: border-box;
        position: relative;
        border-radius: 3px;

        &:hover {
          background: $mofmof-blue-50;
          transition: 0.3s;
        }

        // ※要確認※ 選択肢がチェックされたときのスタイル
        &.checked {
          .round {
            background: $secondary-color;
            position: relative;
          }

          .round:before {
            content: '\e876';
            font-family: 'Material Icons';
            font-size: rem(23);
            color: $primary-color;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%);
            transform: translate(-50%, -50%);
          }
        }

        input[type='radio'] {
          display: none;
        }

        .round {
          display: block;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          border: solid 2px $primary-color;
          background: $neutral-white;
          margin-right: 10px;
        }

        .txt {
          span:not(.normal-txt) {
            font-weight: bold;
            font-size: rem(17);
          }
          letter-spacing: 1px;
          font-size: $base-font-size;
        }

        // 正解不正解それぞれのラベル
        .label-answer {
          position: absolute;
          right: 10px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          border-radius: 50px;
          padding: 4px 8px;
          font-weight: bold;
          font-size: rem(13);

          .material-icons {
            font-size: rem(15);
            vertical-align: middle;
          }
        }

        .label-answer.right {
          color: $right;
          background: $right-pale;
        }

        .label-answer.wrong {
          color: $wrong;
          background: $wrong-pale;
        }
      }
    }
  }

  // 解説 -------------------------------
  .description {
    padding: 30px;
    box-sizing: border-box;
    background: $right-pale;
    border-radius: 10px;
    margin-bottom: 40px;

    &__tit {
      font-size: rem(30);
      font-weight: bold;
      text-align: center;
      color: $right;
      line-height: 1.5;

      &::before {
        content: '\e24e';
        font-family: 'Material Icons';
        font-size: rem(34);
        color: $primary-color;
        vertical-align: middle;
        color: $right;
      }

      span {
        font-size: $small-font-size;
        vertical-align: baseline;
      }
    }

    &__heading {
      border-bottom: dashed 1px $primary-color;
      margin-bottom: 15px;
      font-size: rem(18);
      padding: 10px 0;

      .material-icons {
        display: inline-block;
        margin-right: 5px;
        font-size: rem(18);
      }
    }

    &__txt {
      color: $text-color;
      a {
        color: $primary-color;
      }
    }

    &.wrong {
      background: $wrong-pale;

      .description__tit {
        color: $wrong;
        &::before {
          content: '\e814';
          color: $wrong;
        }
      }
    }
  }
}

// 診断結果
.result {
  
  &__section {
    background: $background-color-old;
    padding: 100px 0;
  }

  &__tit {
    position: relative;
    font-size: rem(30);
    background: $mofmof-blue-50;
    text-align: center;
    padding: 30px 0;
    border-bottom: solid 2px $primary-color;
    letter-spacing: 2px;

    .tit-balloon {
      position: absolute;
      left: 25%;
      top: -30px;
      width: 100px;
    }
  }

  &__box {
    width: 850px;
    margin: 0 auto;
    background: $neutral-white;
    border: solid 2px $primary-color;
  }

  &__contents {
    padding: 50px 70px;
  }

  .illust {
    width: 200px;
    text-align: center;
    margin-right: 30px;

    span {
      display: inline-block;
      background: $secondary-color;
      padding: 5px;
      font-weight: bold;
      width: 170px;
      margin-bottom: 10px;
      border-radius: 3px;
    }

    img {
      width: 200px;
    }
  }

  .percentage {
    font-size: rem(22);
    line-height: 0.3;
    padding-bottom: 30px;
    border-bottom: dashed 1px $primary-color;
    margin-bottom: 20px;

    span {
      font-size: rem(89);
    }
  }
  .description {
    color: $text-color;
    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  &__btns {
    margin: 10px 0 24px 0;

    .btn-wantedly {
      min-width: 350px;
      font-weight: bold;
      border-radius: 99px;
      background: linear-gradient(to right, #6ea6e0 50%, #75ced4);
      padding: 1.3rem 2rem;
      color: $text-color-white;
      font-size: rem(18);
      margin-bottom: 20px;

      &:after {
        content: '\e89e';
        font-family: 'Material Icons';
        color: $text-color-white;
        font-size: rem(18);
        vertical-align: bottom;
        padding-left: 10px;
      }

      &:hover {
        color: $text-color-white;
        text-decoration: none;
        opacity: 0.7;
        transition: 0.3s;
      }
    }

    .link {
      color: $primary-color;
      display: block;
    }
  }

  .result__sns-btns {
    background-color: $background-color-old;
    border-radius: 10px;
    padding: 24px 0;
    .txt {
      color: $primary-color;
      font-size: rem(18);
      font-weight: bold;
      margin-bottom: 16px;
    }
  }
}

// スマホのスタイル
@media screen and (max-width: 1024px) {
  .quiz {
    &__section {
      padding: 40px 0;
    }

    &__tit {
      font-size: rem(30);
      margin-bottom: 50px;
    }

    // ボタン
    &__btns {
      justify-content: center;
    }

    &__btn {
      width: 165px;
      font-size: rem(13);
      line-height: 41px;
    }

    &__btn.last {
      font-size: rem(18);
      width: 300px;
      line-height: 50px;
      margin: 15px auto 0;
    }

    // 解答ボックス -------------------------------
    .answer {
      padding: 50px 20px 50px 20px;
      width: 100%;

      &__number {
        font-size: rem(30);
        left: 30px;
      }

      &__icon {
        width: 100px;
        right: 0;
        top: -50px;
      }

      &__tag {
        width: 80px;
        position: absolute;
        right: 85px;
        top: -40px;
      }

      &__tit {
        font-size: rem(18);
        margin-bottom: 15px;
      }

      // ステータスバー（インジケーター）
      &__status {
        width: 80%;
        margin-bottom: 20px;

        .bar {
          margin-bottom: 10px;
        }
      }

      &__list {
        margin-bottom: 40px;

        .item {
          margin-bottom: 20px;
        }

        .label {
          top: -15px;
          left: 15px;
          font-size: rem(13);
        }

        // コードボックス
        .code-box {
          font-size: $small-font-size;
          letter-spacing: 0;

          code.hljs {
            padding: 0 !important;
          }
        }

        // 選択肢
        .choice {
          height: auto;

          // ※要確認※ 選択肢がチェックされたときのスタイル
          &.checked {
            .round {
              background: $secondary-color;
              position: relative;
            }

            .round:before {
              font-size: rem(20);
            }
          }

          .round {
            width: 30px;
            height: 30px;
          }

          .txt {
            span {
              font-size: rem(15);
            }
            width: calc(100% - 40px);
            letter-spacing: 0;
            font-size: $small-font-size;
            line-height: 1.5;
          }

          // 正解不正解それぞれのラベル
          .label-answer {
            position: absolute;
            right: 10px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            font-size: rem(13);
          }
        }
      }
    }

    // 解説 -------------------------------
    .description {
      padding: 20px;
      margin-bottom: 20px;

      &__tit {
        font-size: rem(25);

        &::before {
          font-size: rem(30);
        }

        span {
          font-size: $small-font-size;
          vertical-align: baseline;
        }
      }

      &__heading {
        margin-bottom: 10px;
        font-size: rem(15);

        .material-icons {
          font-size: rem(15);
        }
      }

      &__txt {
        font-size: $small-font-size;
        line-height: 1.7;
      }
    }
  }

  // 診断結果
  .result {
    &__section {
      padding: 50px 0;
    }

    &__tit {
      font-size: rem(20);
      padding: 20px 0;

      .tit-balloon {
        left: 25px;
        top: -25px;
        width: 65px;
      }
    }

    &__box {
      width: 90%;
    }

    &__contents {
      padding: 30px;

      .d-flex {
        display: block;
      }
    }

    .illust {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }

    .percentage {
      font-size: rem(20);
      line-height: 1.3;
      padding-bottom: 0;
      text-align: center;

      span {
        font-size: rem(70);
      }
    }

    &__btns {
      margin-top: 15px;

      .btn-wantedly {
        min-width: 100%;
        box-sizing: border-box;
        font-size: $base-font-size;
        margin-bottom: 10px;
      }
    }
  }

  // トップページ
  .mof-mof-assessment {
    .header {
      padding-bottom: 80px;
    }

    .header_wrapper {
      height: 70px;
    }

    .mofmof-charactor {
      padding: 30px;
    }

    .assessment-menu {
      display: block;

      .card {
        padding: 40px 30px;
        width: 70%;
        margin: 30px auto;

        .card__explanation {
          font-size: $base-font-size;
        }

        .card__title {
          font-size: rem(25);
        }

        .btn {
          font-size: $base-font-size;
        }
      }
    }

    .page-title {
      font-size: rem(35);
      margin-bottom: 25px;
    }

    .introduction {
      color: $primary-color;
      font-size: $base-font-size;
      max-width: 90%;
      margin: 0 auto;
    }
  }
}
