// /interviews, /interviewの記事カード
$width-l: 560px; // 横レイアウトになる閾値
$width-xl: 960px;
.interview-card-container {
  container-type: inline-size;
  transition: 0.3s;
  // display: contents;
  &:hover {
    text-decoration: none;
    color: inherit;
    .interview-card__cover-image {
      scale: 1.05;
      opacity: 0.8;
    }
    .interview-card__title {
      color: $mofmof-blue-600;
    }
  }
}
.interview-card {
  //container-type: inline-size;
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr auto; // mid-blockだけ伸縮させる
  gap: 16px;
  transition: 0.3s;
  > * {
    min-width: 0;
  }
  @container (width >= #{$width-l}) {
    grid-template-columns: 40% 1fr;
    grid-template-rows: auto auto;
  }
  @container (width >= #{$width-xl}) {
    grid-template-columns: 1fr 50%;
    grid-template-rows: auto 1fr;
    gap: 8px 40px;
  }
}
.interview-card--reverse {
  @extend .interview-card;
  @container (width >= #{$width-l}) {
    grid-template-columns: 1fr 40%;
  }
  @container (width >= #{$width-xl}) {
    grid-template-columns: 50% 1fr;
  }
}
.interview-card__image-block {
  position: relative;
  width: 100%;
  padding-top: 50%;
  grid-column: 1 / 3;
  @container (width >= #{$width-l}) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
}
.interview-card__image-block--reverse {
  @extend .interview-card__image-block;
  @container (width >= #{$width-l}) {
    grid-column: 2 / 3;
  }
}
.interview-card__cover-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-m;
  overflow: hidden;
}
.interview-card__cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s;
}
.interview-card__logo-image {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: $neutral-white;
  padding: 12px;
  border-radius: $border-radius-m 0 0 0;
  width: 180/556 * 100%;
  max-height: 100/280 * 100%;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.3s ease;
  &.hidden {
    display: none;
  }
}

.interview-card__mid-block {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  grid-column: 1 / 3;
  @container (width >= #{$width-l}) {
    grid-column: 2 / 3;
  }
  @container (width >= #{$width-xl}) {
    padding-top: 24px;
  }
}
.interview-card__mid-block--reverse {
  @extend .interview-card__mid-block;
  @container (width >= #{$width-l}) {
    grid-column: 1 / 2;
  }
}
.interview-card__title {
  font-size: rem(28);
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.04em;
  transition: color 0.3s;
  // NOTE: コンテナクエリ cardの幅に依存
  @container (width >= #{$width-l}) {
    font-size: rem(20);
  }
  @container (width >= #{$width-xl}) {
    font-size: rem(24);
  }
  @container (width <= 520px) {
    font-size: rem(24);
  }
  @container (width <= 400px) {
    font-size: rem(20);
  }
  @container (width <= 300px) {
    font-size: rem(16);
  }
}
.interview-card__company-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-column: 1 / 3;
  @container (width >= #{$width-l}) {
    grid-column: 2 / 3;
  }
  @container (width <= 300px) {
    gap: 4px;
  }
}
.interview-card__company-info--reverse {
  @extend .interview-card__company-info;
  @container (width >= #{$width-l}) {
    grid-column: 1 / 2;
  }
}
.interview-card__company-name {
  font-size: rem(14);
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.04em;
  @container (width <= 300px) {
    font-size: rem(12);
  }
}
.interview-card__company-business,
.interview-card__company-size {
  color: $neutral-solid-600;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: rem(14);
  font-weight: 500;
  line-height: 1.8;
  > i {
    font-size: rem(20);
  }
  > span {
    white-space: nowrap; // 横幅のMAXに達しても改行しない
    overflow: hidden; // ハミ出した部分を隠す
    text-overflow: ellipsis; // 「…」と省略
  }
  @container (width <= 300px) {
    font-size: rem(12);
    > i {
      font-size: rem(16);
    }
  }
}
