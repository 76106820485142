// 1列に3アイテム並べるpostのindex
.post-items {
  width: 100%;
  margin: 0 auto;

  &_item {
    display: block;
    float: left;
    width: 32.083333%;
    margin: 0 .625% 20px;

    @media screen and (max-width: 1024px) {
      float: none;
      width: 100%;
      margin: 0 0 20px;
      box-sizing: border-box;
    }

    &:hover {
      text-decoration: none;

      .info_item_text {
        color: $primary-color;
      }

      .info_img_trim {
        opacity: .7;
      }
    }
  }

  &_date-box {
    color: $primary-color;
    font-size: rem(12);
    margin: 10px 0 5px;
  }

  &_info-box {
    color: $text-color-light;
    margin: 10px 0 5px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }

  &_date {
    color: $primary-color;
    font-size: rem(12);
    margin-right: 16px;
  }

  &_tags {
    font-size: $small-font-size;
    font-weight: bold;

    a {
      color: $text-color-light;
      text-decoration: none;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .post-items {
    width: 100%;
    max-width: 340px;
  }
  .info_items {
    position: relative;
    height: auto;
  }
  .info_item_box {
    width: auto;
    top: auto;
  }
}
