// _parts.scss

.breadcrumbs {
  color: $text-color-light;
  font-size: rem(14);
  padding: 16px 24px;
  a {
    color: $text-link-color;
  }
}
.middle {
  width: 200px;
}

.large {
  width: 300px;
}

.well {
  padding: 25px;
  background: $background-base-200;
  margin-bottom: 20px;
}

.img-circle {
  border-radius: 50%;
}

.img-small {
  width: 150px;
}

.img-middle {
  max-width: 300px !important;
}

.text-center {
  text-align: center;
}

.video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.danger {
  color: $attention-color;
}

ol.list-default,
ul.list-default {
  padding-left: 25px;
  padding-bottom: 10px;
  padding-top: 7px;
  line-height: 1.8;

  li {
    line-height: 2;
  }
}

.related {
  padding: 70px 0 100px;

  @media screen and (max-width: 1024px) {
    padding: 100px 15px 60px;
  }
}

.related-posts {
  margin: 0 0 50px;

  @media screen and (max-width: 1024px) {
    margin: 0 0 30px;
  }

  a {
    display: block;
    float: left;
    width: 24.25%;

    @media screen and (max-width: 1024px) {
      float: none;
      margin-bottom: 10px;
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 1%;

      @media screen and (max-width: 1024px) {
        margin-right: 0;
      }
    }

    &:hover {
      text-decoration: none;

      .related-posts_img_trim {
        opacity: 0.7;
      }
    }
  }
}

.related-posts_items {
  width: 100%;
}

.related-posts_items_inner {
  position: relative;
  margin: 0 auto;
  padding: 10px;
  background: $neutral-white;
  border: 1px solid #eee;

  @media screen and (max-width: 1024px) {
  }
}

.related-posts_img_trim {
  background: {
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  margin: 0 0 10px;
  overflow: hidden;
  position: relative;
  padding-top: 66.667%;

  @media screen and (max-width: 1024px) {
    padding-top: 45%;
  }
}

.related-posts_date_box {
  color: $primary-color;
  font-size: rem(12);
  margin: 10px 0 5px;
}

.related-posts_item_text {
  display: block;
  font-size: $small-font-size;
  font-weight: bold;
}

.related-posts_item_name {
  display: block;
  font-size: rem(12);
}

.more_link_related {
  margin: 0 auto;
}

%work_tag {
  // NOTE: /works, /workページ共通で使われているタグ
  border-radius: $border-radius-s;
  background-color: $neutral-solid-50;
  color: $neutral-solid-600;
  display: inline-block;
  font-size: rem(12);
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.04em;
  padding: 8px 12px;
}

.service-name__tag {
  // NOTE: /works, /workページ共通で使われているタグ
  @extend %work_tag;
  padding: 8px;
  &.start-up,
  &.scale-up {
    background-color: $mofmof-blue-50;
    color: $text-link-color;
  }
  &.mvp {
    background-color: $mvp-green-50;
    color: $mvp-green-600;
  }
  &.pro-pl {
    background-color: $goro-orange-50;
    color: $goro-orange-600;
  }
}

.voice-card {
  // NOTE: /work, /interviewページ共通で使われているお客様の声、メンバーの声等のカード
  $img-size: 96px;
  background-color: $background-base-300;
  border-radius: $border-radius-s;
  display: grid;
  gap: 16px 32px;
  grid-template-rows: 52px 1fr;
  grid-template-columns: $img-size 1fr;
  padding: 32px;
  @media screen and (max-width: $breakpoint-tablet) {
    align-items: center;
    gap: 24px 32px;
    grid-template-rows: $img-size 1fr;
    grid-template-columns: $img-size 1fr;
  }

  .voice-card__image {
    align-items: center;
    border-radius: 50%;
    display: flex;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: $img-size;
    justify-content: center;
    min-width: $img-size;
    overflow: hidden;
    width: $img-size;
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      grid-column: 1;
      grid-row: 1;
    }
  }

  .voice-card__info {
    @media screen and (max-width: $breakpoint-tablet) {
      grid-column: 2;
      grid-row: 1;
    }
    .voice-card__info--name {
      font-weight: bold;
    }
    .voice-card__info--position {
      color: $text-color-light;
      font-size: rem(12);
    }
  }

  .voice-card__message {
    @media screen and (max-width: $breakpoint-tablet) {
      grid-column: 1 / 3;
      grid-row: 2;
    }
  }
}

// FIXME: .page_nav_items, .page_nav_items.common ともにどこにも使われていないっぽいので削除したい
.page_nav_items {
  float: left;
  width: 480px;
}

.page_nav_items.common {
  width: 100%;
  margin: 0;

  .page_nav_inner {
    margin: 0 30px;

    @media screen and (max-width: 1024px) {
      margin: 0;
    }
  }

  .blog_items,
  .blog_post_items {
    float: none;
    width: 100%;
  }

  .page_nav_items {
    float: none;
  }

  .page_nav_left {
    float: left;
    width: 50%;

    .page_nav_item_box {
      margin-right: 20px;
    }
  }

  .page_nav_right {
    float: right;
    width: 50%;

    .page_nav_item_box {
      margin-left: 20px;
    }
  }

  .page_nav_item_box {
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .page_nav_items.common {
    .page_nav_left {
      .page_nav_item_box {
        margin-right: 10px;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .page_nav_right {
      .page_nav_item_box {
        margin-left: 10px;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .page_nav_items {
    width: 100%;
    margin: 0;
  }

  .blog_items,
  .blog_post_items {
    float: none;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 70px;
  }

  .home {
    .blog_items {
      margin-bottom: 0;
    }

    .blog_post_items {
      margin-bottom: 70px;
    }
  }

  .page_nav_items {
    float: none;
  }

  .page_nav_left {
    float: left;
    width: 50%;

    .page_nav_item_box {
      margin-right: 10px;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .page_nav_right {
    float: right;
    width: 50%;

    .page_nav_item_box {
      margin-left: 10px;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .page_nav_button {
    background: transparent;
    color: $text-link-color;

    &:hover {
      background: transparent;
    }
  }
}

.page_nav_item_box {
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;

  &:hover {
    .page_nav_button {
      background: $text-link-color;
      color: $text-color-white;

      &::after {
        position: absolute;
        right: 20px;
        content: '';
        width: 9px;
        height: 100%;
        background: url(../images/arrow_white.svg) no-repeat center center;
        background-size: contain;
      }
    }
  }
}

.page_nav_button {
  position: absolute;
  bottom: 0;
  right: 30px;
  width: 240px;
  height: 60px;
}

@media screen and (max-width: 1024px) {
  .page_nav_button {
    position: relative;
    bottom: auto;
    right: auto;
    width: auto;

    &::after {
      display: none;
    }
  }

  .button.page_nav_button {
    padding: 10px 0;
  }
}

.more_link {
  width: 200px;
  height: 60px;

  .button_more {
    display: inline-block;
    position: relative;
    top: auto;
    vertical-align: middle;
    background: #aaa;
    width: 100%;
    height: 100%;
    text-align: left;
    color: $text-color-white;
    line-height: 60px;

    span {
      position: relative;
      left: 30px;
    }

    &::after {
      display: block;
      width: 11px;
      height: 60px;
      content: '';
      position: absolute;
      background: url(../images/arrow_more.png) no-repeat center;
      top: 0;
      right: 30px;
    }

    &:hover {
      border: solid 1px #aaa;
      color: $text-color;
      text-decoration: none;
      background: rgba(255, 255, 255, 0.5);
      -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;

      &::after {
        background: url(../images/arrow_more_hover.png) no-repeat center;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .more_link {
    position: relative;
    margin: 0 auto;
    width: 150px;
    height: 30px;
    font-size: rem(10);

    .button_more {
      line-height: 30px;
      background: transparent;
      border: solid 1px $text-link-color;
      color: $text-link-color;

      &::after {
        height: 30px;
        width: 9px;
        right: 15px;
        background: url(../images/arrow.svg) no-repeat center;
        background-size: contain;
      }

      &:hover {
        background: $text-link-color;
        color: $text-color-white;
      }

      span {
        left: 15px;
      }
    }
  }
}

.slick-prev,
.slick-next {
  background: #aaa;
}

button.slick-prev {
  width: 36px;
  height: 36px;
  left: 30px;
  background: #aaa url(../images/nav_left_arrow.png) no-repeat center;
  z-index: 2;

  &::before {
    color: $text-color;
    content: '';
    background: #aaa;
  }

  &:hover,
  &:focus {
    background: #ddd url(../images/nav_left_arrow.png) no-repeat center;
    background-color: $primary-color;
  }
}

button.slick-next {
  width: 36px;
  height: 36px;
  right: 30px;
  background: #aaa url(../images/nav_right_arrow.png) no-repeat center;
  z-index: 2;

  &::before {
    color: $text-color;
    content: '';
    background: #aaa;
  }

  &:hover,
  &:focus {
    background: #ddd url(../images/nav_right_arrow.png) no-repeat center;
    background-color: $primary-color;
  }
}

blockquote {
  position: relative;
  margin-bottom: 25px;
  padding: 25px 10px 5px;
  box-sizing: border-box;
  font-style: italic;
  background: #f3f3f3;
}

blockquote:before {
  display: inline-block;
  position: absolute;
  top: -8px;
  left: 9px;
  width: 40px;
  height: 30px;
  text-align: center;
  content: '\f10d';
  font-family: FontAwesome;
  color: $text-color-white;
  font-size: rem(18);
  line-height: 30px;
  background: #6fcdd9;
  font-weight: 900;
}

blockquote:after {
  position: absolute;
  content: '';
  top: -8px;
  left: 49px;
  border: none;
  border-bottom: solid 8px #6cacb5;
  border-right: solid 9px transparent;
}
