.sns-buttons {
  display: flex;
  gap: 12px;
  padding: 4px 0;
  justify-content: flex-end;
  .btn-sns {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $neutral-solid-600;
    border-radius: 30px;
    font-size: rem(24);
    transition: all 0.3s;
    svg {
      fill: $neutral-solid-600;
      transition: fill 0.3s;
    }
    @media screen and (max-width: $breakpoint-pc) {
      width: 32px;
      height: 32px;
      font-size: rem(16);
    }
  }
  .btn-x {
    &:hover {
      border-color: $neutral-black;
      background-color: $neutral-black;
      svg {
        fill: $neutral-white;
      }
    }
  }
  .btn-fb {
    &:hover {
      border-color: #0c66fd;
      background-color: #0c66fd; /* facebook ブランドカラー */
      svg {
        fill: $neutral-white;
      }
    }
  }
}