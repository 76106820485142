.home-contact {
  * {
    box-sizing: border-box;
  }
  background-color: $primary-color;
  background-image: url(/images/home/home-bg-grid.svg),
    url(/images/home/home-bg-ornament-10.svg),
    url(/images/home/home-bg-ornament-11.svg),
    url(/images/home/home-bg-ornament-12.svg),
    url(/images/home/home-bg-ornament-13.svg);
  background-blend-mode: normal, multiply, multiply, normal, normal;
  background-position: top, bottom -296px left -196px, top -312px right -176px,
    bottom 64px left -76px, top 64px right -64px;
  background-repeat: repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  padding: 120px 0;
  @media screen and (max-width: $breakpoint-xl) {
    background-size: auto, 480px, 480px, 180px, 180px;
    background-position: top, bottom -148px left -108px, top -164px right -88px,
      bottom 32px left -32px, top 32px right -32px;
  }
  @media screen and (max-width: $breakpoint-md) {
    background-size: auto, 320px, 320px, 144px, 144px;
    background-position: top, bottom -148px left -108px, top -164px right -88px,
      bottom 32px left -32px, top 32px right -32px;
  }

  .home-contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-title {
    margin-bottom: 48px;
  }
  .button-group {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
    > .btn {
      width: 252px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
      gap: 16px;
      align-items: center;
      > .btn {
        width: 100%;
        max-width: 320px;
      }
    }
  }
}
