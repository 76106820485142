.our-products-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px;
  background: $background-color-old;
  width: 100vw;
  box-sizing: border-box;
  margin: 0px calc(50% - 50vw);
  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .our-products-section__title {
    font-size: rem(56);
    font-weight: 700;
    color: $primary-color;
    line-height: 100%;
    margin-bottom: 16px;
    @media screen and (max-width: 1024px) {
      font-size: rem(40);
    }
  }
  .our-products-section__sub-title {
    font-size: $base-font-size;
    font-weight: 500;
    color: $primary-color;
    line-height: 100%;
    margin-bottom: 50px;
  }

  .product__my-ope-office {
    position: relative;
    display: flex;
    width: 900px;
    background-color: $text-color-white;
    box-shadow: 2px 2px 10px rgba(#d3d3d3, 0.3);
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      width: 100%;
    }

    .product__my-ope-office__image {
      width: 100%;
      object-fit: cover;
      @media screen and (max-width: 1024px) {
        height: 250px;
      }
    }

    .product__my-ope-office__content {
      padding: 50px 40px 40px 40px;
      @media screen and (max-width: 1024px) {
        padding: 30px 20px;
      }
      .product__my-ope-office__content--title {
        font-size: rem(30);
        font-weight: 700;
        color: $primary-color;
        margin-bottom: 25px;
      }
      .product__my-ope-office__content--divider {
        background-color: $primary-color;
        height: 2px;
        width: 50px;
        margin-bottom: 20px;
      }
      .product__my-ope-office__content--sub-title {
        font-size: $base-font-size;
        font-weight: 700;
        color: $primary-color;
        line-height: 180%;
        margin-bottom: 30px;
      }
      .product__my-ope-office__content--description {
        font-size: rem(12);
        font-weight: 400;
        color: $text-color;
        line-height: 200%;
        margin-bottom: 30px;
      }
      .product__my-ope-office__content--read-more {
        display: flex;
        justify-content: end;
        @media screen and (max-width: 1024px) {
          justify-content: center;
        }
        .product__my-ope-office__content--read-more-button {
          border-radius: 99px;
        }
      }
    }
  }
}
