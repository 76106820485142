.guideline-container {
  width: 900px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

#entry_form_container {
  position: relative;

  #entry_form_modal {
    position: absolute;
    display: inline;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.5);
    
    p {
      display: block;
      line-height: 1.5;
      color: white;
      font-size: rem(35);
      font-weight: bold;
      width: calc(100% - 40px);
      background: #0CA6AF;
      border-radius: 0;
      padding: 25px;
      text-align: center;
      box-sizing: border-box;
      margin: auto 20px;
  
      @media screen and (max-width: 768px) {
        & {
          font-size: rem(30);
          width: 100%;
          margin: auto;
        }
      }
    }
  }
}
