.f-24 {
  font-size: rem(24) !important;
}

.text-brand {
  color: $primary-color;
}

.text-error {
  color: $attention-color;
  white-space: nowrap;
}

.text-secondary-500 {
  color: $yellow-300;
}

.text-bold {
  font-weight: 700;
}

.text-high-light {
  background: linear-gradient(transparent 60%, $yellow-200 40%);
}

.text-outline-primary-600 {
  color: transparent;
  -webkit-text-stroke: 2px $mofmof-blue-700;
}

.text-shadow-secondary-500 {
  text-shadow: 3px 3px 0 $yellow-300;
}

.link-color {
  color: $text-link-color;
}

.link--no-decorate {
  &:hover {
    text-decoration: none;
  }
}

// テキストリンクのスタイル
.text-link-primary { // mofmofブルー デフォ下線なし
  color: $text-link-color;
  text-decoration: none;
  text-underline-offset: 0.1rem;
  &:hover {
    color: $text-link-color;
    text-decoration: underline;
  }
}
.text-link-secondary { // 文字色 デフォ下線あり
  color: $text-color;
  text-decoration: underline;
  text-underline-offset: 0.1rem;
  &:hover {
    color: $text-link-color;
    text-decoration: underline;
  }
}
.text-link-tertiary { // 文字色 下線なし
  color: $text-color;
  text-decoration: none;
  text-underline-offset: 0.1rem;
  &:hover {
    color: $text-link-color;
    text-decoration: none;
  }
}

.text--emphasized {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 0.2em;
    height: 0.2em;
    border-radius: 50%;
    background-color: white;
    top: -20px;
    left: 20px;
    transform: translate(-50%, 0);
  }
}

// テキストのレイアウト
.u-text-center, .text-center {
  text-align: center !important;
}
