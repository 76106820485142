.service {
  //改行禁止区間設定用クラス
  .nobr {
    white-space: nowrap;
  }

  //コンテナ共通の余白設定
  @mixin base-container {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 6%;
    padding-right: 6%;
    box-sizing: content-box;
    @media screen and (max-width: $breakpoint-pc) {
      max-width: 600px;
    }
  }

  //service内共通の見出し設定
  @mixin headline {
    color: $primary-color;
    font-weight: 700;
  }

  .service-category {
    margin: 0;
    &.service-category--colored {
      background-color: $background-color-old;
    }
    .service-category__container {
      @include base-container;
      padding-top: 80px;
      padding-bottom: 120px;
      @media screen and (max-width: $breakpoint-pc) {
        padding-top: 64px;
        padding-bottom: 96px;
      }
      &.service-category__container--first-item {
        padding-top: 24px;
      }
    }
    .service-category__header {
      display: flex;
      flex-direction: column;
      gap: 48px;
    }
    .service-category__headline {
      @include headline;
      font-size: rem(36);
      line-height: 1.3;
      @include vertical-trim-JP;
      margin-bottom: 32px;
      @media screen and (max-width: 560px) {
        font-size: rem(22);
        margin-bottom: 24px;
        line-height: 1.4;
      }
    }
    .service-category__sub-headline {
      @include headline;
      color: #949494;
      font-size: rem(16);
      line-height: 1.5;
      @include vertical-trim-JP;
      margin-bottom: 32px;
      .sp-only {
        @media screen and (max-width: $breakpoint-pc) {
          display: grid !important;
          grid-template-columns: 40px 1fr;
        }
      }
    }
    .service-category__item {
      display: flex;
      column-gap: 24px;
      @media screen and (max-width: $breakpoint-pc) {
        flex-direction: column;
      }
      .service-category__item__image {
        max-width: 568px;
        object-fit: cover;
        border-radius: $border-radius-m;
      }
      .service-category__item__text {
        padding: 20px 0;
        .service-category__item__text__logo {
          width: 100%;
          max-width: 232px;
          margin-bottom: 16px;
        }
        .service-category__item__text__button {
          display: flex;
          justify-content: space-between;
          width: auto;
          max-width: 400px;
          margin-top: 34px;
          padding: 16px 24px;
          font-weight: 700;
        }
        .service-category__item__text__button__active {
          background-color: $primary-color;
          color: $text-color-white;
        }
      }
    }
    .service-category__description {
      max-width: 760px;
      font-size: $base-font-size;
      line-height: 1.8;
      @include vertical-trim-JP;
      @media screen and (max-width: 560px) {
        font-size: $small-font-size;
      }
    }
    .development-team-rental-details {
      summary {
        display: block;
      }
      summary::-webkit-details-marker {
        display: none;
      }
      .development-team-rental-details__content {
        .development-team-rental-details__inner {
          position: relative;
          padding: 40px;
          background-color: $neutral-solid-50;
          border-radius: 12px;
          @media screen and (max-width: $breakpoint-pc) {
            padding: 20px 10px;
          }
          .development-team-rental-details__triangle {
            position: absolute;
            top: -42px;
            right: 30%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 42.5px 42px 42.5px;
            border-color: transparent transparent $neutral-solid-50 transparent;
            @media screen and (max-width: $breakpoint-pc) {
              right: calc(50% - 42px);
            }
          }
        }
      }
    }
    .service-category__cards {
      display: grid;
      column-gap: 32px;
      row-gap: 32px;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 1fr;
      @media screen and (max-width: $breakpoint-pc) {
        // 960px以下で1列
        grid-template-columns: 1fr;
      }
    }
    .service-category__cards-item {
      container-type: inline-size;
    }
  }

  .service-card {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: $text-color-white;
    display: grid;
    grid-template-columns: 30% 70%;
    border-radius: $border-radius-s;
    overflow: hidden;
    @container (width < 520px) {
      grid-template-columns: 40% 60%;
    }
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease 0s;
    outline: rgba(0, 0, 0, 0) solid 1px;
    // ホバー時の挙動
    &:hover {
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.12);
      transform: translateY(-5px);
      outline: $primary-color solid 1px;
      & .service-card__img img {
        transform: scale(1.1);
      }
    }

    // カードリンク
    // 疑似要素でカード全体をリンクにさせる
    .service-card__link {
      text-decoration: none;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    .service-card__img {
      height: 100%;
      overflow: hidden;
      position: relative;
      & img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease 0s;
      }
    }
    .service-card__body {
      padding: 28px 24px;
      @media screen and (max-width: 560px) {
        padding: 16px 16px;
      }
    }

    .service-card__label {
      font-weight: 700;
      color: $text-color-light;
      font-size: rem(14);
      line-height: 1.5;
      @include vertical-trim-JP;
      margin-bottom: 16px;
      @media screen and (max-width: 560px) {
        font-size: rem(12);
        margin-bottom: 12px;
      }
    }
    .service-card__title {
      @include headline;
      font-size: rem(20);
      line-height: 1.5;
      @include vertical-trim-JP;
      word-break: keep-all;
      overflow-wrap: anywhere;
      margin-bottom: 24px;
      @media screen and (max-width: 560px) {
        font-size: rem(16);
        margin-bottom: 16px;
      }
    }
    .service-card__description {
      font-size: $small-font-size;
      line-height: 2;
      @include vertical-trim-JP;
      @media screen and (max-width: 560px) {
        font-size: rem(12);
        line-height: 1.8;
      }
    }
  }
}

.sp_br {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: rem(8);
  }
}
