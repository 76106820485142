// MEMO: クライアントインタビュー
.interview {
  * {
    box-sizing: border-box;
  }
  .contents-blocks {
    display: flex;
    align-items: flex-start;
    padding: 0 24px;
    gap: 64px;
    width: 100%;
    margin-bottom: 64px;
    @media screen and (max-width: $breakpoint-pc) {
      flex-direction: column;
    }
  }

  // 上部タイトル部分のスタイル
  .heading-block {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 48px;
    @media screen and (max-width: $breakpoint-pc) {
      margin-bottom: 32px;
    }
  }
  .heading-block__heading {
    font-size: rem(48);
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.04em;
    @media screen and (max-width: $breakpoint-pc) {
      font-size: rem(36);
    }
    @media screen and (max-width: $breakpoint-md) {
      font-size: rem(32);
    }
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(24);
      line-height: 1.5;
    }
  }
  .heading-block__lower {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .heading-block__company {
    width: 100%;
    font-size: rem(20);
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.04em;
    @media screen and (max-width: $breakpoint-md) {
      font-size: rem(16);
    }
  }
  .mobile-top-image {
    width: 100%;
    margin-bottom: 32px;
    .mobile-top-image__image {
      width: 100vw;
      margin: 0 calc(50% - 50vw);
      max-height: 400px;
      aspect-ratio: 2/1;
      object-fit: cover;
    }
  }

  // 左の情報部分のスタイル
  .info {
    width: 280px;
    flex-shrink: 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: $border-radius-m;
    border: 1px solid $neutral-opacity-100;
    @media screen and (max-width: $breakpoint-pc) {
      width: 100%;
      display: grid;
      grid-template-columns: 248px 1fr;
      gap: 48px;
      > .info__divider {
        display: none;
      }
    }
    @media screen and (max-width: $breakpoint-tablet) {
      gap: 16px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      display: flex;
      > .info__divider {
        display: block;
      }
    }
  }
  .info__group {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .info-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px
  }
  .info-item__heading {
    color: $neutral-solid-700;
    font-size: rem(14);
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.04em;
    margin-bottom: 4px;
  }
  .company-logo {
    object-fit: contain;
    max-width: 320px;
    max-height: 120px;
    padding-bottom: 8px;
    width: 100%;
  }
  .company-data {
    color: $neutral-solid-600;
    display: flex;
    gap: 12px;
    font-size: rem(14);
    font-weight: 400;
    line-height: 1.4;
    > i {
      font-size: rem(20);
    }
    a {
      @extend .text-link-secondary;
      color: $neutral-solid-600;
    }
    > span {
      > i {
        padding-left: 4px;
        vertical-align: - rem(1);
        font-size: rem(16);
      }
    }
  }
  .company-name {
    @extend .company-data;
    font-weight: 700;
    line-height: 1.5;
    padding-bottom: 4px;
  }
  .info__divider {
    border-bottom: solid 1px $neutral-opacity-100;
  }
  .info-opener {
    display: none;
    justify-content: center;
    gap: 4px;
    align-items: center;
    font-weight: 500;
    font-size: rem(14);
    color: $mofmof-blue-600;
    > i {
      font-size: rem(20);
    }
    @media screen and (max-width: $breakpoint-tablet) {
      display: flex;
    }
    &.open {
      display: none;
    }
  }
  .info-wrap {
    display: block;
    @media screen and (max-width: $breakpoint-tablet) {
      display: none;
    }
  }

  .interviewees {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 8px 12px;
  }
  .interviewee {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .interviewee__icon {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    object-fit: cover;
  }
  .interviewee__text {
    display: flex;
    flex-direction: column;
  }
  .interviewee__name {
    font-size: rem(16);
    line-height: 1.8;
    letter-spacing: 0.06em;
  }
  .interviewee__position {
    font-size: rem(12);
    line-height: 1.2;
    letter-spacing: 0.06em;
    color: $text-color-light;
  }


  // 右のメイン部分のスタイル
  .main-block {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 64px;
    .main-block__top-image {
      width: 100%;
      aspect-ratio: 2/1;
      border-radius: $border-radius-m;
      object-fit: cover;
    }
    .main-block__heading {
      font-size: rem(28);
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.04em;
      margin-bottom: 20px;
      @media screen and (max-width: $breakpoint-tablet) {
        font-size: rem(20);
        margin-bottom: 32px;
      }
    }
  }
  // 本文部分のスタイル
  .main-content {
    @include markdown-style;

    .lead-body-divider {
      border-bottom: 1px solid $neutral-opacity-100;
      width: 140px;
      margin: 64px auto;
    }
    .interview-speaker-block {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 8px;
      .interview-speaker-block__icon {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        object-fit: cover;
        margin: 0;
      }
      span {
        font-size: rem(16);
        font-weight: 700;
        line-height: 1.7;
        letter-spacing: 0.04em;
      }
    }

    p {
      font-size: rem(16);
      line-height: 1.8;
      letter-spacing: 0.06em;
      text-align: justify;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .interview-speaker-block + p { // NOTE: p直前にinterview-speaker-blockが来たときだけ、pのスタイルは以下
      margin-top: 8px;
    }

    a {
      @extend .text-link-primary;
    }

    img {
      width: 100%;
      border-radius: $border-radius-s;
      margin: 8px 0; // NOTE: pの16pxマージンと合わせて24pxとなる
    }

    h2 { //NOTE: Qを先頭につけるスタイル
      display: flex;
      gap: 12px;
      font-size: rem(28);
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.04em;
      padding-bottom: 12px;
      border-bottom: 1px solid $neutral-opacity-100;
      margin-top: 64px;
      margin-bottom: 32px;
      &::before {
        content: 'Q.';
        font-family: lato;
        color: $primary-color;
        font-size: rem(40);
        font-weight: 700;
        line-height: 1;
      }
      @media screen and (max-width: $breakpoint-md) {
        font-size: rem(20);
        &::before {
          font-size: rem(32);
        }
      }
    }
    h3 {
      font-size: rem(24);
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.04em;
      margin-top: 48px;
      margin-bottom: 24px;
    }
    h4 {
      font-size: rem(20);
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.04em;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    h5 {
      font-size: rem(16);
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.04em;
      margin-top: 32px;
      margin-bottom: 16px;
    }
  }

  //mofmof担当者の声部分のスタイル
  .members-voice__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  //関連の実績部分のスタイル
  .related-work-card {
    display: flex;
    gap: 24px;
    width: 100%;
    overflow: hidden;
    transition: 0.3s;
    @media screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
    &:hover {
      opacity: .8;
      text-decoration: none;
      color: inherit;
      .related-work-card__image > img {
        scale: 1.05;
      }
      .related-work-card__title {
        color: $mofmof-blue-600;
      }
    }
  }
  .related-work-card__image {
    border-radius: $border-radius-m;
    width: 50%;
    overflow: hidden;
    @media screen and (max-width: $breakpoint-tablet) {
      height: 200px;
      width: 100%;
    }
    > img {
      transition: scale 0.3s;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .related-work-card__title {
    color: $text-color;
    font-size: rem(32);
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: rem(1.28);
    margin-bottom: 4px;
    transition: 0.3s;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(28);
    }
  }
  .related-work-card__summary {
    color: $text-color;
    font-size: rem(20);
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: rem(0.8);
    margin-bottom: 20px;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(16);
    }
  }
  .related-work-card__separator {
    background-color: $mofmof-blue-400;
    border-radius: 2px;
    height: 4px;
    margin-bottom: 20px;
    width: 80px;
  }
  .related-work-card__client-name {
    color: $text-color;
    font-size: rem(14);
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: rem(0.56);
  }

  //関連資料ダウンロード部分のスタイル
  .interview-dl {
    width: 100vw;
    margin: 0 calc(50% - 50vw);
    display: flex;
    background-color: $mofmof-blue-50;
    @media screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
    .interview-dl__image {
      width: 50%;
      background-size: cover;
      background-position: center;
      @media screen and (max-width: $breakpoint-pc) {
        width: 40%;
      }
      @media screen and (max-width: $breakpoint-tablet) {
        width: 100%;
        height: 200px;
      }
    }
    .interview-dl__contents {
      padding: 80px;
      width: 50%;
      max-width: $breakpoint-xxl / 2;
      display: flex;
      flex-direction: column;
      align-items: start;
      @media screen and (max-width: $breakpoint-xl) {
        padding: 48px;
      }
      @media screen and (max-width: $breakpoint-pc) {
        width: 60%;
      }
      @media screen and (max-width: $breakpoint-tablet) {
        width: 100%;
        padding: 24px 24px 32px;
      }
    }
    .interview-dl__label {
      font-size: rem(16);
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.04em;
      color: $primary-color;
      background-color: $mofmof-blue-100;
      padding: 6px 12px;
      border-radius: $border-radius-s;
      margin-bottom: 16px;
      @media screen and (max-width: $breakpoint-pc) {
        font-size: rem(14);
        padding: 4px 8px;
        margin-bottom: 8px;
      }
      @media screen and (max-width: $breakpoint-md) {
        font-size: rem(12);
      }
    }
    .interview-dl__title {
      font-size: rem(32);
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.04em;
      color: $text-color;
      margin-bottom: 20px;
      @media screen and (max-width: $breakpoint-pc) {
        font-size: rem(24);
      }
    }
    .interview-dl__description {
      font-size: rem(16);
      line-height: 1.8;
      letter-spacing: 0.06em;
      color: $text-color-light;
      margin-bottom: 32px;
      @media screen and (max-width: $breakpoint-md) {
        font-size: rem(12);
      }
    }
    .btn {
      width: unquote('min(300px, 100%)'); // NOTE: cssのminがscssの関数と衝突するので回避
      @media screen and (max-width: $breakpoint-tablet) {
        margin: 0 auto;
      }
    }
  }

  //関連記事部分のスタイル
  .related-interview {
    margin-top: 64px;
    margin-bottom: 104px;
    padding: 0 24px;
  }
  .related-interview__heading {
    color: $text-color;
    font-size: rem(28);
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: rem(1.12);
    margin-bottom: 24px;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: rem(20);
      margin-bottom: 32px;
    }
  }
  .related-interview__cards {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 64px 40px;
    @media screen and (max-width: $breakpoint-pc) {
      grid-template-columns: 1fr;
      gap: 48px;
    }
  }
}